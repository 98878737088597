@media (min-width: 320px) {
  .evenheight {
    height: calc(100% - 22vh);
  }
  .course-detail-container {
    width: 100%;
    padding: 10px;
  }
  .course-detail-container .breadcrumb-container {
    margin: 0px;
  }
  .course-detail-container .breadcrumb-container .breadcrumb {
    margin: 0px !important;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .item {
    cursor: pointer;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .item .link {
    color: #0b649d;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .active-item {
    color: #0b649d;
    font-weight: 400;
  }
  .course-detail-container .content {
    margin: 0px;
  }
  .course-detail-container .content .container {
    margin-top: 10px;
  }
  .course-detail-container .content .container .inner-container {
    background: #fff;
    border-radius: 0px;
  }
  .course-detail-container .content .container .inner-container .row {
    align-items: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .banner-column {
    padding: 5px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .banner-column
    .image {
    border-radius: 7px;
    width: 100%;
    min-height: auto;
    height: auto;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column {
    padding: 0px 5px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content {
    padding: 0px 0px;
    height: 100%;
    justify-content: center;
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .title-row {
    margin-bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .title-row
    .title {
    font-weight: 700;
    font-size: 16px;
    color: #334d6e;
    line-height: normal;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row {
    padding: 10px 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column
    .text {
    font-size: 12px;
    color: #334d6e !important;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column
    .created-by {
    font-size: 10px;
    color: #90a0b7;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row {
    align-items: center;
    padding: 0px;
    justify-content: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .image-container {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .image-container
    .image {
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column {
    display: flex;
    flex-direction: column;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .content {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .content
    .text {
    font-size: 18px;
    color: #334d6e;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .label {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .label
    .text {
    color: #0b649d;
    font-size: 10px;
    font-weight: normal;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .image-container {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .image-container
    .image {
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column {
    display: flex;
    flex-direction: column;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .content {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .content
    .text {
    font-size: 18px;
    color: #334d6e;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .label {
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .label
    .text {
    color: #0b649d;
    font-size: 10px;
    font-weight: normal;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column {
    position: relative;
    bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .text {
    text-align: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .text
    .value {
    color: #cb561e;
    font-size: 18px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .inner-content {
    position: relative;
    bottom: 5px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .inner-content
    .icon {
    color: #f9873c !important;
    font-size: 18px;
    font-weight: 700;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row {
    padding: 10px 0px;
    position: unset;
    width: 100%;
    bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column
    .text {
    font-size: 12px;
    color: #334d6e !important;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column
    .created-by {
    font-size: 10px;
    color: #90a0b7;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column {
    margin: 0px;
    margin-bottom: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notified,
  .notify {
    cursor: pointer;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notified
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notify
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row {
    padding-bottom: 10px !important;
    padding-top: 0px !important;

    position: unset;
    width: 100%;
    bottom: 0px;
  }
  .course-details-notify-box {
    background: #fff;
    margin-top: 20px;
    text-align: center;
    padding: 20px;
    font-size: 16px;
    font-weight: 400;
    box-shadow: 0px 2px 10px rgb(90 114 200 / 8%);
    border-radius: 0px;
  }
  .course-detail-container .content .description-container .inner-container {
    background: #fff;
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 0px;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .description-container
    .inner-container
    .card {
    width: 100%;
  }
  .course-detail-container
    .content
    .description-container
    .inner-container
    .card
    .content {
    padding: 10px;
    font-size: 14px;
  }
  .course-detail-container .content .faq-container {
    margin: 0px;
  }
  .course-detail-container .content .faq-container .content {
    background: #fff;
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    padding-bottom: 50px;
  }
  .course-detail-container .content .faq-container .content .card {
    width: 100%;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .collapse-image {
    width: 20px;
    position: relative;
    top: 3px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .panel-header {
    color: #0b649d !important;
    font-weight: 500;
    line-height: 24px;
    font-size: 16px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .panel-answer {
    margin: 0px 0px !important;
    font-size: 16px;
  }
  .course-detail-container .content .features-container {
    margin-top: 20px;
  }
  .course-detail-container .content .features-container .content {
    background: #fff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  }
  .course-detail-container .content .features-container .content .card {
    width: 100%;
    padding: 0px 20px 20px;
  }
  .course-detail-container
    .content
    .features-container
    .content
    .card
    .list-item {
    display: block;
    border-bottom: 0px solid;
  }
  .course-detail-container
    .content
    .features-container
    .content
    .card
    .list-item
    .image {
    width: 20px;
    margin-right: 15px;
  }
  .course-detail-container .content .instructor-container {
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 0px;
  }
  .course-detail-container .content .instructor-container .content {
    margin: 0px;
  }
  .course-detail-container .content .instructor-container .content .card {
    width: 100%;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content {
    padding: 0px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content {
    padding: 10px 0px;
    display: flex;
    align-items: center;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content
    .image {
    border-radius: 90px;
    width: 50px;
    height: 50px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content
    .span {
    margin: 0px 10px;
    text-transform: capitalize;
  }
  .course-detail-container .content .support-container {
    margin-top: 12px;
  }
  .course-detail-container .content .support-container .content {
    background: #fff;
    margin: 0px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
  }
  .course-detail-container .content .support-container .content .card {
    width: 100%;
  }
  .course-detail-container .content .support-container .content .card .row {
    width: 100%;
    align-items: center;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .title-column {
    background: #e0f3ff;
    text-align: center;
    padding: 25px;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .text {
    font-weight: bold;
    font-size: 14px;
    color: #0b649d;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .support-column {
    padding: 10px;
    text-align: center;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .support-column
    .icon {
    border: 1px solid #0b649d;
    border-radius: 90px;
    width: 45px;
    object-fit: none;
    cursor: pointer;
  }
  .module-container {
    margin-top: 0px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 4px;
  }
  .course-detail-container .content .module-container .content {
    background: #fff;
    margin: 0px;
    padding: 0px 20px;
  }
  .course-detail-container .content .module-container .content .card .row {
    width: 100%;
    align-items: flex-start;
    padding: 20px 0px;
    justify-content: center;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column {
    padding: 20px;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .image {
    width: 40px;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content {
    padding: 0px 10px;
    white-space: nowrap;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content
    .value {
    font-size: 16px;
    font-weight: 700;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content
    .title {
    font-size: 10px;
    color: #90a0b7;
  }
  .course-detail-container .content .free-container {
    margin: 0px;
  }
  .course-detail-container .content .free-container .content {
    padding: 15px;
    background: #fffbe6;
    width: 70%;
    margin: auto;
    text-align: center;
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #f0efef;
  }
  .course-detail-container .content .free-container .content .inner-content {
    padding: 10px;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .image {
    position: relative;
    bottom: 5px;
    right: 5px;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .text {
    color: #7ba353;
    font-size: 22px;
    font-weight: bold;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .button {
    border-radius: 6px !important;
    background: #ee2d3b;
    font-weight: 600;
    color: #fff !important;
    width: 170px;
    height: 45px !important;
  }
  .course-detail-container .content .unsubscribe-container {
    margin: 0px;
    padding-bottom: 15px;
  }
  .course-detail-container .content .unsubscribe-container .content {
    padding: 10px;
    background: #fffbe6;
    width: 90%;
    margin: auto;
    text-align: center;
    border-top: 1px solid #f0efef;
    position: relative;
    bottom: 3px;
  }
  .course-detail-container .content .unsubscribe-container .content .row {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .text-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .text-column
    .text {
    color: #7ba353;
    font-size: 20px;
    font-weight: bold;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column {
    padding: 5px 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .image-span {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .image-span
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .date-span {
    padding: 10px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .date-span
    .text {
    color: red;
    font-weight: 600;
    font-size: 12px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    height: 45px;
    background: #c4c4c4;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
    width: 100%;
  }
  .course-detail-container .content .price-container {
    margin: 0px;
    padding-bottom: 15px;
  }
  .course-detail-container .content .price-container .content {
    padding: 10px;
    background: #fffbe6;
    width: 90%;
    margin: auto;
    text-align: center;
    border-top: 1px solid #f0efef;
    position: relative;
    bottom: 5px;
  }
  .course-detail-container .content .price-container .content .row {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .price-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .price-column
    .text {
    font-weight: bold;
    font-size: 16px;
    font-family: sans-serif;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column {
    padding: 5px 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .image-span {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .image-span
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .text-span {
    padding: 10px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .text-span
    .text {
    color: red;
    font-weight: 600;
    font-size: 12px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    height: 45px;
    background: #c4c4c4;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
    height: 40px;
    width: 100%;
  }
  .course-detail-container .content .special-price-container {
    margin: 0px;
  }
  .course-detail-container .content .special-price-container .content {
    background: #fffbe6;
    width: 95%;
    margin: auto;
    text-align: center;
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #f0efef;
  }
  .course-detail-container .content .special-price-container .content .row {
    padding: 5px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .clock-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .clock-column
    .image {
    width: 60px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .time-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .label {
    color: #0b649d;
    font-weight: bold;
    padding: 0px 10px;
    font-size: 12px;
    display: contents;
    justify-content: center;
    align-items: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .text {
    font-weight: 900;
    color: red;
    letter-spacing: 1px;
    font-size: 14px;
    padding: 0px 5px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row {
    display: flex;
    justify-content: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .save-span {
    color: #01a54e;
    font-size: 12px;
    padding: 5px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .price-text {
    font-size: 18px;
    margin: 20px;
    font-weight: bolder;
    font-family: sans-serif;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .expire-text {
    font-size: 12px;
    margin: 20px;
    font-weight: 600;
    font-family: sans-serif;
    position: relative;
    color: #c4c4c4;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .expire-text:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid #ee2d3b !important;
    border-color: inherit;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-10deg);
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    height: 40px;
    width: 100%;
  }
  .card-view-button {
    color: #0b649d;
    font-size: 13px;
    font-weight: 900;
    border: 1px solid #0b649d;
    background: #e6f7ff;
    border-radius: 4px;
    letter-spacing: 1px;
  }
  .action-content {
    padding-bottom: 10px;
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 0px 0;
  }
}

@media (min-width: 481px) {
  .evenheight {
    height: calc(100% - 22vh);
  }
  .course-detail-container {
    width: 100%;
    padding: 10px;
  }
  .course-detail-container .breadcrumb-container {
    margin: 0px;
  }
  .course-detail-container .breadcrumb-container .breadcrumb {
    margin: 0px !important;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .item {
    cursor: pointer;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .item .link {
    color: #0b649d;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .active-item {
    color: #0b649d;
    font-weight: 400;
  }
  .course-detail-container .content {
    margin: 0px;
  }
  .course-detail-container .content .container {
    margin-top: 10px;
  }
  .course-detail-container .content .container .inner-container {
    background: #fff;
    border-radius: 0px;
  }
  .course-detail-container .content .container .inner-container .row {
    align-items: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .banner-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .banner-column
    .image {
    border-radius: 7px;
    width: 100%;
    min-height: auto;
    height: auto;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column {
    padding: 0px 5px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content {
    height: 100%;
    justify-content: center;
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .title-row {
    margin-bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .title-row
    .title {
    font-weight: 700;
    font-size: 16px;
    color: #334d6e;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row {
    padding: 10px 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column
    .text {
    font-size: 14px;
    color: #334d6e !important;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column
    .created-by {
    font-size: 10px;
    color: #90a0b7;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row {
    align-items: center;
    padding: 30px 0px;
    justify-content: flex-start;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .image-container {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .image-container
    .image {
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column {
    display: flex;
    flex-direction: column;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .content {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .content
    .text {
    font-size: 14px;
    color: #334d6e;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .label {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .label
    .text {
    color: #0b649d;
    font-size: 10px;
    font-weight: normal;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .image-container {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .image-container
    .image {
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column {
    display: flex;
    flex-direction: column;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .content {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .content
    .text {
    font-size: 18px;
    color: #334d6e;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .label {
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .label
    .text {
    color: #0b649d;
    font-size: 10px;
    font-weight: normal;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column {
    position: relative;
    bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .text {
    text-align: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .text
    .value {
    color: #cb561e;
    font-size: 18px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .inner-content {
    position: relative;
    bottom: 5px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .inner-content
    .icon {
    color: #f9873c !important;
    font-size: 18px;
    font-weight: 700;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row {
    padding: 10px 0px;
    position: unset;
    width: 100%;
    bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column
    .text {
    font-size: 14px;
    color: #334d6e !important;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column
    .created-by {
    font-size: 10px;
    color: #90a0b7;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column {
    margin: 0px;
    margin-bottom: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notified,
  .notify {
    cursor: pointer;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notified
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notify
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row {
    padding: 10px 0px;
    position: unset;
    width: 100%;
    bottom: 0px;
  }
  .course-details-notify-box {
    background: #fff;
    margin-top: 20px;
    text-align: center;
    padding: 20px;
    font-size: 16px;
    font-weight: 400;
    box-shadow: 0px 2px 10px rgb(90 114 200 / 8%);
    border-radius: 0px;
  }
  .course-detail-container .content .description-container .inner-container {
    background: #fff;
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 0px;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .description-container
    .inner-container
    .card {
    width: 100%;
  }
  .course-detail-container
    .content
    .description-container
    .inner-container
    .card
    .content {
    padding: 10px;
    font-size: 14px;
  }
  .course-detail-container .content .faq-container {
    margin: 0px;
  }
  .course-detail-container .content .faq-container .content {
    background: #fff;
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    padding-bottom: 50px;
  }
  .course-detail-container .content .faq-container .content .card {
    width: 100%;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .collapse-image {
    width: 20px;
    position: relative;
    top: 3px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .panel-header {
    color: #0b649d !important;
    font-weight: 500;
    line-height: 24px;
    font-size: 16px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .panel-answer {
    margin: 0px 0px !important;
    font-size: 16px;
  }
  .course-detail-container .content .features-container {
    margin-top: 20px;
  }
  .course-detail-container .content .features-container .content {
    background: #fff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  }
  .course-detail-container .content .features-container .content .card {
    width: 100%;
    padding: 0px 20px 20px;
  }
  .course-detail-container
    .content
    .features-container
    .content
    .card
    .list-item {
    display: block;
    border-bottom: 0px solid;
  }
  .course-detail-container
    .content
    .features-container
    .content
    .card
    .list-item
    .image {
    width: 20px;
    margin-right: 15px;
  }
  .course-detail-container .content .instructor-container {
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 0px;
  }
  .course-detail-container .content .instructor-container .content {
    margin: 0px;
  }
  .course-detail-container .content .instructor-container .content .card {
    width: 100%;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content {
    padding: 0px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content {
    padding: 10px 0px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content
    .image {
    border-radius: 90px;
    width: 50px;
    height: 50px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content
    .span {
    margin: 0px 10px;
    text-transform: capitalize;
  }
  .course-detail-container .content .support-container {
    margin-top: 0px;
  }
  .course-detail-container .content .support-container .content {
    background: #fff;
    margin: 0px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
  }
  .course-detail-container .content .support-container .content .card {
    width: 100%;
  }
  .course-detail-container .content .support-container .content .card .row {
    width: 100%;
    align-items: center;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .title-column {
    background: #e0f3ff;
    text-align: center;
    padding: 25px;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .text {
    font-weight: bold;
    font-size: 14px;
    color: #0b649d;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .support-column {
    padding: 10px;
    text-align: center;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .support-column
    .icon {
    border: 1px solid #0b649d;
    border-radius: 90px;
    width: 45px;
    object-fit: none;
    cursor: pointer;
  }
  .module-container {
    margin-top: 0px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 4px;
  }
  .course-detail-container .content .module-container .content {
    background: #fff;
    margin: 0px;
    padding: 0px 20px;
  }
  .course-detail-container .content .module-container .content .card .row {
    width: 100%;
    align-items: flex-start;
    padding: 20px 0px;
    justify-content: flex-start;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column {
    padding: 20px;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .image {
    width: 40px;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content {
    padding: 0px 10px;
    white-space: nowrap;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content
    .value {
    font-size: 16px;
    font-weight: 700;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content
    .title {
    font-size: 10px;
    color: #90a0b7;
  }
  .course-detail-container .content .free-container {
    margin: 0px;
  }
  .course-detail-container .content .free-container .content {
    padding: 15px;
    background: #fffbe6;
    width: 70%;
    margin: auto;
    text-align: center;
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #f0efef;
  }
  .course-detail-container .content .free-container .content .inner-content {
    padding: 10px;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .image {
    position: relative;
    bottom: 5px;
    right: 5px;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .text {
    color: #7ba353;
    font-size: 22px;
    font-weight: bold;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .button {
    border-radius: 6px !important;
    background: #ee2d3b;
    font-weight: 600;
    color: #fff !important;
    width: 170px;
    height: 45px !important;
  }
  .course-detail-container .content .unsubscribe-container {
    margin: 0px;
    padding-bottom: 15px;
  }
  .course-detail-container .content .unsubscribe-container .content {
    padding: 10px;
    background: #fffbe6;
    width: 90%;
    margin: auto;
    text-align: center;
    border-top: 1px solid #f0efef;
    position: relative;
    bottom: 5px;
  }
  .course-detail-container .content .unsubscribe-container .content .row {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .text-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .text-column
    .text {
    color: #7ba353;
    font-size: 20px;
    font-weight: bold;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column {
    padding: 5px 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .image-span {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .image-span
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .date-span {
    padding: 10px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .date-span
    .text {
    color: red;
    font-weight: 600;
    font-size: 12px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    height: 45px;
    background: #c4c4c4;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
    width: auto;
  }
  .course-detail-container .content .price-container {
    margin: 0px;
    padding-bottom: 15px;
  }
  .course-detail-container .content .price-container .content {
    padding: 10px;
    background: #fffbe6;
    width: 90%;
    margin: auto;
    text-align: center;
    border-top: 1px solid #f0efef;
    position: relative;
    bottom: 5px;
  }
  .course-detail-container .content .price-container .content .row {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .price-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .price-column
    .text {
    font-weight: bold;
    font-size: 16px;
    font-family: sans-serif;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column {
    padding: 5px 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .image-span {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .image-span
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .text-span {
    padding: 10px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .text-span
    .text {
    color: red;
    font-weight: 600;
    font-size: 12px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    height: 45px;
    background: #c4c4c4;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
    height: 40px;
    width: auto;
  }
  .course-detail-container .content .special-price-container {
    margin: 0px;
  }
  .course-detail-container .content .special-price-container .content {
    background: #fffbe6;
    width: 95%;
    margin: auto;
    text-align: center;
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #f0efef;
  }
  .course-detail-container .content .special-price-container .content .row {
    padding: 5px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .clock-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .clock-column
    .image {
    width: 60px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .time-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .label {
    color: #0b649d;
    font-weight: bold;
    padding: 0px 10px;
    font-size: 12px;
    display: contents;
    justify-content: center;
    align-items: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .text {
    font-weight: 900;
    color: red;
    letter-spacing: 1px;
    font-size: 14px;
    padding: 0px 5px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row {
    display: flex;
    justify-content: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .save-span {
    color: #01a54e;
    font-size: 12px;
    padding: 5px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .price-text {
    font-size: 18px;
    margin: 20px;
    font-weight: bolder;
    font-family: sans-serif;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .expire-text {
    font-size: 12px;
    margin: 20px;
    font-weight: 600;
    font-family: sans-serif;
    position: relative;
    color: #c4c4c4;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .expire-text:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid #ee2d3b !important;
    border-color: inherit;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-10deg);
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    height: 40px;
    width: 100%;
  }
  .card-view-button {
    color: #0b649d;
    font-size: 13px;
    font-weight: 900;
    border: 1px solid #0b649d;
    background: #e6f7ff;
    border-radius: 4px;
    letter-spacing: 1px;
  }
  .action-content {
    padding-bottom: 10px;
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 0px 0;
  }
}

@media (min-width: 641px) {
  .evenheight {
    height: calc(100% - 22vh);
  }
  .course-detail-container {
    margin: auto;
    width: 85%;
  }
  .course-detail-container .breadcrumb-container {
    margin: 0px;
  }
  .course-detail-container .breadcrumb-container .breadcrumb {
    margin: 0px !important;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .item {
    cursor: pointer;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .item .link {
    color: #0b649d;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .active-item {
    color: #0b649d;
    font-weight: 400;
  }
  .course-detail-container .content {
    margin: 0px;
  }
  .course-detail-container .content .container {
    margin-top: 10px;
  }
  .course-detail-container .content .container .inner-container {
    background: #fff;
    border-radius: 0px;
  }
  .course-detail-container .content .container .inner-container .row {
    align-items: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .banner-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .banner-column
    .image {
    border-radius: 7px;
    width: 100%;
    min-height: auto;
    height: auto;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column {
    padding: 0px 5px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content {
    height: 100%;
    justify-content: center;
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .title-row {
    margin-bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .title-row
    .title {
    font-weight: 700;
    font-size: 16px;
    color: #334d6e;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row {
    padding: 10px 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column
    .text {
    font-size: 14px;
    color: #334d6e !important;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column
    .created-by {
    font-size: 10px;
    color: #90a0b7;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row {
    align-items: center;
    padding: 0px 0px;
    justify-content: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .image-container {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .image-container
    .image {
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column {
    display: flex;
    flex-direction: column;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .content {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .content
    .text {
    font-size: 14px;
    color: #334d6e;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .label {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .label
    .text {
    color: #0b649d;
    font-size: 10px;
    font-weight: normal;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .image-container {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .image-container
    .image {
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column {
    display: flex;
    flex-direction: column;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .content {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .content
    .text {
    font-size: 18px;
    color: #334d6e;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .label {
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .label
    .text {
    color: #0b649d;
    font-size: 10px;
    font-weight: normal;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column {
    position: relative;
    bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .text {
    text-align: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .text
    .value {
    color: #cb561e;
    font-size: 18px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .inner-content {
    position: relative;
    bottom: 0px;
    left: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .inner-content
    .icon {
    color: #f9873c !important;
    font-size: 14px;
    font-weight: 700;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row {
    padding: 10px 0px;
    position: unset;
    width: 100%;
    bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column
    .text {
    font-size: 14px;
    color: #334d6e !important;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column
    .created-by {
    font-size: 10px;
    color: #90a0b7;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column {
    margin: 0px;
    margin-bottom: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notified,
  .notify {
    cursor: pointer;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notified
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notify
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row {
    padding: 10px 0px;
    position: unset;
    width: 100%;
    bottom: 0px;
  }
  .course-details-notify-box {
    background: #fff;
    margin-top: 20px;
    text-align: center;
    padding: 20px;
    font-size: 16px;
    font-weight: 400;
    box-shadow: 0px 2px 10px rgb(90 114 200 / 8%);
    border-radius: 0px;
  }
  .course-detail-container .content .description-container .inner-container {
    background: #fff;
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 0px;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .description-container
    .inner-container
    .card {
    width: 100%;
  }
  .course-detail-container
    .content
    .description-container
    .inner-container
    .card
    .content {
    padding: 10px;
    font-size: 14px;
  }
  .course-detail-container .content .faq-container {
    margin: 0px;
  }
  .course-detail-container .content .faq-container .content {
    background: #fff;
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    padding-bottom: 50px;
  }
  .course-detail-container .content .faq-container .content .card {
    width: 100%;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .collapse-image {
    width: 20px;
    position: relative;
    top: 3px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .panel-header {
    color: #0b649d !important;
    font-weight: 500;
    line-height: 24px;
    font-size: 16px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .panel-answer {
    margin: 0px 0px !important;
    font-size: 16px;
  }
  .course-detail-container .content .features-container {
    margin-top: 20px;
  }
  .course-detail-container .content .features-container .content {
    background: #fff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  }
  .course-detail-container .content .features-container .content .card {
    width: 100%;
    padding: 0px 20px 20px;
  }
  .course-detail-container
    .content
    .features-container
    .content
    .card
    .list-item {
    display: block;
    border-bottom: 0px solid;
  }
  .course-detail-container
    .content
    .features-container
    .content
    .card
    .list-item
    .image {
    width: 20px;
    margin-right: 15px;
  }
  .course-detail-container .content .instructor-container {
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 0px;
  }
  .course-detail-container .content .instructor-container .content {
    margin: 0px;
  }
  .course-detail-container .content .instructor-container .content .card {
    width: 100%;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content {
    padding: 0px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content {
    padding: 10px 0px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content
    .image {
    border-radius: 90px;
    width: 50px;
    height: 50px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content
    .span {
    margin: 0px 10px;
    text-transform: capitalize;
  }
  .course-detail-container .content .support-container {
    margin-top: 0px;
  }
  .course-detail-container .content .support-container .content {
    background: #fff;
    margin: 0px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
  }
  .course-detail-container .content .support-container .content .card {
    width: 100%;
  }
  .course-detail-container .content .support-container .content .card .row {
    width: 100%;
    align-items: center;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .title-column {
    background: #e0f3ff;
    text-align: center;
    padding: 25px;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .text {
    font-weight: bold;
    font-size: 14px;
    color: #0b649d;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .support-column {
    padding: 10px;
    text-align: center;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .support-column
    .icon {
    border: 1px solid #0b649d;
    border-radius: 90px;
    width: 45px;
    object-fit: none;
    cursor: pointer;
  }
  .module-container {
    margin-top: 0px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 4px;
  }
  .course-detail-container .content .module-container .content {
    background: #fff;
    margin: 0px;
    padding: 0px 20px;
  }
  .course-detail-container .content .module-container .content .card .row {
    width: 100%;
    align-items: flex-start;
    padding: 20px 0px;
    justify-content: flex-start;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column {
    padding: 20px;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .image {
    width: 40px;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content {
    padding: 0px 10px;
    white-space: nowrap;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content
    .value {
    font-size: 16px;
    font-weight: 700;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content
    .title {
    font-size: 10px;
    color: #90a0b7;
  }
  .course-detail-container .content .free-container {
    margin: 0px;
  }
  .course-detail-container .content .free-container .content {
    padding: 15px;
    background: #fffbe6;
    width: 70%;
    margin: auto;
    text-align: center;
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #f0efef;
  }
  .course-detail-container .content .free-container .content .inner-content {
    padding: 10px;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .image {
    position: relative;
    bottom: 3px;
    right: 10px;
    width: 20px;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .text {
    color: #7ba353;
    font-size: 18px;
    font-weight: bold;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .button {
    border-radius: 6px !important;
    background: #ee2d3b;
    font-weight: 600;
    color: #fff !important;
    width: 140px;
    height: 40px !important;
    font-size: 12px;
  }
  .course-detail-container .content .unsubscribe-container {
    margin: 0px;
    padding-bottom: 15px;
  }
  .course-detail-container .content .unsubscribe-container .content {
    padding: 10px;
    background: #fffbe6;
    width: 90%;
    margin: auto;
    text-align: center;
    border-top: 1px solid #f0efef;
    position: relative;
    bottom: 20px;
  }
  .course-detail-container .content .unsubscribe-container .content .row {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .text-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .text-column
    .text {
    color: #7ba353;
    font-size: 20px;
    font-weight: bold;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column {
    padding: 5px 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .image-span {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .image-span
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .date-span {
    padding: 10px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .date-span
    .text {
    color: red;
    font-weight: 600;
    font-size: 12px;
    white-space: nowrap;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    height: 40px;
    background: #c4c4c4;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 600;
    width: auto;
  }
  .course-detail-container .content .price-container {
    margin: 0px;
    padding-bottom: 15px;
  }
  .course-detail-container .content .price-container .content {
    padding: 10px;
    background: #fffbe6;
    width: 90%;
    margin: auto;
    text-align: center;
    border-top: 1px solid #f0efef;
    position: relative;
    bottom: 5px;
  }
  .course-detail-container .content .price-container .content .row {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .price-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .price-column
    .text {
    font-weight: bold;
    font-size: 16px;
    font-family: sans-serif;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column {
    padding: 5px 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .image-span {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .image-span
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .text-span {
    padding: 10px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .text-span
    .text {
    color: red;
    font-weight: 600;
    font-size: 12px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    height: 45px;
    background: #c4c4c4;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 600;
    height: 40px;
    width: auto;
  }
  .course-detail-container .content .special-price-container {
    margin: 0px;
  }
  .course-detail-container .content .special-price-container .content {
    background: #fffbe6;
    width: 95%;
    margin: auto;
    text-align: center;
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #f0efef;
  }
  .course-detail-container .content .special-price-container .content .row {
    padding: 5px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .clock-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .clock-column
    .image {
    width: 60px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .time-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .label {
    color: #0b649d;
    font-weight: bold;
    padding: 0px 10px;
    font-size: 12px;
    display: contents;
    justify-content: center;
    align-items: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .text {
    font-weight: 900;
    color: red;
    letter-spacing: 1px;
    font-size: 14px;
    padding: 0px 5px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row {
    display: flex;
    justify-content: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .save-span {
    color: #01a54e;
    font-size: 12px;
    padding: 5px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .price-text {
    font-size: 16px;
    margin: 20px;
    font-weight: bolder;
    font-family: sans-serif;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .expire-text {
    font-size: 12px;
    margin: 20px;
    font-weight: 600;
    font-family: sans-serif;
    position: relative;
    color: #c4c4c4;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .expire-text:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid #ee2d3b !important;
    border-color: inherit;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-10deg);
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    height: 40px;
    width: 100%;
    font-size: 12px;
  }
  .card-view-button {
    color: #0b649d;
    font-size: 13px;
    font-weight: 900;
    border: 1px solid #0b649d;
    background: #e6f7ff;
    border-radius: 4px;
    letter-spacing: 1px;
  }
  .action-content {
    padding-bottom: 10px;
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 20px 0;
  }
}

@media (min-width: 961px) {
  .evenheight {
    height: calc(100% - 22vh);
  }
  .course-detail-container {
    margin: auto;
    width: 85%;
  }
  .course-detail-container .breadcrumb-container {
    margin: 0px;
  }
  .course-detail-container .breadcrumb-container .breadcrumb {
    margin: 0px !important;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .item {
    cursor: pointer;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .item .link {
    color: #0b649d;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .active-item {
    color: #0b649d;
    font-weight: 400;
  }
  .course-detail-container .content {
    margin: 0px;
  }
  .course-detail-container .content .container {
    margin-top: 10px;
  }
  .course-detail-container .content .container .inner-container {
    background: #fff;
    border-radius: 0px;
  }
  .course-detail-container .content .container .inner-container .row {
    align-items: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .banner-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .banner-column
    .image {
    border-radius: 7px;
    width: 100%;
    min-height: auto;
    height: auto;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column {
    padding: 0px 5px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content {
    height: 100%;
    justify-content: center;
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .title-row {
    margin-bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .title-row
    .title {
    font-weight: 700;
    font-size: 16px;
    color: #334d6e;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row {
    padding: 10px 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column
    .text {
    font-size: 14px;
    color: #334d6e !important;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column
    .created-by {
    font-size: 10px;
    color: #90a0b7;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row {
    align-items: center;
    padding: 15px 0px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .image-container {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .image-container
    .image {
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column {
    display: flex;
    flex-direction: column;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .content {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .content
    .text {
    font-size: 14px;
    color: #334d6e;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .label {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .label
    .text {
    color: #0b649d;
    font-size: 10px;
    font-weight: normal;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .image-container {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .image-container
    .image {
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column {
    display: flex;
    flex-direction: column;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .content {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .content
    .text {
    font-size: 18px;
    color: #334d6e;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .label {
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .label
    .text {
    color: #0b649d;
    font-size: 10px;
    font-weight: normal;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column {
    position: relative;
    bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .text {
    text-align: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .text
    .value {
    color: #cb561e;
    font-size: 18px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .inner-content {
    position: relative;
    bottom: 5px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .inner-content
    .icon {
    color: #f9873c !important;
    font-size: 14px;
    font-weight: 700;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row {
    padding: 10px 0px;
    position: unset;
    width: 100%;
    bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column
    .text {
    font-size: 14px;
    color: #334d6e !important;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column
    .created-by {
    font-size: 10px;
    color: #90a0b7;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column {
    margin: 0px;
    margin-bottom: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notified,
  .notify {
    cursor: pointer;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notified
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notify
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row {
    padding: 10px 0px;
    position: unset;
    width: 100%;
    bottom: 0px;
  }
  .course-details-notify-box {
    background: #fff;
    margin-top: 20px;
    text-align: center;
    padding: 20px;
    font-size: 16px;
    font-weight: 400;
    box-shadow: 0px 2px 10px rgb(90 114 200 / 8%);
    border-radius: 0px;
  }
  .course-detail-container .content .description-container .inner-container {
    background: #fff;
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 0px;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .description-container
    .inner-container
    .card {
    width: 100%;
  }
  .course-detail-container
    .content
    .description-container
    .inner-container
    .card
    .content {
    padding: 10px;
    font-size: 14px;
  }
  .course-detail-container .content .faq-container {
    margin: 0px;
  }
  .course-detail-container .content .faq-container .content {
    background: #fff;
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    padding-bottom: 50px;
  }
  .course-detail-container .content .faq-container .content .card {
    width: 100%;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .collapse-image {
    width: 20px;
    position: relative;
    top: 3px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .panel-header {
    color: #0b649d !important;
    font-weight: 500;
    line-height: 24px;
    font-size: 16px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .panel-answer {
    margin: 0px 0px !important;
    font-size: 16px;
  }
  .course-detail-container .content .features-container {
    margin-top: 20px;
  }
  .course-detail-container .content .features-container .content {
    background: #fff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  }
  .course-detail-container .content .features-container .content .card {
    width: 100%;
    padding: 0px 20px 20px;
  }
  .course-detail-container
    .content
    .features-container
    .content
    .card
    .list-item {
    display: block;
    border-bottom: 0px solid;
  }
  .course-detail-container
    .content
    .features-container
    .content
    .card
    .list-item
    .image {
    width: 20px;
    margin-right: 15px;
  }
  .course-detail-container .content .instructor-container {
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 0px;
  }
  .course-detail-container .content .instructor-container .content {
    margin: 0px;
  }
  .course-detail-container .content .instructor-container .content .card {
    width: 100%;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content {
    padding: 0px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content {
    padding: 10px 0px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content
    .image {
    border-radius: 90px;
    width: 50px;
    height: 50px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content
    .span {
    margin: 0px 10px;
    text-transform: capitalize;
  }
  .course-detail-container .content .support-container {
    margin-top: 0px;
  }
  .course-detail-container .content .support-container .content {
    background: #fff;
    margin: 0px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
  }
  .course-detail-container .content .support-container .content .card {
    width: 100%;
  }
  .course-detail-container .content .support-container .content .card .row {
    width: 100%;
    align-items: center;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .title-column {
    background: #e0f3ff;
    text-align: center;
    padding: 25px;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .text {
    font-weight: bold;
    font-size: 14px;
    color: #0b649d;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .support-column {
    padding: 10px;
    text-align: center;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .support-column
    .icon {
    border: 1px solid #0b649d;
    border-radius: 90px;
    width: 45px;
    object-fit: none;
    cursor: pointer;
  }
  .module-container {
    margin-top: 0px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 4px;
  }
  .course-detail-container .content .module-container .content {
    background: #fff;
    margin: 0px;
    padding: 0px 20px;
  }
  .course-detail-container .content .module-container .content .card .row {
    width: 100%;
    align-items: flex-start;
    padding: 20px 0px;
    justify-content: flex-start;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column {
    padding: 20px;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .image {
    width: 40px;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content {
    padding: 0px 10px;
    white-space: nowrap;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content
    .value {
    font-size: 16px;
    font-weight: 700;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content
    .title {
    font-size: 10px;
    color: #90a0b7;
  }
  .course-detail-container .content .free-container {
    margin: 0px;
  }
  .course-detail-container .content .free-container .content {
    padding: 15px;
    background: #fffbe6;
    width: 70%;
    margin: auto;
    text-align: center;
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #f0efef;
  }
  .course-detail-container .content .free-container .content .inner-content {
    padding: 10px;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .image {
    position: relative;
    bottom: 3px;
    right: 10px;
    width: 20px;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .text {
    color: #7ba353;
    font-size: 18px;
    font-weight: bold;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .button {
    border-radius: 6px !important;
    background: #ee2d3b;
    font-weight: 600;
    color: #fff !important;
    width: 140px;
    height: 40px !important;
    font-size: 12px;
  }
  .course-detail-container .content .unsubscribe-container {
    margin: 0px;
    padding-bottom: 15px;
  }
  .course-detail-container .content .unsubscribe-container .content {
    padding: 10px;
    background: #fffbe6;
    width: 90%;
    margin: auto;
    text-align: center;
    border-top: 1px solid #f0efef;
    position: relative;
    bottom: 20px;
  }
  .course-detail-container .content .unsubscribe-container .content .row {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .text-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .text-column
    .text {
    color: #7ba353;
    font-size: 20px;
    font-weight: bold;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column {
    padding: 5px 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .image-span {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .image-span
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .date-span {
    padding: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .date-span
    .text {
    color: red;
    font-weight: 600;
    font-size: 12px;
    white-space: nowrap;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    height: 40px;
    background: #c4c4c4;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 600;
    width: auto;
  }
  .course-detail-container .content .price-container {
    margin: 0px;
    padding-bottom: 15px;
  }
  .course-detail-container .content .price-container .content {
    padding: 10px;
    background: #fffbe6;
    width: 90%;
    margin: auto;
    text-align: center;
    border-top: 1px solid #f0efef;
    position: relative;
    bottom: 5px;
  }
  .course-detail-container .content .price-container .content .row {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .price-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .price-column
    .text {
    font-weight: bold;
    font-size: 16px;
    font-family: sans-serif;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column {
    padding: 5px 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .image-span {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .image-span
    .image {
    margin: 0px;
    width: 20px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .text-span {
    padding: 10px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .text-span
    .text {
    color: red;
    font-weight: 600;
    font-size: 12px;
    white-space: nowrap;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    height: 45px;
    background: #c4c4c4;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 600;
    height: 40px;
    width: auto;
  }
  .course-detail-container .content .special-price-container {
    margin: 0px;
  }
  .course-detail-container .content .special-price-container .content {
    background: #fffbe6;
    width: 95%;
    margin: auto;
    text-align: center;
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #f0efef;
  }
  .course-detail-container .content .special-price-container .content .row {
    padding: 5px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .clock-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .clock-column
    .image {
    width: 60px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .time-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .label {
    color: #0b649d;
    font-weight: bold;
    padding: 0px 10px;
    font-size: 12px;
    display: contents;
    justify-content: center;
    align-items: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .text {
    font-weight: 900;
    color: red;
    letter-spacing: 1px;
    font-size: 14px;
    padding: 0px 5px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row {
    display: flex;
    justify-content: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .save-span {
    color: #01a54e;
    font-size: 12px;
    padding: 5px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .price-text {
    font-size: 18px;
    margin: 20px;
    font-weight: bolder;
    font-family: sans-serif;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .expire-text {
    font-size: 12px;
    margin: 20px;
    font-weight: 600;
    font-family: sans-serif;
    position: relative;
    color: #c4c4c4;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .expire-text:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid #ee2d3b !important;
    border-color: inherit;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-10deg);
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    height: 40px;
    width: 100%;
    font-size: 12px;
  }
  .card-view-button {
    color: #0b649d;
    font-size: 13px;
    font-weight: 900;
    border: 1px solid #0b649d;
    background: #e6f7ff;
    border-radius: 4px;
    letter-spacing: 1px;
  }
  .action-content {
    padding-bottom: 10px;
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 20px 0;
  }
}

@media (min-width: 1025px) {
  .evenheight {
    height: calc(100% - 16vh);
  }
  .course-detail-container {
    margin: auto;
    min-height: 100vh;
    height: 100%;
    width: 70%;
  }
  .course-detail-container .breadcrumb-container {
    margin: 0px;
  }
  .course-detail-container .breadcrumb-container .breadcrumb {
    margin: 0px !important;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .item {
    cursor: pointer;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .item .link {
    color: #0b649d;
    font-size: 14px;
  }
  .course-detail-container .breadcrumb-container .breadcrumb .active-item {
    color: #0b649d;
    font-weight: 400;
    font-size: 14px;
  }
  .course-detail-container .content {
    margin: 0px;
  }
  .course-detail-container .content .container {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgb(90 114 200 / 8%);
    border-radius: 0px;
    margin-top: 20px;
  }
  .course-detail-container .content .container .inner-container {
    background: #fff;
    border-radius: 0px;
  }
  .course-detail-container .content .container .inner-container .row {
    align-items: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .banner-column {
    padding: 20px;
    border-radius: 7px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .banner-column
    .image {
    border-radius: 7px;
    width: 100%;
    min-height: 100%;
    height: auto;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column {
    padding: 0px;
    height: auto;
    justify-content: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content {
    height: 100%;
    justify-content: center;
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .title-row {
    margin-bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .title-row
    .title {
    font-weight: 700;
    font-size: 28px;
    color: #334d6e;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row {
    padding: 10px 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column
    .text {
    font-size: 16px;
    color: #334d6e !important;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row
    .column
    .created-by {
    font-size: 12px;
    color: #90a0b7;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row {
    align-items: center;
    padding: 0px;
    justify-content: flex-start;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .image-container {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .image-container
    .image {
    width: 25px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column {
    display: flex;
    flex-direction: column;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .content {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .content
    .text {
    font-size: 18px;
    color: #334d6e;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .label {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .language-column
    .row
    .column
    .label
    .text {
    color: #0b649d;
    font-size: 14px;
    font-weight: normal;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .image-container {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .image-container
    .image {
    width: 25px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column {
    display: flex;
    flex-direction: column;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .content {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .content
    .text {
    font-size: 18px;
    color: #334d6e;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .label {
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .subscriber-column
    .row
    .column
    .label
    .text {
    color: #0b649d;
    font-size: 14px;
    font-weight: normal;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column {
    position: relative;
    bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .text {
    text-align: center;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .text
    .value {
    font-size: 20px;
    color: #cb561e !important;
    font-weight: 700;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .inner-content {
    position: relative;
    bottom: 5px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .content-row
    .rating-column
    .row
    .content
    .inner-content
    .icon {
    color: #f9873c !important;
    font-size: 18px;
    font-weight: 700;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row {
    padding: 10px 0px;
    position: unset;
    width: 100%;
    bottom: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column
    .text {
    font-size: 16px;
    color: #334d6e !important;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .preference-column
    .created-by {
    font-size: 14px;
    color: #90a0b7;
    font-weight: 400;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notified,
  .notify {
    cursor: pointer;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notified
    .image {
    margin: 0px;
    width: 25px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .footer-row
    .notification-column
    .notify
    .image {
    margin: 0px;
    width: 25px;
  }
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    .content
    .preference-row {
    padding: 10px 0px;
    position: unset;
    width: 100%;
    bottom: 0px;
  }
  .course-details-notify-box {
    background: #fff;
    margin-top: 20px;
    text-align: center;
    padding: 20px;
    font-size: 16px;
    font-weight: 400;
    box-shadow: 0px 2px 10px rgb(90 114 200 / 8%);
    border-radius: 0px;
  }
  .course-detail-container .content .description-container .inner-container {
    background: #fff;
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 0px;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .description-container
    .inner-container
    .card {
    width: 100%;
  }
  .course-detail-container
    .content
    .description-container
    .inner-container
    .card
    .content {
    padding: 20px;
    font-size: 14px;
  }
  .course-detail-container .content .faq-container {
    margin: 0px;
  }
  .course-detail-container .content .faq-container .content {
    background: #fff;
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    padding-bottom: 50px;
  }
  .course-detail-container .content .faq-container .content .card {
    width: 100%;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .collapse-image {
    width: 20px;
    position: relative;
    top: 3px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .panel-header {
    color: #0b649d !important;
    font-weight: 500;
    line-height: 24px;
    font-size: 16px;
  }
  .course-detail-container
    .content
    .faq-container
    .content
    .card
    .panel-answer {
    margin: 0px 50px !important;
    font-size: 16px;
  }
  .course-detail-container .content .features-container {
    margin-top: 20px;
  }
  .course-detail-container .content .features-container .content {
    background: #fff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  }
  .course-detail-container .content .features-container .content .card {
    width: 100%;
    padding: 0px 20px 20px;
  }
  .course-detail-container
    .content
    .features-container
    .content
    .card
    .list-item {
    display: block;
    border-bottom: 0px solid;
  }
  .course-detail-container
    .content
    .features-container
    .content
    .card
    .list-item
    .image {
    width: 20px;
    margin-right: 15px;
  }
  .course-detail-container .content .instructor-container {
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 0px;
  }
  .course-detail-container .content .instructor-container .content {
    margin: 0px;
  }
  .course-detail-container .content .instructor-container .content .card {
    width: 100%;
    padding: 0px 20px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content {
    padding: 20px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content {
    padding: 10px 0px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content
    .image {
    border-radius: 90px;
    width: 50px;
    height: 50px;
  }
  .course-detail-container
    .content
    .instructor-container
    .content
    .card
    .content
    .inner-content
    .span {
    margin: 0px 10px;
    text-transform: capitalize;
  }
  .course-detail-container .content .support-container {
    margin-top: 0px;
  }
  .course-detail-container .content .support-container .content {
    background: #fff;
    margin: 0px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
  }
  .course-detail-container .content .support-container .content .card {
    width: 100%;
  }
  .course-detail-container .content .support-container .content .card .row {
    width: 100%;
    align-items: center;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .title-column {
    background: #e0f3ff;
    text-align: center;
    padding: 25px;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .text {
    font-weight: bold;
    font-size: 18px;
    color: #0b649d;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .support-column {
    padding: 10px;
    text-align: center;
  }
  .course-detail-container
    .content
    .support-container
    .content
    .card
    .row
    .support-column
    .icon {
    border: 1px solid #0b649d;
    border-radius: 90px;
    width: 60px;
    object-fit: none;
    cursor: pointer;
  }
  .module-container {
    margin-top: 0px;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.08);
    border-radius: 4px;
  }
  .course-detail-container .content .module-container .content {
    background: #fff;
    margin: 0px;
    padding: 0px 20px;
  }
  .course-detail-container .content .module-container .content .card .row {
    width: 100%;
    align-items: flex-start;
    padding: 20px 0px;
    justify-content: flex-start;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column {
    padding: 20px;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .image {
    width: 50px;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content {
    padding: 0px 10px;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content
    .value {
    font-size: 18px;
    font-weight: 700;
  }
  .course-detail-container
    .content
    .module-container
    .content
    .card
    .row
    .column
    .column-content
    .content
    .title {
    font-size: 12px;
    color: #90a0b7;
  }
  .course-detail-container .content .free-container {
    margin: 0px;
  }
  .course-detail-container .content .free-container .content {
    padding: 15px;
    background: #fffbe6;
    width: 70%;
    margin: auto;
    text-align: center;
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #f0efef;
  }
  .course-detail-container .content .free-container .content .inner-content {
    padding: 10px;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .image {
    position: relative;
    bottom: 5px;
    right: 5px;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .text {
    color: #7ba353;
    font-size: 22px;
    font-weight: bold;
  }
  .course-detail-container
    .content
    .free-container
    .content
    .inner-content
    .button {
    border-radius: 6px !important;
    background: #ee2d3b;
    font-weight: 600;
    color: #fff !important;
    width: 170px;
    height: 45px !important;
  }
  .course-detail-container .content .unsubscribe-container {
    margin: 0px;
  }
  .course-detail-container .content .unsubscribe-container .content {
    padding: 10px;
    background: #fffbe6;
    width: 90%;
    margin: auto;
    text-align: center;
    border-top: 1px solid #f0efef;
    position: relative;
    bottom: 20px;
  }
  .course-detail-container .content .unsubscribe-container .content .row {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .text-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .text-column
    .text {
    color: #7ba353;
    font-size: 30px;
    font-weight: bold;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column {
    padding: 5px 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .image-span {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .image-span
    .image {
    margin: 0px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .date-span {
    padding: 10px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .date-column
    .date-span
    .text {
    color: red;
    font-weight: 600;
    font-size: 14px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .unsubscribe-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    height: 45px;
    background: #c4c4c4;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
    width: 150px;
  }
  .course-detail-container .content .price-container {
    margin: 0px;
  }
  .course-detail-container .content .price-container .content {
    padding: 10px;
    background: #fffbe6;
    width: 90%;
    margin: auto;
    text-align: center;
    border-top: 1px solid #f0efef;
    position: relative;
    bottom: 16px;
  }
  .course-detail-container .content .price-container .content .row {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .price-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .price-column
    .text {
    font-weight: bold;
    font-size: 32px;
    font-family: sans-serif;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column {
    padding: 5px 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .image-span {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .image-span
    .image {
    margin: 0px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .text-span {
    padding: 10px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .date-column
    .text-span
    .text {
    color: red;
    font-weight: 600;
    font-size: 16px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .price-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    background: #c4c4c4;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
    height: 40px;
    width: 150px;
  }
  .course-detail-container .content .special-price-container {
    margin: 0px;
  }
  .course-detail-container .content .special-price-container .content {
    background: #fffbe6;
    width: 95%;
    margin: auto;
    text-align: center;
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #f0efef;
  }
  .course-detail-container .content .special-price-container .content .row {
    padding: 10px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .clock-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .clock-column
    .image {
    width: 80px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .time-row {
    display: flex;
    justify-content: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .label {
    color: #0b649d;
    font-weight: bold;
    padding: 0px 10px;
    font-size: 18px;
    display: contents;
    justify-content: center;
    align-items: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .text {
    font-weight: 900;
    color: red;
    letter-spacing: 1px;
    font-size: 18px;
    padding: 0px 5px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row {
    display: flex;
    justify-content: center;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column {
    margin: 0px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .save-span {
    color: #01a54e;
    font-size: 18px;
    padding: 5px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .price-text {
    font-size: 32px;
    margin: 20px;
    font-weight: bolder;
    font-family: sans-serif;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .expire-text {
    font-size: 22px;
    margin: 20px;
    font-weight: 600;
    font-family: sans-serif;
    position: relative;
    color: #c4c4c4;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .timer-column
    .price-row
    .column
    .expire-text:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid #ee2d3b !important;
    border-color: inherit;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-10deg);
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .button-column {
    padding: 10px;
  }
  .course-detail-container
    .content
    .special-price-container
    .content
    .row
    .button-column
    .button {
    border-radius: 6px !important;
    height: 40px;
    width: 150px;
  }
  .card-view-button {
    color: #0b649d;
    font-size: 13px;
    font-weight: 900;
    border: 1px solid #0b649d;
    background: #e6f7ff;
    border-radius: 4px;
    letter-spacing: 1px;
  }
  .action-content {
    padding-bottom: 10px;
  }
}

.course-detail-container
  .content
  .container
  .inner-container
  .row
  .content-column
  > .content {
  padding: 20px 0px;
}

.course-detail-container .content .container .inner-container > .row {
  align-items: stretch !important;
}
.card-view-button:hover {
  color: #0b649d;
  font-size: 13px;
  font-weight: 900;
  border: 1px solid #0b649d;
  background: #ffffff;
  border-radius: 4px;
  letter-spacing: 1px;
}
.quiz-attempt0-button:hover {
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  background: #fff;
  color: #0b649d;
  border: 1px solid #0b649d;
}
.solution-quiz-button:hover {
  background: #fff;
  color: #0b649d !important;
  border-color: #0b649d !important;
  cursor: pointer;
}
@media (max-width: 1199px) {
  .course-detail-container
    .content
    .container
    .inner-container
    .row
    .content-column
    > .content {
    padding: 8px 0px;
  }
  .evenheight {
    height: calc(100% - 10vh);
  }
}

@media (min-width: 1100px) {
  .evenheight {
    height: calc(100% - 16vh);
  }
}
@media (min-width: 1300px) {
  .evenheight {
    height: calc(100% - 16vh);
  }
}

@media (min-width: 1440px) {
  .evenheight {
    height: calc(100% - 12vh);
  }
}

@media (min-width: 2560px) {
  .evenheight {
    height: calc(100% - 8vh);
  }
}