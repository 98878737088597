
.quiz-container {
    margin: 10px;
}
.quiz-container .row {
    margin: 0px;  padding: 10px;
}
.quiz-container .row .column {
    margin: 0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
.quiz-container .row .column .main-container {
    margin: 10px; padding: 15px;  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16); border-radius: 12px; background: #fff;
}
.quiz-container .row .column .main-container .card {
    margin: 0px; border: 0px solid;
}
.quiz-container .row .column .main-container .card .card-content {
    margin: 0px;
}
.quiz-container .row .column .main-container .card .card-content .image-container {
    margin: 0px;
}
.quiz-container .row .column .main-container .card .card-content .image-container .content {
    position: absolute; right: 0; margin: auto; z-index: 1; left: 0; width: fit-content;
}
.quiz-container .row .column .main-container .card .card-content .image-container .content .image1 {
    width: 100%; border-radius: 4px; min-height: auto; height: 190px; object-fit: cover; filter: drop-shadow(4px 6px 8px rgb(206, 206, 206));
}
.quiz-container .row .column .main-container .card .card-content .image-container .image2 {
    width: 100%; border-radius: 8px; min-height: 190px; height: 190px; object-fit: cover; filter: blur(6px);
}
.quiz-container .row .column .main-container .card .card-content .body-content {
    margin-top: 10px;
}
.quiz-container .row .column .main-container .card .card-content .body-content .inner-content {
    margin: 0px;
}
.quiz-container .row .column .main-container .card .card-content .body-content .inner-content .nowrap-content .column {
    margin: 0px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
}
.quiz-container .row .column .main-container .card .card-content .body-content .inner-content .nowrap-content .column .title {
    font-size: 16px; font-weight: 500; color: #151515 !important; text-transform: capitalize;
}
.quiz-container .row .column .main-container .card .card-content .body-content .body-footer {
    margin: 0px;
}
.quiz-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .language-image {
    width: 30px; padding: 0px 5px;
}
.quiz-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .like-image {
    padding: 0px 5px; width: 34px;
}
.quiz-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .view-image {
    padding: 0px 5px; width: 28px;
}
.quiz-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .calendar-image {
    width: 32px; padding: 0px 5px; 
}
.quiz-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .image-label {
    color: #0B649D; font-weight: normal; font-size: 14px;
}
.quiz-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .image-label-upcoming {
    color: #3C4852; font-weight: normal; font-size: 14px;
}
.quiz-attempt0-button {
    font-size: 12px; font-weight: bold; border-radius: 5px; background: #E6F7FF; color: #0B649D; border: 1px solid #0B649D;
}
.quiz-upcoming-button {
    width: 100%; font-size: 12px; font-weight: bold; border-radius: 5px; background:#F9E2E7; color: #F2244A; border: 1px solid #F2244A;
}
.quiz-attempt2-button {
    font-size: 12px; font-weight: bold; border-radius: 5px; border: 1px solid #E49836 !important; color: #E49836 !important; background: #FFFBE6 !important;
}
.quiz-attempt1-solution-button {
    font-size: 12px; font-weight: bold; border-radius: 5px; border: 1px solid #46760A !important; color: #46760A !important; background: #F4F8EC !important;
}
.quiz-start-content-text {
    font-size: 12px; font-weight: normal; color: #0B649D;
}
.quiz-resume-content-text {
    font-size: 12px; font-weight: normal; color: #FFA500;
}
.quiz-attempted-content-text {
    font-size: 12px; font-weight: normal; color: #6A983C;
}
.course-details-quiz-attempt-button-icon {
    font-size: 12px;
}