@media (max-width: 768px) {
    .video-container .video-container-row .video-container-column .video-content {
        padding: 5px !important;
    }
    .video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-card-inner-content .video-iframe-span .video-iframe {
        height: 160px !important;
    }
    .video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-card-inner-content .video-iframe-span .video-overlay {
        height: 160px !important; width: 100% !important;
    }
    .video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-body-inner-content .video-body-content-row .video-title-column .video-title {
        font-size: 14px !important;
    }
    .video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-language-column .video-language-text {
        font-size: 12px !important;
    }
    .video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-votes-column .video-votes-value {
        font-size: 12px !important;
    }
    .video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-view-column .video-view-value {
        font-size: 12px !important;
    }
    .video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-valitity-column .video-validity-date {
        font-size: 12px !important;
    }
}

@media (max-width: 1024px) {
    .video-container .video-container-row .video-container-column .video-content {
        padding: 10px !important;
    }
    .video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-card-inner-content .video-iframe-span .video-iframe {
        height: 180px !important;
    }
    .video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-card-inner-content .video-iframe-span .video-overlay {
        height: 180px !important; width: 100% !important;
    }
    .video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-body-inner-content .video-body-content-row .video-title-column .video-title {
        font-size: 16px !important;
    }
    .video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-language-column .video-language-text {
        font-size: 14px !important;
    }
    .video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-votes-column .video-votes-value {
        font-size: 14px !important;
    }
    .video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-view-column .video-view-value {
        font-size: 14px !important;
    }
    .video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-valitity-column .video-validity-date {
        font-size: 14px !important;
    }
}

.video-container {
    margin: 0px;
}
.video-container .video-container-row {
    margin: 0px; padding: 15px;
}
.video-container .video-container-row .video-container-column {
    margin: 0px; 
}
.video-container .video-container-row .video-container-column .video-content {
    margin: 0px; padding: 15px;  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16); border-radius: 12px; background: #fff;
}
.video-container .video-container-row .video-container-column .video-content .video-card {
    margin: 0px; border: 0px solid
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content {
    margin: 0px; border-radius: 10px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-card-inner-content {
    margin: 0px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-card-inner-content .video-iframe-span {
    margin: 0px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-card-inner-content .video-iframe-span .video-iframe {
    margin: 0px; width: 100%; height: 220px; border-radius: 12px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-card-inner-content .video-iframe-span .video-overlay {
    height: 220px; position: absolute; width: 106%; background: transparent; top: 0px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content {
    margin: 0px; padding-top: 5px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-body-inner-content {
    margin: 0px; padding-bottom: 5px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-body-inner-content .video-body-content-row {
    margin: 0px; 
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-body-inner-content .video-body-content-row .video-title-column {
    margin: 0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-body-inner-content .video-body-content-row .video-title-column .video-title {
    margin: 0px; font-size: 16px; font-weight: 400;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-body-inner-content .video-body-content-row .video-menu-column {
    margin: 0px; 
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions {
    margin: 0px; padding-bottom: 10px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row {
    margin: 0px; 
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-language-column {
    margin: 0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-language-column .video-language-image {
    margin: 0px; width: 20px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-language-column .video-language-text {
    margin: 0px; padding: 0px 5px; font-size: 14px; color: #0B649D; font-size: 12px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-votes-column {
    margin: 0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-votes-column .video-votes-image {
    margin: 0px; width: 24px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-votes-column .video-votes-value {
    margin: 0px;  padding: 0px 5px; color: #0B649D; font-size: 12px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-view-column {
    margin: 0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-view-column .video-view-image {
    margin: 0px; width: 18px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-view-column .video-view-value {
    margin: 0px;  padding: 0px 5px; color: #0B649D; font-size: 12px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-valitity-column {
    margin: 0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-valitity-column .video-calendar-image {
    margin: 0px; width: 22px;
}
.video-container .video-container-row .video-container-column .video-content .video-card .video-card-content .video-body-content .video-actions .video-actions-row .video-valitity-column .video-validity-date {
    margin: 0px;  padding: 0px 5px; color: #0B649D; font-size: 12px;
}