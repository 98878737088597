
@media (max-width: 1024px) {
    .ebook-container .row .column .main-container .card .card-content .image-container .content .image1 {
        width: 100%; border-radius: 4px; min-height: auto; height: 120px !important; object-fit: cover; filter: drop-shadow(4px 6px 8px rgb(206, 206, 206));
    }
    .ebook-container .row .column .main-container .card .card-content .image-container .image2 {
        width: 100%; border-radius: 8px; min-height: 120px !important; height: 120px !important; object-fit: cover; padding: 10px; filter: blur(14px) !important;
    }
}


@media (max-width: 992px) {
    .ebook-container .row .column .main-container .card .card-content .image-container .content .image1 {
        width: 100%; border-radius: 4px; min-height: auto; height: 190px !important; object-fit: cover; filter: drop-shadow(4px 6px 8px rgb(206, 206, 206));
    }
    .ebook-container .row .column .main-container .card .card-content .image-container .image2 {
        width: 100%; border-radius: 8px; min-height: 190px !important; height: 190px !important; object-fit: cover; padding: 10px; filter: blur(14px) !important;
    }
    .ebook-container .row .column .main-container .card .card-content .image-container .content {
        position: absolute; right: 0; margin: auto; z-index: 1; left: 0; width: fit-content; top: 0px;
    }
}

@media (max-width: 768px) {
    .ebook-container .row .column .main-container .card .card-content .image-container .content .image1 {
        width: 100%; border-radius: 4px; min-height: auto; height: 150px !important; object-fit: cover; filter: drop-shadow(4px 6px 8px rgb(206, 206, 206));
    }
    .ebook-container .row .column .main-container .card .card-content .image-container .image2 {
        width: 100%; border-radius: 8px; min-height: 150px !important; height: 150px !important; object-fit: cover; padding: 10px; filter: blur(14px) !important;
    }
    .ebook-container .row .column .main-container .card .card-content .image-container .content {
        position: absolute; right: 0; margin: auto; z-index: 1; left: 0; width: fit-content; top: -10px;
    }
}



.ebook-container {
    margin: 10px;
}
.ebook-container .row {
    margin: 0px;  padding: 10px;
}
.ebook-container .row .column {
    margin: 0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
.ebook-container .row .column .main-container {
    margin: 10px; padding: 15px;  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16); border-radius: 12px; background: #fff;
}
.ebook-container .row .column .main-container .card {
    margin: 0px; border: 0px solid;
}
.ebook-container .row .column .main-container .card .card-content {
    margin: 0px;
}
.ebook-container .row .column .main-container .card .card-content .image-container {
    margin: 0px;
}
.ebook-container .row .column .main-container .card .card-content .image-container .content {
    position: absolute; right: 0; margin: auto; z-index: 1; left: 0; width: fit-content;
}
.ebook-container .row .column .main-container .card .card-content .image-container .content .image1 {
    width: 100%; border-radius: 4px; min-height: auto; height: 190px; object-fit: cover; filter: drop-shadow(4px 6px 8px rgb(206, 206, 206));
}
.ebook-container .row .column .main-container .card .card-content .image-container .image2 {
    width: 100%; border-radius: 8px; min-height: 190px; height: 190px; object-fit: cover; padding: 10px; filter: blur(14px);
}
.ebook-container .row .column .main-container .card .card-content .body-content {
    margin-top: 10px;
}
.ebook-container .row .column .main-container .card .card-content .body-content .inner-content {
    margin: 0px;
}
.ebook-container .row .column .main-container .card .card-content .body-content .inner-content .nowrap-content .column {
    margin: 0px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
}
.ebook-container .row .column .main-container .card .card-content .body-content .inner-content .nowrap-content .column .title {
    font-size: 16px; font-weight: 500; color: #151515 !important; text-transform: capitalize;
}
.ebook-container .row .column .main-container .card .card-content .body-content .body-footer {
    margin: 0px; padding-bottom: 10px;
}
.ebook-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .language-image {
    width: 30px; padding: 0px 5px;
}
.ebook-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .like-image {
    padding: 0px 5px; color: grey; width: 34px;
}
.ebook-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .view-image {
    padding: 0px 5px; color: grey; width: 28px;
}
.ebook-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .calendar-image {
    width: 32px; padding: 0px 5px; 
}
.ebook-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .image-label {
    color: #0B649D; font-weight: normal; font-size: 12px;
}