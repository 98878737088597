.view-earn-popup-title {
  font-size: 20px;
  font-weight: bold;
  color: rgb(11, 100, 157);
  margin-left: 20px;
}
.view-earn-popup-body {
  padding: 10px 25px;
  overflow-y: scroll;
  max-height: 600px;
}
.view-earn-popup-earned-flex {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  padding: 25px;
  justify-content: space-between;
  align-items: center;
}
.view-earn-popup-earned-point {
  background: linear-gradient(rgb(255, 255, 255) 0%, rgb(224, 243, 255) 100%);
  color: rgb(11, 100, 157);
  border: 1px solid rgb(11, 100, 157);
}
.view-earn-popup-body::-webkit-scrollbar {
  width: 7px;
}
.view-earn-popup-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ebeff2;
  border-radius: 7px;
}
.view-earn-popup-body::-webkit-scrollbar-thumb {
  /* background: rgb(241, 239, 239); */
  background: #cfcfcf;
  border-radius: 7px;
}
.view-earn-popup-body::-webkit-scrollbar-thumb:hover {
  background: #d3d8dd;
}

