.doubts-details-Divider .ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 3px 0px 0px 0px;
}
.doubts-answer-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 0px 0px 0px;
}
.ant-modal.add-doubt-answer {
  padding-bottom: 0px;
}
.doubts-answer-flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
}
.doubts-answer-user {
  font-weight: 900;
  font-size: 16px;
  white-space: nowrap;
}
.doubts-answer-published {
  color: grey;
  font-weight: 500;
  font-size: 14px;
}
.doubts-ans-cover-image {
  width: 23%;
  margin: auto;
  border-radius: 10px !important;
  cursor: pointer;
}
.doubts-ans-card-avatar {
  float: left;
  background: #e4e5e7;
  padding: 0px;
  border-radius: 90px;
  margin-right: 5px;
}
.ant-modal.add-doubt-answer {
  padding-bottom: 0px;
}
.ant-modal.doubt-answer-share {
  padding-bottom: 0px;
}

@media (max-width: 2560px) {
  .doubts-modal-content {
    margin: auto;
    display: block;
    width: auto;
    max-width: 400px;
  }
  .doubts-answer-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
  }
  .add-close-circle-icon {
    position: absolute;
    padding: 10px 0px 0px 956px;
    z-index: -1;
    cursor: pointer;
  }
  .add-doubt-answer {
    width: 1000px !important;
  }
  .doubt-answer-share {
    width: 1200px !important;
  }
  .doubt-answer-btn {
    position: absolute;
    right: -31px;
    background: #0b649d;
    border-radius: 0px 20px 20px 0px;
    padding: 4px 10px;
    top: 15px;
    cursor: pointer;
  }
  .doubt-answer-share .doubts-card-content {
    background: #e0f3ff;
    padding: 20px;
  }
  .doubts-popup-card-discubtion {
    padding: 25px 0px 0px 35px;
  }
  .doubts-popup-card-discubtion-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    margin: 0;
    white-space: break-spaces;
  }

  .doubts-details-cards {
    width: 100%;
    padding: 15px;
    border: none;
  }
  .doubts-details-cover-title {
    color: #0b649d;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    padding: 10px 34px;
  }
  .doubts-details-cover-image {
    width: 38%;
    padding: 20px;
    border-radius: 30px !important;
  }
  .boubt-btn-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 10px;
    align-items: flex-start;
  }
  .doubts-details-Menu {
    transform: rotate(90deg);
    color: #000;
    font-size: 22px;
  }
  .doubts-details-scroll {
    overflow-y: scroll;
    max-height: 63vh;
    min-height: 63vh;
  }
  .doubts-popup-details-lable {
    color: rgb(1, 165, 78);
    background: rgb(204, 255, 228);
    padding: 20px;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    border-radius: 4px;
    margin: 0px 40px 0px 0px;
  }
  .all-courses-card .close-circle-icon {
    position: absolute;
    padding: 14px 0px 0px 1119px;
    z-index: -1;
  }
  .doubt-ans-reply {
    padding: 20px 10px;
  }
  textarea.ant-input {
    max-width: 97%;
  }
  .doubt-best-answer {
    color: #01a54e;
    font-weight: 700;
    font-size: 16px;
    margin: 0px 15px;
  }
  .doubts-answer-flex-col {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media (max-width: 1920px) and (min-width: 1600px) {
  .doubts-modal-content {
    margin: auto;
    display: block;
    width: auto;
    max-width: 330px;
  }
  .doubts-answer-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
  }
  .add-close-circle-icon {
    position: absolute;
    padding: 10px 0px 0px 955px;
    z-index: -1;
    cursor: pointer;
  }
  .doubt-answer-share {
    width: 1200px !important;
  }
  .add-doubt-answer {
    width: 1000px !important;
  }
  .doubt-answer-btn {
    position: absolute;
    right: -31px;
    background: #0b649d;
    border-radius: 0px 20px 20px 0px;
    padding: 4px 10px;
    top: 15px;
    cursor: pointer;
  }
  .doubt-answer-share .doubts-card-content {
    background: #e0f3ff;
    padding: 20px;
    border-radius: 8px 8px 0px 0px;
  }
  .doubts-popup-card-discubtion {
    padding: 1px 0px 0px 35px;
  }
  .doubts-details-cards {
    width: 100%;
    padding: 15px 15px 5px 15px;
    border: none;
  }
  .doubts-details-cover-title {
    color: #0b649d;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    padding: 10px 34px;
  }
  .doubts-details-cover-image {
    width: 38%;
    padding: 20px;
    border-radius: 30px !important;
  }
  .boubt-btn-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 10px 7px 10px;
    align-items: flex-start;
  }
  .doubts-details-Menu {
    transform: rotate(90deg);
    color: #000;
    font-size: 22px;
  }
  .doubts-details-scroll {
    overflow-y: scroll;
    max-height: 80vh;
    min-height: 80vh;
  }
  .doubts-popup-details-lable {
    color: rgb(1, 165, 78);
    background: rgb(204, 255, 228);
    padding: 20px;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    border-radius: 4px;
    margin: 0px 40px 0px 0px;
  }
  .all-courses-card .close-circle-icon {
    position: absolute;
    padding: 14px 0px 0px 1119px;
    z-index: -1;
  }
  .doubt-ans-reply {
    padding: 20px 10px;
  }
  textarea.ant-input {
    max-width: 97%;
  }
  .doubt-best-answer {
    color: #01a54e;
    font-weight: 700;
    font-size: 16px;
    margin: 0px 15px;
  }
  .doubts-answer-flex-col {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media (max-width: 1520px) {
  .doubts-modal-content {
    margin: auto;
    display: block;
    width: auto;
    max-width: 330px;
  }
  .doubts-answer-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
  }
  .add-close-circle-icon {
    position: absolute;
    padding: 10px 0px 0px 634px;
    z-index: -1;
  }
  .doubt-answer-share {
    width: 1200px !important;
  }
  .add-doubt-answer {
    width: 678px !important;
  }
  .doubt-answer-btn {
    position: absolute;
    right: -31px;
    background: #0b649d;
    border-radius: 0px 20px 20px 0px;
    padding: 4px 10px;
    top: 15px;
    cursor: pointer;
  }
  .doubt-answer-share .doubts-card-content {
    background: #e0f3ff;
    padding: 20px;
  }
  .doubts-popup-card-discubtion {
    padding: 29px 0px 15px 32px;
  }
  .doubts-details-cards {
    width: 100%;
    padding: 15px 15px 0px 15px;
  }
  .doubts-details-cover-title {
    color: #0b649d;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }
  .doubts-details-cover-image {
    width: 50%;
    padding: 30px;
    border-radius: 50px !important;
  }
  .boubt-btn-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    align-items: flex-start;
  }
  .doubts-details-Menu {
    transform: rotate(90deg);
    color: #000;
    font-size: 22px;
  }
  .doubts-details-scroll {
    overflow-y: scroll;
    max-height: 63vh;
    min-height: 63vh;
  }
  .doubts-popup-details-lable {
    color: rgb(1, 165, 78);
    background: rgb(204, 255, 228);
    padding: 20px;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    border-radius: 4px;
  }
  .all-courses-card .close-circle-icon {
    position: absolute;
    padding: 14px 0px 0px 1119px;
    z-index: -1;
  }
  #comments-block textarea.ant-input {
    max-width: 95%;
  }
  .doubt-ans-reply {
    padding: 20px 10px;
  }
  textarea.ant-input {
    max-width: 97%;
  }
  .doubt-best-answer {
    color: #01a54e;
    font-weight: 700;
    font-size: 16px;
    margin: 0px 10px;
  }
  .doubts-answer-flex-col {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media (max-width: 1366px) {
  .doubts-modal-content {
    margin: auto;
    display: block;
    width: auto;
    max-width: 330px;
  }
  .doubts-answer-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
  }
  .add-close-circle-icon {
    position: absolute;
    padding: 10px 0px 0px 955px;
    z-index: -1;
  }
  .doubt-answer-share {
    width: 1200px !important;
  }
  .add-doubt-answer {
    width: 1000px !important;
  }
  .doubt-answer-btn {
    position: absolute;
    right: -31px;
    background: #0b649d;
    border-radius: 0px 20px 20px 0px;
    padding: 4px 10px;
    top: 15px;
    cursor: pointer;
  }
  .doubt-answer-share .doubts-card-content {
    background: #e0f3ff;
    padding: 20px;
  }
  .doubts-popup-card-discubtion {
    padding: 29px 0px 15px 32px;
  }
  .doubts-details-cards {
    width: 100%;
    padding: 15px 15px 0px 15px;
  }
  .doubts-details-cover-title {
    color: #0b649d;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }
  .doubts-details-cover-image {
    width: 50%;
    padding: 30px;
    border-radius: 30px !important;
  }
  .boubt-btn-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    align-items: flex-start;
  }
  .doubts-details-Menu {
    transform: rotate(90deg);
    color: #000;
    font-size: 22px;
  }
  .doubts-details-scroll {
    overflow-y: scroll;
    max-height: 63vh;
    min-height: 63vh;
  }
  .doubts-popup-details-lable {
    color: rgb(1, 165, 78);
    background: rgb(204, 255, 228);
    padding: 20px;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    border-radius: 4px;
  }
  .all-courses-card .close-circle-icon {
    position: absolute;
    padding: 14px 0px 0px 1119px;
    z-index: -1;
  }
  #comments-block textarea.ant-input {
    max-width: 95%;
  }
  .doubt-ans-reply {
    padding: 20px 10px;
  }
  textarea.ant-input {
    max-width: 97%;
  }
  .doubt-best-answer {
    color: #01a54e;
    font-weight: 700;
    font-size: 16px;
    margin: 0px 10px;
  }
  .doubts-answer-flex-col {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media (max-width: 1440px) and (min-width: 1300px) {
  .doubts-modal-content {
    margin: auto;
    display: block;
    width: auto;
    max-width: 300px;
  }
  .doubts-answer-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
  }
  .add-close-circle-icon {
    position: absolute;
    padding: 10px 0px 0px 634px;
    z-index: -1;
  }
  .doubt-answer-share {
    width: 1200px !important;
  }
  .add-doubt-answer {
    width: 678px !important;
  }
  .doubt-answer-btn {
    position: absolute;
    right: -31px;
    background: #0b649d;
    border-radius: 0px 20px 20px 0px;
    padding: 4px 10px;
    top: 15px;
    cursor: pointer;
  }
  .doubt-answer-share .doubts-card-content {
    background: #e0f3ff;
    padding: 20px;
  }
  .doubts-popup-card-discubtion {
    padding: 29px 0px 15px 32px;
  }
  .doubts-details-cards {
    width: 100%;
    padding: 15px 15px 0px 15px;
  }
  .doubts-details-cover-title {
    color: #0b649d;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }
  .doubts-details-cover-image {
    width: 50%;
    padding: 30px;
    border-radius: 30px !important;
  }
  .boubt-btn-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    align-items: flex-start;
  }
  .doubts-details-Menu {
    transform: rotate(90deg);
    color: #000;
    font-size: 22px;
  }
  .doubts-details-scroll {
    overflow-y: scroll;
    max-height: 63vh;
    min-height: 63vh;
  }
  .doubts-popup-details-lable {
    color: rgb(1, 165, 78);
    background: rgb(204, 255, 228);
    padding: 20px;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    border-radius: 4px;
  }
  .all-courses-card .close-circle-icon {
    position: absolute;
    padding: 14px 0px 0px 1119px;
    z-index: -1;
  }
  #comments-block textarea.ant-input {
    max-width: 95%;
  }
  .doubt-ans-reply {
    padding: 20px 10px;
  }
  textarea.ant-input {
    max-width: 97%;
  }
  .doubt-best-answer {
    color: #01a54e;
    font-weight: 700;
    font-size: 16px;
    margin: 0px 10px;
  }
  .doubts-answer-flex-col {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media (max-width: 1024px) {
  .doubts-modal-content {
    margin: auto;
    display: block;
    width: auto;
    max-width: 280px;
  }
  .doubts-answer-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
  }
  .add-doubt-answer {
    width: 678px !important;
  }
  .add-close-circle-icon {
    position: absolute;
    padding: 10px 0px 0px 633px;
    z-index: -1;
  }
  .doubt-answer-share {
    width: 750px !important;
  }
  .doubt-answer-btn {
    position: absolute;
    right: -31px;
    background: #0b649d;
    border-radius: 0px 20px 20px 0px;
    padding: 4px 10px;
    top: 15px;
    cursor: pointer;
  }
  .doubt-answer-share .doubts-card-content {
    background: #e0f3ff;
    padding: 20px;
  }
  .doubts-popup-card-discubtion {
    padding: 20px 0px 15px 32px;
  }
  .doubts-details-cards {
    width: 100%;
    padding: 15px;
  }
  .doubts-details-cover-title {
    color: #0b649d;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }
  .doubts-details-cover-image {
    width: 75%;
    padding: 30px;
    border-radius: 30px !important;
  }
  .boubt-btn-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    align-items: flex-start;
  }
  .doubts-details-Menu {
    transform: rotate(90deg);
    color: #000;
    font-size: 22px;
  }
  .doubts-details-scroll {
    overflow-y: scroll;
    max-height: 63vh;
    min-height: 63vh;
  }
  .doubts-popup-details-lable {
    color: rgb(1, 165, 78);
    background: rgb(204, 255, 228);
    padding: 20px;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    border-radius: 4px;
  }
  .all-courses-card .close-circle-icon {
    position: absolute;
    padding: 14px 0px 0px 670px;
    z-index: -1;
  }
  #comments-block textarea.ant-input {
    max-width: 95%;
  }
  .doubt-ans-reply {
    padding: 20px 10px;
  }
  textarea.ant-input {
    max-width: 97%;
  }
  .doubt-best-answer {
    color: #01a54e;
    font-weight: 700;
    font-size: 16px;
    margin: 0px 10px;
  }
  .doubts-answer-flex-col {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .doubts-modal-content {
    margin: auto;
    display: block;
    width: auto;
    max-width: 280px;
  }
  .doubts-answer-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0px;
  }
  .add-close-circle-icon {
    position: absolute;
    padding: 10px 0px 0px 518px;
    z-index: -1;
  }
  .add-doubt-answer {
    width: 560px !important;
  }
  .doubt-answer-share {
    width: 570px !important;
  }
  .doubt-answer-btn {
    position: absolute;
    right: -31px;
    background: #0b649d;
    border-radius: 0px 20px 20px 0px;
    padding: 4px 10px;
    top: 15px;
    cursor: pointer;
  }
  .doubt-answer-share .doubts-card-content {
    background: #e0f3ff;
    padding: 15px;
  }
  .doubts-popup-card-discubtion {
    padding: 20px 0px 0px 25px;
  }
  .doubts-details-cards {
    width: 100%;
    padding: 0px 10px;
  }
  .doubts-details-cover-title {
    color: #0b649d;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding: 8px 25px;
  }
  .doubts-details-cover-image {
    width: 50%;
    padding: 0px;
    border-radius: 25px !important;
  }
  .boubt-btn-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    align-items: flex-start;
    margin-right: 0px;
  }
  .doubts-details-Menu {
    transform: rotate(90deg);
    color: #000;
    font-size: 22px;
  }
  .doubts-details-scroll {
    overflow-y: scroll;
    max-height: 63vh;
    min-height: 63vh;
  }
  .doubts-popup-details-lable {
    color: rgb(1, 165, 78);
    background: rgb(204, 255, 228);
    padding: 20px;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    border-radius: 4px;
  }
  .all-courses-card .close-circle-icon {
    position: absolute;
    padding: 14px 0px 0px 489px;
    z-index: -1;
  }
  #comments-block textarea.ant-input {
    max-width: 91%;
  }
  .doubt-ans-reply {
    padding: 20px 10px;
  }
  textarea.ant-input {
    max-width: 87%;
  }
  .doubt-best-answer {
    color: #01a54e;
    font-weight: 700;
    font-size: 14px;
    margin: 0px 20px 0px 50px;
  }
  .doubts-answer-flex-col {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media (max-width: 425px) {
  .doubts-modal-content {
    margin: auto;
    display: block;
    width: auto;
    max-width: 250px;
  }
  .doubt-answer-btn {
    position: absolute;
    right: -31px;
    background: #0b649d;
    border-radius: 0px 20px 20px 0px;
    padding: 4px 10px;
    top: 15px;
    cursor: pointer;
  }
  .doubts-answer-flex-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .add-close-circle-icon {
    position: absolute;
    padding: 10px 0px 0px 365px;
    z-index: -1;
    cursor: pointer;
  }
  .add-doubt-answer {
    width: 678px !important;
  }
  .helpothers_s {
    background-color: #ffff;
    margin: 36px -32px 36px 32px;
    padding: 38px;
  }
}

@media (max-width: 375) {
  .add-close-circle-icon {
    position: absolute;
    padding: 5px 0px 0px 320px;
    z-index: -1;
    cursor: pointer;
  }
}
.all-courses-scroll {
  padding: 25px;
  overflow-y: scroll;
}
.solved-add-doubt-answer {
  padding: 25px;
  overflow-y: scroll;
}
.answer-doubts-popup {
  padding: 25px;
  overflow-y: scroll;
}
.createdoubts-post-image {
  margin: 0px 0px 0px 12px;
}
.createdoubts-post-image .ant-image-img {
  max-height: 340px;
  border-radius: 10px;
}
.createdoubts-post-image .ant-image-mask {
  border-radius: 10px;
}
.answerDoubts-post-image {
  margin: 0px 0px 0px 70px;
}
.answerDoubts-post-image .ant-image-img {
  max-height: 340px;
  border-radius: 10px;
}
.answerDoubts-post-image .ant-image-mask {
  border-radius: 10px;
}
.Create-closer {
  position: absolute;
  left: 160px;
  top: 101px;
  cursor: pointer;
}
.Create-audio-closer {
  position: absolute;
  left: 320px;
  top: 101px;
}
@media screen and (max-width: 2560px) and (max-height: 1220px) {
  .all-courses-scroll {
    height: 74vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 11vh);
  }
}

@media screen and (max-width: 2560px) and (max-height: 1200px) {
  .all-courses-scroll {
    height: 72vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 21vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 2560px) and (max-height: 1150) {
  .all-courses-scroll {
    height: 64vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 18vh) !important;
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 2560px) and (max-height: 1100) {
  .all-courses-scroll {
    height: 64vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 20vh) !important;
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 2560px) and (max-height: 1050) {
  .all-courses-scroll {
    height: 59vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 25vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 2560px) and (max-height: 1000) {
  .all-courses-scroll {
    height: 64vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 25vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 2560px) and (max-height: 950px) {
  .all-courses-scroll {
    height: 62vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 27vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 15vh);
  }
}
@media screen and (max-width: 2560px) and (max-height: 900px) {
  .all-courses-scroll {
    height: 64vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 25vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 2560px) and (max-height: 800) {
  .all-courses-scroll {
    height: 64vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 25vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}

@media screen and (max-width: 2560px) and (max-height: 700px) {
  .all-courses-scroll {
    height: 64vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 32vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 15vh);
  }
}
@media screen and (max-width: 2560px) and (max-height: 600px) {
  .all-courses-scroll {
    height: 47vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 28vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 18vh);
  }
}
@media screen and (max-width: 2560px) and (max-height: 650px) {
  .all-courses-scroll {
    height: 47vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 28vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 18vh);
  }
}

@media screen and (max-width: 1920px) and (max-height: 1300px) {
  .all-courses-scroll {
    height: 74vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 11vh);
  }
}
@media screen and (max-width: 1920px) and (max-height: 1250px) {
  .all-courses-scroll {
    height: 74vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 11vh);
  }
}

@media screen and (max-width: 1920px) and (max-height: 1200px) {
  .all-courses-scroll {
    height: 70vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1920px) and (max-height: 1100px) {
  .all-courses-scroll {
    height: 70vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1920px) and (max-height: 1000px) {
  .all-courses-scroll {
    height: 65vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 23vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1920px) and (max-height: 900px) {
  .all-courses-scroll {
    height: 64vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 25vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1920px) and (max-height: 850px) {
  .all-courses-scroll {
    height: 62vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 25vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1920px) and (max-height: 800px) {
  .all-courses-scroll {
    height: 60vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 27vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1920px) and (max-height: 750px) {
  .all-courses-scroll {
    height: 57vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 29vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1920px) and (max-height: 700px) {
  .all-courses-scroll {
    height: 54vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 34vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 15vh);
  }
}
@media screen and (max-width: 1920px) and (max-height: 650px) {
  .all-courses-scroll {
    height: 51vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 34vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 15vh);
  }
}
@media screen and (max-width: 1920px) and (max-height: 600px) {
  .all-courses-scroll {
    height: 47vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 36vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 18vh);
  }
}
/* -----------------------------------------------------------------1440 */
@media screen and (max-width: 1440px) and (max-height: 1200px) {
  .all-courses-scroll {
    height: 73vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1440px) and (max-height: 1100px) {
  .all-courses-scroll {
    height: 73vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1440px) and (max-height: 1000px) {
  .all-courses-scroll {
    height: 67vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 22vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1440px) and (max-height: 950x) {
  .all-courses-scroll {
    height: 73vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1440px) and (max-height: 900px) {
  .all-courses-scroll {
    height: 65vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 24vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1440px) and (max-height: 850px) {
  .all-courses-scroll {
    height: 62vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 26vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}

@media screen and (max-width: 1440px) and (max-height: 800px) {
  .all-courses-scroll {
    height: 60vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 27vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1440px) and (max-height: 750px) {
  .all-courses-scroll {
    height: 57vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 27vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 15vh);
  }
}
@media screen and (max-width: 1440px) and (max-height: 700px) {
  .all-courses-scroll {
    height: 54vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 27vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 15vh);
  }
}
@media screen and (max-width: 1440px) and (max-height: 650px) {
  .all-courses-scroll {
    height: 51vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 32vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 16vh);
  }
}
@media screen and (max-width: 1440px) and (max-height: 600px) {
  .all-courses-scroll {
    height: 47vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 34vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 17vh);
  }
}

/* ---------------------------------------------------1300--------- */
@media screen and (max-width: 1300px) and (max-height: 1200px) {
  .all-courses-scroll {
    height: 73vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1300px) and (max-height: 1100px) {
  .all-courses-scroll {
    height: 73vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1300px) and (max-height: 1000px) {
  .all-courses-scroll {
    height: 69vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1300px) and (max-height: 950x) {
  .all-courses-scroll {
    height: 71vh;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1300px) and (max-height: 900px) {
  .all-courses-scroll {
    height: 66vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 21vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1300px) and (max-height: 850px) {
  .all-courses-scroll {
    height: 64vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 24vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1300px) and (max-height: 800px) {
  .all-courses-scroll {
    height: 61vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 25vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1300px) and (max-height: 750px) {
  .all-courses-scroll {
    height: 59vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 27vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1300px) and (max-height: 700px) {
  .all-courses-scroll {
    height: 55vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 27vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1300px) and (max-height: 650px) {
  .all-courses-scroll {
    height: 52 vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 30vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1300px) and (max-height: 600px) {
  .all-courses-scroll {
    height: 47vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 34vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}

/* ---------------------------------------------------1280--------- */

@media screen and (max-width: 1200) and (max-height: 1200px) {
  .all-courses-scroll {
    height: 73vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1200) and (max-height: 1100px) {
  .all-courses-scroll {
    height: 73vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1200) and (max-height: 1000px) {
  .all-courses-scroll {
    height: 73vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 16vh);
  }
}
@media screen and (max-width: 1200) and (max-height: 950x) {
  .all-courses-scroll {
    height: 73vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 19vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1200) and (max-height: 900px) {
  .all-courses-scroll {
    height: 68vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 21vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1200) and (max-height: 850px) {
  .all-courses-scroll {
    height: 66vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 24vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1200) and (max-height: 800px) {
  .all-courses-scroll {
    height: 64vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 25vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1200) and (max-height: 750px) {
  .all-courses-scroll {
    height: 60vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 27vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1200) and (max-height: 700px) {
  .all-courses-scroll {
    height: 60vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 27vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1200) and (max-height: 650px) {
  .all-courses-scroll {
    height: 56vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 30vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1200) and (max-height: 600px) {
  .all-courses-scroll {
    height: 47vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 34vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}

/* -------------------------------------------------- */
@media screen and (max-width: 1024px) and (max-height: 1200px) {
  .all-courses-scroll {
    height: 65vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 23vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1024px) and (max-height: 1100px) {
  .all-courses-scroll {
    height: 65vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 23vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1024px) and (max-height: 1000px) {
  .all-courses-scroll {
    height: 65vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 23vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}
@media screen and (max-width: 1024px) and (max-height: 950px) {
  .all-courses-scroll {
    height: 65vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 23vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}

@media screen and (max-width: 1024px) and (max-height: 900px) {
  .all-courses-scroll {
    height: 65vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 23vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}

@media screen and (max-width: 1024px) and (max-height: 850px) {
  .all-courses-scroll {
    height: 64vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 25vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 17vh);
  }
}

@media screen and (max-width: 1024px) and (max-height: 800px) {
  .all-courses-scroll {
    height: 65vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 23vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}

@media screen and (max-width: 1024px) and (max-height: 700px) {
  .all-courses-scroll {
    height: 58vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 29vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 16vh);
  }
}
@media screen and (max-width: 1024px) and (max-height: 600px) {
  .all-courses-scroll {
    height: 50vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 35vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}

@media screen and (max-width: 768px) and (max-height: 900px) {
  .all-courses-scroll {
    height: 64vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 22vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}

@media screen and (max-width: 768px) and (max-height: 850px) {
  .all-courses-scroll {
    height: 64vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 22vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}

@media screen and (max-width: 768px) and (max-height: 700px) {
  .all-courses-scroll {
    height: 57vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 27vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 13vh);
  }
}

@media screen and (max-width: 768px) and (max-height: 600px) {
  .all-courses-scroll {
    height: 49vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 30vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 15vh);
  }
}

@media screen and (max-width: 768px) and (max-height: 500px) {
  .all-courses-scroll {
    height: 40vh !important;
  }
  .answer-doubts-popup {
    height: calc(100vh - 35vh);
  }
  .solved-add-doubt-answer {
    height: calc(100vh - 17vh);
  }
}

.all-courses-scroll::-webkit-scrollbar {
  width: 7px;
}
.all-courses-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ebeff2;
  border-radius: 7px;
}
.all-courses-scroll::-webkit-scrollbar-thumb {
  background: #d3d8dd;
  border-radius: 7px;
}
.all-courses-scroll::-webkit-scrollbar-thumb:hover {
  background: #d3d8dd;
}
