#question-status-card {
  height: calc(100vh - 130px);
}

#mock-attempted-fixed-content {
  height: calc(100vh - 29vh);
  overflow: hidden;
  overflow-y: scroll;
}

#mock-attempted-inner-layout::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#mock-attempted-inner-layout::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
#mock-attempted-inner-layout::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

/* Handle on hover */
#mock-attempted-inner-layout::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

#mock-fixed-content::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#mock-fixed-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
#mock-fixed-content::-webkit-scrollbar-thumb {
  background: #e4e5e7;
  border-radius: 10px;
}

/* Handle on hover */
#mock-fixed-content::-webkit-scrollbar-thumb:hover {
  background: rgba(90, 114, 200, 0.04);
}

#mock-attempted-fixed-content::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#mock-attempted-fixed-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
#mock-attempted-fixed-content::-webkit-scrollbar-thumb {
  background: #e4e5e7;
  border-radius: 10px;
}

/* Handle on hover */
#mock-attempted-fixed-content::-webkit-scrollbar-thumb:hover {
  background: rgba(90, 114, 200, 0.04);
}

#mock-notvisited-fixed-content::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#mock-notvisited-fixed-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
#mock-notvisited-fixed-content::-webkit-scrollbar-thumb {
  background: #e4e5e7;
  border-radius: 10px;
}

/* Handle on hover */
#mock-notvisited-fixed-content::-webkit-scrollbar-thumb:hover {
  background: rgba(90, 114, 200, 0.04);
}

#mock-startresume-fixed-content::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#mock-startresume-fixed-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
#mock-startresume-fixed-content::-webkit-scrollbar-thumb {
  background: #e4e5e7;
  border-radius: 10px;
}

/* Handle on hover */
#mock-startresume-fixed-content::-webkit-scrollbar-thumb:hover {
  background: rgba(90, 114, 200, 0.04);
}

#mock-attempted-fixed-status::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#mock-attempted-fixed-status::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
#mock-attempted-fixed-status::-webkit-scrollbar-thumb {
  background: #e4e5e7;
  border-radius: 10px;
}

/* Handle on hover */
#mock-attempted-fixed-status::-webkit-scrollbar-thumb:hover {
  background: rgba(90, 114, 200, 0.04);
}

#mock-inner-layout::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#mock-inner-layout::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
#mock-inner-layout::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

/* Handle on hover */
#mock-inner-layout::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.mocktest-type-row {
  padding: 15px 3px 15px 0px;
  transition: all 0.3s ease;
}

.question-tab-column .ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0;
  font-size: 14px;
  color: #3c4852;
  background: rgba(90, 114, 200, 0.2);;
  border: 0;
  outline: none;
  cursor: pointer;
}

.section-timer-flex {
  background: rgb(249, 250, 253);
  font-weight: 600;
  display: flex;
}

.question-tab-column .ant-tabs-nav-list > .ant-tabs-tab {
  border: 1px solid rgba(90, 114, 200, 0.2);
}

.question-tab-column .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}

.question-tab-column .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}

.question-tab-column .ant-tabs-tab.ant-tabs-tab-active.ant-tabs-tab-disabled {
  cursor: pointer;
}

.question-tab-column .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: none;
  content: "";
}

.question-tab-column .ant-tabs-tab.ant-tabs-tab-disabled {
  color: #b4b6b9 !important;
  cursor: not-allowed;
}

.question-tab-column .ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 10px 0;
}

.mock-card-user-block {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.language-drop {
  min-width: 120px !important;
}

.language-drop .ant-select.ant-select-single.ant-select-show-arrow {
  width: 100px !important;
}

.language-drop .ant-dropdown-menu {
  padding: 5px;
  width: 100px;
}

.language-drop .ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.language-drop
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #000;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.language-drop
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  position: relative;
  background-color: #000;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='#fff'/></svg>");
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  box-shadow: 0;
}

.language-drop .ant-select-single.ant-select-open .ant-select-selection-item {
  font-weight: 600;
  color: #fff;
}

.language-drop .ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-top: 10px solid #fff;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  width: 18px;
  height: 12px;
  margin-top: -6px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}

.language-drop .ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder::after {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #2f2f2f;
  font-size: 0;
  line-height: 0;
  float: left;
}

.question-submit-btn:active {
  color: #0b649d;
  border-color: #e0f1fb;
  background: #fff;
  font-weight: 800;
}

.question-submit .ant-btn:hover .ant-btn:focus {
  color: #0b649d;
  border-color: #e0f1fb;
  background: #fff;
  font-weight: 800;
}

.question-submit-btn:hover .question-submit-btn:focus {
  color: #0b649d;
  border-color: #e0f1fb;
  background: #fff;
  font-weight: 800;
}

button.ant-btn.ant-btn-default.ant-btn-lg.ant-btn-block.question-submit-btn {
  color: #40a9ff;
  border-color: rgb(133, 193, 73);
  background: #fff;
  font-weight: 800;
}

.question-analysis-btn:hover .question-analysis-btn:focus {
  color: #0b649d;
  border-color: #e0f1fb;
  background: #fff;
  font-weight: 800;
}

.mark-question-btn {
  float: left;
  color: #553475;
  font-size: 16px;
  font-weight: 600;
  background: #d8cae6;
  border-radius: 2px;
  cursor: pointer;
  padding: 8px 20px;
  margin: 0px 20px 0px 1px;
}

.clear-response-btn {
  float: left;
  color: rgb(60, 72, 82);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding: 8px 20px;
  background: rgb(240, 241, 244);
  border-radius: 2px;
}

.save-next-btn {
  float: right;
  background: rgb(11, 100, 157);
  color: rgb(255, 255, 255);
  font-size: 17px;
  font-weight: 600;
  padding: 6px 30px;
  cursor: pointer;
  border-radius: 4px;
}

.save-next-solu-btn {
  float: right;
  background: #f0f1f4;
  color: #3c4852;
  font-size: 17px;
  font-weight: 600;
  padding: 6px 30px;
  cursor: pointer;
  border-radius: 4px;
}

.back-solu-btn {
  float: left;
  background: #f0f1f4;
  font-size: 17px;
  font-weight: 600;
  padding: 4px 0px;
  cursor: pointer;
  border-radius: 4px;
}

.back-solu-btn button.ant-btn.ant-btn-link {
  color: #3c4852; 
}

.solu-back-btn-style {
  color: #3c4852;
  font-weight: 600;
}

.mock-text {
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.mockaside-icon {
  padding: 16px 5px 17px 6px;
  background: #3c4852;
  position: absolute;
  left: -16px;
  border-radius: 7px 0px 0px 7px;
  top: 45%;
  cursor: pointer;
}

.mocktest-question-choices-container .ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-color: #fff;
  border-color: #3c4852;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}

.mocktest-question-choices-container .ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #0b649d;
}

.mocktest-Checkbox-Item {
  display: flex;
  justify-content: flex-end;
}

.mocktest-Checkbox-Item .ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: rgba(90, 114, 200, 0.1);
  border: 1px solid #3c4852;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}

.mocktest-Checkbox-Item .ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgba(0, 0, 0, 0.08);
  border-color: #3c4852;
}

.mocktest-Checkbox-Item .ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #3c4852;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
}

.mocktest-Checkbox-Item .ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
  direction: ltr;
  background-color: rgba(90, 114, 200, 0.1);
  border: 1px solid #3c4852;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}

.solution-choices .wrong .ant-radio-inner::after {
  background-color: #ff4d4f !important;
}

.solution-choices .wrong .ant-radio-checked .ant-radio-inner {
  border-color: #ff4d4f !important;
  background-color: #fff !important;
}

.solution-choices .right .ant-radio .ant-radio-inner {
  border-color: #01a54e !important;
  background-color: #fff !important;
}

.solution-choices .right .ant-radio-inner::after {
  background-color: #01a54e !important;
}

.solution-choices .right .ant-radio-checked .ant-radio-inner {
  border-color: #01a54e !important;
}

.reattempt-choices .wrong .ant-radio-inner::after {
  background-color: #ff4d4f !important;
}

.reattempt-choices .wrong .ant-radio-checked .ant-radio-inner {
  border-color: #ff4d4f !important;
  background-color: #fff !important;
}

.reattempt-choices .right .ant-radio .ant-radio-inner {
  border-color: #01a54e !important;
}

.reattempt-choices .right .ant-radio-inner::after {
  background-color: #01a54e !important;
}

.reattempt-choices .right .ant-radio-checked .ant-radio-inner {
  border-color: #01a54e !important;
  background-color: #fff !important;
}

/* New tab design */
.course-modules-mocktest-group-buttons-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.course-modules-mocktest-group-buttons
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  background: #0b649d;
  color: #fff !important;
  border-color: #1890ff;
}

.mocktest-toggle-buttons {
  margin: 0px;
  border-radius: 0px;
  font-weight: 500;
}

.course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
  display: inline-block;
  height: 45px;
}

.course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
  position: relative;
  padding: 0 15px;
  border: 1px solid #d9d9d9;
  display: inline-flex;
  align-items: center;
  color: #3c4852;
  /* background: rgba(90, 114, 200, 0.2);; */
  outline: none;
  cursor: pointer;
}

.course-modules-mocktest-group-buttons
  .ant-radio-button-wrapper.ant-radio-button-wrapper-disabled.mocktest-toggle-buttons {
  cursor: no-drop;
  color: #b4b6b9;
}

.course-modules-mocktest-group-buttons
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled.mocktest-toggle-buttons {
  cursor: no-drop;
  color: #fff;
  background-color: #0b649d;
}

.question.question-full-image .question-name div pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.question-full-image div pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.mock-questions-count-column-diable {
  display: none;
}

.dropdownAnt-link {
  background: rgb(60, 72, 82);
  color: rgb(255, 255, 255);
  padding: 6px 15px;
  border-radius: 5px;
  margin: 0px 10px;
  cursor: pointer;
}

.dropdownAnt-link:active,
.dropdownAnt-link:hover {
  color: rgb(255, 255, 255) !important;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background: none;
}

.solution-subtext-div {
  padding-bottom: 5px;
}

.solution-subtext {
  font-size: 14px;
  color: #151515;
  font-weight: 600;
}

.solution-subtext-content {
  font-size: 13px;
  color: #151515;
  font-weight: 590;
}

.mock-content-background-grid {
  background-color: transparent;
  display: flex;
  border-radius: 8px;
}

@media (min-width: 320px) {
  .mockaside-icon-left {
    padding: 16px 5px 17px 6px;
    background: #3c4852;
    position: absolute;
    right: -4px;
    border-radius: 0px 7px 7px 0px;
    top: 45%;
    cursor: pointer;
  }

  .inner-Content {
    display: flex;
    align-items: center;
    min-width: 160px;
    text-align: center;
    padding: 0px 10px !important;
    justify-content: space-between;
  }

  .mocktest-quest-footer {
    position: fixed;
    /* width: 5%;  */
    bottom: 0px;
  }

  .side-BarText {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    grid-auto-rows: minmax(50px, auto);
    justify-content: center;
    padding: 0px 30px;
  }

  .mock-content-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
    justify-content: center;
    padding: 0px;
  }

  .mock-content-background-grid {
    padding: 1px 5px;
  }

  .questionTab {
    padding-left: 40px;
  }

  .inner-ContentEnd {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mocktest-question-container {
    background: transparent;
    margin: auto;
    min-height: 85vh;
    height: 100%;
    width: 100%;
    padding: 10px;
    overflow: hidden;
  }
  .mocktest-footer-position-full {
    position: fixed;
    width: 100%;
    bottom: 0px;
    z-index: 1;
    left: 3px;
  }

  .mocktest-footer-container {
    box-shadow: 0px 2px 8px rgb(0 0 0 / 8%);
    border-top: 0px solid rgba(90, 114, 200, 0.2);;
  }

  .mocktest-question-container .breadcrumb-container {
    margin: 0px;
  }

  .mocktest-question-container .breadcrumb-container .breadcrumb {
    margin: 0px;
    padding: 0px;
  }

  .mocktest-question-container .breadcrumb-container .breadcrumb .item {
    cursor: pointer;
  }

  .mocktest-question-container .breadcrumb-container .breadcrumb .item .link {
    color: #0b649d;
    font-size: 12px;
  }

  .mocktest-question-container .breadcrumb-container .breadcrumb .active-item {
    color: #0b649d;
    font-weight: 400;
    font-size: 12px;
  }

  .mocktest-question-container .main-layout {
    margin: 0px;
    margin-top: 0px;
  }

  .mocktest-question-container .main-layout .row {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card {
    padding: 0px 10px 0px 0px;
    border-top: 1px solid rgba(90, 114, 200, 0.2);
    border-radius: 3px;
    background: #ffffff;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-header {
    background: #0b649d;
    padding: 10px;
    border-top: 1px solid rgba(90, 114, 200, 0.2);
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-header
    .title-column {
    color: #fff;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-header
    .title-column
    .title {
    font-size: 14px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-header
    .icon-column {
    text-align: end;
    padding: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-header
    .icon-column
    .back-icon {
    color: #fff;
    font-size: 22px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header {
    padding: 15px 40px;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .left-column {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .left-column
    .icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .center-column {
    text-align: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .center-column
    .timer {
    background: #fbd7e1;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .center-column
    .timer
    .text {
    padding: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .center-column
    .timer-spin {
    text-align: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .right-column {
    margin: 0px;
    text-align: end;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .right-column
    .pause-icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  .mock-questions-count-column .card .mock-card-user-block .pause-icon {
    cursor: pointer;
    width: 35px;
    height: 35px;
  }

  .mocktest-question-container .main-layout .row .mock-questions-count-column {
    margin: 0px;
    position: absolute;
    width: 54%;
    /* top: 85px; */
    right: 0;
    z-index: 1;
    display: block;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout {
    margin-top: 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row {
    margin: 0px;
    padding: 10px 18px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .mock-question-column {
    padding: 0px 5px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .mock-question-column
    .text {
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat {
    text-align: end;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat
    .content {
    padding: 0px 0px;
    display: contents;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat
    .content
    .box-1 {
    background: #8ad6ad;
    border-radius: 4px;
    color: #000;
    font-size: 10px;
    padding: 5px;
    font-weight: bold;
    margin: 5px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat
    .content
    .box-2 {
    background: #fbd7e1;
    border-radius: 4px;
    color: #000;
    font-size: 10px;
    padding: 5px;
    font-weight: bold;
    margin: 5px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat
    .content
    .bookmark-span {
    cursor: pointer;
    margin: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat
    .content
    .bookmarked-span {
    cursor: pointer;
    margin: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat
    .content
    .share-span {
    cursor: pointer;
    margin: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat
    .content
    .share-span
    .share-icon {
    margin: 0px;
    width: 20px;
    height: 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .question {
    background: rgba(90, 114, 200, 0.04);
    padding: 20px;
    margin: 8px 24px;
    font-size: 15px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .question
    .question-name
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question {
    padding: 10px;
    margin-top: 20px;
    margin: 10px 10px 30px 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question
    .question {
    margin: 0px;
    padding: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question
    .question
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question
    .show-content {
    text-align: center;
    padding: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question
    .show-content
    .show-more,
  .show-less {
    cursor: pointer;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question
    .show-content {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question
    .show-content
    .show-more {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question
    .show-content
    .show-less {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .solution-choices {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .solution-choices
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .solution-choices
    .right {
    padding: 0px;
    background: #ccffe4;
    margin: 20px 29px;
    border: 0px solid #01a54e;
    color: #01a54e;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .solution-choices
    .not-choosen {
    padding: 0px;
    background: #fff;
    margin: 20px 29px;
    border: 0px solid #d3d8dd;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .solution-choices
    .wrong {
    padding: 0px;
    margin: 20px 29px;
    border: 0px solid #f7685b;
    color: #f7685b;
    font-weight: 600;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .reattempt-choices {
    margin: 10px 20px 10px 25px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .reattempt-choices
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .reattempt-choices
    .right {
    padding: 0px;
    background: #ccffe4;
    margin: 20px 5px;
    border: 0px solid #01a54e;
    cursor: pointer;
    color: #01a54e;
    font-weight: 600;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .reattempt-choices
    .not-choosen {
    padding: 0px;
    background: #fff;
    margin: 20px 5px;
    border: 0px solid #d3d8dd;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .reattempt-choices
    .wrong {
    padding: 0px;
    background: #ffe9e9;
    margin: 20px 5px;
    border: 0px solid #f7685b;
    cursor: pointer;
    color: #f7685b;
    font-weight: 600;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .start-choices {
    margin: 10px 10px 150px 45px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .start-choices
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .start-choices
    .not-choosen {
    padding: 0px;
    background: #fff;
    margin: 25px 5px;
    border: 0px solid #d3d8dd;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .start-choices
    .choosen {
    padding: 0px;
    background: transparent;
    margin: 5px;
    border: 0px solid #0b649d;
    cursor: pointer;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .resume-choices {
    margin: 10px 10px 10px 45px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .resume-choices
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .resume-choices
    .choosen {
    padding: 0px;
    background: transparent;
    margin: 25px 5px;
    border: 0px solid #0b649d;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .resume-choices
    .not-choosen {
    padding: 0px;
    margin: 25px 5px;
    border: 0px solid #d3d8dd;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout {
    margin-top: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background: #ffffff;
    padding: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .row {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .row
    .title-column {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .row
    .title-column
    .text {
    color: #151515;
    font-weight: 500;
    font-size: 14px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .row
    .report-column {
    margin: 0px;
    text-align: end;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .row
    .report-column
    .report {
    background: #e0f3ff;
    border-radius: 90px;
    padding: 5px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .row
    .report-column
    .report
    .report-image {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .divider {
    margin: 0px;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .row {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .row
    .label {
    color: #151515;
    font-size: 14px;
    font-weight: 600;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .row
    .label
    .option-value {
    color: #151515;
    font-size: 12px;
    font-weight: 400;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .content {
    padding-top: 15px;
    margin: 0px 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .content
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout {
    border: 1px solid #d1d1d1;
    box-sizing: border-box;
    margin-top: 40px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row {
    align-items: center;
    padding: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .difficult-column {
    text-align: start;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .difficult-column
    .image-span {
    padding: 0px 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .difficult-column
    .image-span
    .image {
    margin: 0px;
    width: 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .difficult-column
    .text-span {
    margin: 0px;
    color: #151515;
    font-size: 12px;
    font-weight: normal;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .difficult-column
    .text-span
    .easy-text {
    margin: 0px;
    color: #151515;
    font-size: 12px;
    font-weight: 400;
    color: #01a54e;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .difficult-column
    .text-span
    .hard-text {
    margin: 0px;
    color: #151515;
    font-size: 12px;
    font-weight: 400;
    color: #ee2d3b;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .difficult-column
    .text-span
    .medium-text {
    margin: 0px;
    color: #151515;
    font-size: 12px;
    font-weight: 400;
    color: #f9873c;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .attempt-column {
    text-align: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .attempt-column
    .image-span {
    padding: 0px 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .attempt-column
    .image-span
    .image {
    margin: 0px;
    width: 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .attempt-column
    .text-span {
    margin: 0px;
    color: #151515;
    font-size: 12px;
    font-weight: normal;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .attempt-column
    .text-span
    .text {
    margin: 0px;
    color: #151515;
    font-size: 12px;
    font-weight: 600;
    color: #01a54e;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .divider {
    margin: 0px;
    padding: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .topic {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .topic
    .content {
    text-align: center;
    padding: 10px 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .topic
    .content
    .image-span {
    margin: 0px;
    padding: 0px 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .topic
    .content
    .image-span
    .image {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .topic
    .content
    .text-span {
    color: #151515;
    font-size: 12px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .topic
    .content
    .text-span
    .text {
    font-weight: 500;
    font-size: 14px;
  }

  .mocktest-question-container .main-layout .row .mock-questions-count-column {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card {
    background: #fff;
    padding: 0px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .header {
    border: 1px solid #d1d1d1;
    box-sizing: border-box;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .header
    .title {
    font-size: 18px;
    background: rgb(11, 100, 157);
    padding: 16px 26px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-transform: capitalize;
  }

  .mock-card-user-block {
    padding: 5% 4%;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .header
    .text {
    color: #151515;
    font-size: 14px;
    font-weight: 600;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content {
    padding: 10px;
    margin: 5px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .row {
    justify-content: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .row
    .column {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .answered {
    color: #fff;
    font-weight: bold;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .un-answered {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .mark-question {
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: linear-gradient(180deg, #8e6cad 0%, #2c0a4d 160.31%);
    color: #fff;
    font-size: 18px;
    border-radius: 90px;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .row
    .column
    .correct {
    background: #ccffe4;
    border: 1px solid #01a54e;
    border-radius: 90px;
    color: #01a54e;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .mocktest-type-row
    .content
    .not-answered {
    background: linear-gradient(180deg, #f8f8f6 48.3%, #d3d3d3 100%);
    color: #000;
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .mocktest-type-row
    .content
    .answered {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    color: #fff;
    font-weight: 900;
    font-size: 18px;
    text-align: "center";
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .mocktest-type-row
    .content
    .un-answered {
    background: linear-gradient(180deg, #ad2806 0%, #fc5a10 96.15%);
    border: 2px solid #cf6e52;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 900;
    font-size: 18px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .mocktest-type-row
    .content
    .mark-question {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 900;
    font-size: 18px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .not-answered {
    color: #000;
    font-size: 18px;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .wrong-answer {
    background: #fbd7e1;
    border: 1px solid #ee2d3b;
    border-radius: 90px;
    color: red;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .row
    .column
    .not-answered
    .status-icon,
  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .row
    .column
    .answered
    .status-icon,
  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .un-answered
    .status-icon {
    font-size: 30px;
    position: absolute;
    bottom: 25px;
    color: #109cf1;
    right: -23px;
  }

  .answered .reattempt-status-icon {
    font-size: 30px;
    position: absolute;
    bottom: 25px;
    color: #01a54e;
    right: 5px;
  }

  .wrong-answer .reattempt-status-icon {
    font-size: 30px;
    position: absolute;
    bottom: 25px;
    color: #ee2d3b;
    right: 5px;
  }

  .reattempt-status-icon {
    font-size: 30px;
    position: absolute;
    bottom: 25px;
    color: #109cf1;
    right: 5px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer {
    padding: 0px;
    margin: 0px 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type1-row {
    margin: 0px;
    justify-content: center;
    padding: 10px;
    white-space: nowrap;
    flex-direction: column;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type1-row
    .column {
    margin: 0px;
    padding: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type1-row
    .column
    .content {
    padding: 0px;
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type1-row
    .column
    .content
    .image {
    width: 10px;
    height: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type1-row
    .column
    .content
    .text {
    font-size: 12px;
    padding: 0px 5px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type2-row {
    margin: 0px;
    justify-content: center;
    padding: 20px;
    white-space: nowrap;
    flex-direction: column;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type2-row
    .column {
    margin: 0px;
    padding: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type2-row
    .column
    .content {
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type2-row
    .column
    .content
    .image {
    width: 10px;
    height: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type2-row
    .column
    .content
    .text {
    font-size: 14px;
    padding: 0px 5px;
    display: block;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-submit,
  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-next,
    .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-submited  {
    background: #fff;
    padding: 21px 85px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .question-submit
    .button {
    height: 45px;
    font-size: 14px;
  }

  .mocktest-popup-modal-body-content-instruction {
    padding: 0px 0px;
    font-size: 18px;
  }

  .mocktest-popup-modal-body-content-instruction strong {
    display: flex;
    justify-content: center;
  }

  .mocktest-questions-main2-card2-choice-not-choosen {
    background: transparent;
    color: #3c4852;
  }

  .mocktest-questions-main2-card2-choice-choosen {
    background: #109cf1;
    padding: 14px 21px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .mocktest-questions-main2-card2-choice-isright {
    background: #c9fce1;
    padding: 14px 0px;
    color: #01a54e;
    font-weight: bold;
    font-size: 14px;
  }

  .mocktest-questions-main2-card2-choice-iswrong {
    background: #ffe9e9;
    padding: 14px 0px;
    color: #f00;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    align-items: center;
  }

  .mocktest-question-choices-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mocktest-question-choices-value {
    padding: 0px 20px;
  }

  .mocktest-question-choices-value-not-choosen {
    display: flex;
    align-items: center;
    padding: 0px 10px;
  }

  .mocktest-question-choices-value-choosen p,
  .mocktest-reattempt-question-choices-value-choosen p,
  .mocktest-question-choices-value-not-choosen p,
  .mocktest-reattempt-question-choices-value-not-choosen p,
  .mocktest-question-choices-value p {
    margin: 0px;
    padding: 0px;
  }

  .question-bar {
    background: rgb(60, 72, 82);
    padding: 4px 6px;
    margin: 1px 10px;
    border-radius: 3px;
    font-size: 11px;
    color: #fff;
  }

  .section-timer-flex {
    padding: 10px;
    font-size: 15px;
    flex-direction: row;
    align-items: center;
  }

  .section-timer {
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    display: none;
  }

  .course-modules-mocktest-group-buttons {
    margin: 10px;
  }

  /* scroll */
  #mock-fixed-content {
    height: calc(100vh - 540px);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-question-status-card {
    box-shadow: none;
  }

  #mock-attempted-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 25vh);
    overflow-x: hidden;
  }

  #mock-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 33vh);
    overflow-x: hidden;
  }

  #mock-startresume-fixed-content {
    height: calc(100vh - 34vh);
    overflow: auto;
    overflow-y: auto;
  }

  #mock-notvisited-fixed-content {
    height: calc(100vh - 34vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-attempted-fixed-status {
    height: calc(100vh - 59vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  .un-mark-question-btn {
    float: left;
    color: #3c4852;
    font-size: 16px;
    font-weight: 600;
    background: #f0f1f4;
    border-radius: 2px;
    cursor: pointer;
    padding: 8px 18px;
    margin: 0px 20px 0px 1px;
  }

  /* Antd tabs */
  .question-tab-column {
    padding: 8px 4px 8px 8px;
    background: #fff;
    border: 1px solid #d3d8dd;
  }

  .question-tab-column
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    background: #0b649d;
    color: #fff !important;
    font-size: 16px;
    font-weight: 600;
  }

  .question-tab-column .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    text-shadow: 0 0 0.25px currentColor;
    padding: 0px 6px 0px 7px;
    font-size: 18px;
  }

  .question-section-tabpane-name {
    margin: 0px 25px 0px 0px;
    text-transform: capitalize;
  }

  .question-section-tabpane {
    padding: 4px 8px;
  }

  .mocktest-question-choices-value-choosen {
    padding: 0px 10px;
    font-size: 15px;
  }
  .course-modules-mocktest-group-buttons
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 15px;
    font-weight: 600;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    padding: 0 8px;
    font-size: 15px;
    line-height: 20px;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    height: 37px;
  }
}

@media (min-width: 375px) {
  .mocktest-quest-footer {
    position: fixed;
    /* width: 5%;  */
    bottom: 0px;
  }

  .side-BarText {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6px;
    grid-auto-rows: minmax(50px, auto);
    justify-content: center;
    padding: 0px 30px;
  }

  .mock-content-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
    justify-content: center;
    padding: 0px;
  }

  .mock-content-background-grid {
    padding: 1px 5px;
  }

  .mocktest-question-choices-value-choosen {
    padding: 0px 10px;
    font-size: 15px;
  }
  .course-modules-mocktest-group-buttons
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 15px;
    font-weight: 600;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    padding: 0 8px;
    font-size: 15px;
    line-height: 20px;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    height: 37px;
  }
}

@media (min-width: 425px) {
  .mocktest-quest-footer {
    position: fixed;
    /* width: 35%; */
    bottom: 0px;
  }

  .side-BarText {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    grid-auto-rows: minmax(50px, auto);
    justify-content: center;
    padding: 0px 30px;
  }

  .mock-content-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
    justify-content: center;
    padding: 0px;
  }

  .mock-content-background-grid {
    padding: 1px 5px;
  }

  .mocktest-question-choices-value-choosen {
    padding: 0px 10px;
    font-size: 15px;
  }
}

@media (min-width: 481px) {
  .mockaside-icon-left {
    padding: 16px 5px 17px 6px;
    background: #3c4852;
    position: absolute;
    right: -4px;
    border-radius: 11px 0px 0px 11px;
    top: 45%;
    cursor: pointer;
  }

  .side-BarText {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    grid-auto-rows: minmax(50px, auto);
    justify-content: center;
    padding: 0px 30px;
  }

  .questionTab {
    padding-left: 40px;
  }

  .inner-Content {
    display: flex;
    align-items: center;
    min-width: 160px;
    text-align: center;
    padding: 1px;
    justify-content: space-between;
  }

  .inner-ContentEnd {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mocktest-question-container {
    background: transparent;
    margin: auto;
    min-height: 85vh;
    height: 100%;
    width: 100%;
    padding: 10px;
    overflow: hidden;
  }

  .mocktest-footer-position-full {
    position: fixed;
    width: 100%;
    bottom: 0px;
    z-index: 1;
    left: 3px;
  }

  .mocktest-footer-container {
    padding: 10px;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 8%);
    /* border-top: 1px solid lightgrey; */
  }

  .question-bar {
    background: rgb(60, 72, 82);
    padding: 7px 26px;
    margin: 0px 10px;
    border-radius: 3px;
  }

  .section-timer-flex {
    padding: 13px;
    font-size: 15px;
    flex-direction: row;
    align-items: center;
  }

  .section-timer {
    margin: 0px;
    font-size: 18px;
    font-weight: 400;
    display: none;
  }

  .course-modules-mocktest-group-buttons {
    margin: 16px 20px 20px 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .header
    .title {
    font-size: 18px;
    background: rgb(11, 100, 157);
    padding: 16px 26px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-transform: capitalize;
  }

  .mock-card-user-block {
    padding: 5% 4%;
  }

  .mocktest-quest-footer {
    position: fixed;
    width: 25%;
    bottom: 0px;
  }

  /* scroll */
  #mock-fixed-content {
    height: calc(100vh - 533px);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-question-status-card {
    box-shadow: none;
  }

  #mock-attempted-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 30vh);
    overflow-x: hidden;
  }

  #mock-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 256px);
    overflow-x: hidden;
  }

  #mock-startresume-fixed-content {
    height: calc(100vh - 31vh);
    overflow: hidden;
    overflow-y: auto;
  }

  .un-mark-question-btn {
    float: left;
    color: #3c4852;
    font-size: 16px;
    font-weight: 600;
    background: #f0f1f4;
    border-radius: 2px;
    cursor: pointer;
    padding: 8px 18px;
    margin: 0px 20px 0px 1px;
  }

  #mock-notvisited-fixed-content {
    height: calc(100vh - 34vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-attempted-fixed-status {
    height: calc(100vh - 51vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  /* Antd tabs */
  .question-tab-column {
    padding: 8px 4px 8px 8px;
    background: #fff;
    border: 1px solid #d3d8dd;
  }

  .question-tab-column
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    background: #0b649d;
    color: #fff !important;
    font-size: 16px;
    font-weight: 600;
  }

  .question-tab-column .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    text-shadow: 0 0 0.25px currentColor;
    padding: 0px 6px 0px 22px;
    font-size: 18px;
  }

  .question-section-tabpane-name {
    margin: 0px 30px 0px 0px;
  }

  .question-section-tabpane {
    padding: 8px;
  }

  .mocktest-question-choices-value-choosen {
    padding: 0px 10px;
    font-size: 15px;
  }
}

@media (min-width: 641px) {
  .mockaside-icon-left {
    padding: 16px 5px 17px 6px;
    background: #3c4852;
    position: absolute;
    right: -4px;
    border-radius: 11px 0px 0px 11px;
    top: 45%;
    cursor: pointer;
  }

  .colan {
    display: none;
  }

  .side-BarText {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    grid-auto-rows: minmax(50px, auto);
    justify-content: center;
    padding: 0px 30px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row {
    padding: 10px 34px;
  }

  .questionTab {
    padding-left: 40px;
  }

  #mock-attempted-fixed-status {
    height: calc(100vh - 59vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  .inner-Content {
    display: flex;
    align-items: center;
    min-width: 160px;
    text-align: center;
    padding: 1px;
    justify-content: space-between;
  }

  .inner-ContentEnd {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 12.5px !important;
    padding-right: 0.5px !important;
  }

  .mocktest-question-container {
    background: transparent;
    margin: auto;
    min-height: 85vh;
    height: 100%;
    width: 100%;
    padding: 10px;
    overflow: hidden;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-submit {
    padding: 24px 80px;
    background: #fff;
    text-align: center;
  }  
  .mocktest-question-container
  .main-layout
  .row
  .mock-questions-count-column
  .card
  .question-submited {
  padding: 24px 80px;
  background: #fff;
  text-align: center;
}

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .header
    .title {
    font-size: 18px;
    background: rgb(11, 100, 157);
    padding: 16px 26px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-transform: capitalize;
  }

  .mock-card-user-block {
    padding: 2.3% 4%;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .question-submit
    .button {
    height: 45px;
    font-size: 14px;
    width: 50%;
    background: #0b649d;
    color: #fff;
    border-radius: 6px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .answered {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .un-answered {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .mark-question {
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: linear-gradient(180deg, #8e6cad 0%, #2c0a4d 160.31%);
    color: #fff;
    font-size: 18px;
    border-radius: 90px;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .row
    .column
    .correct {
    background: #ccffe4;
    border: 1px solid #01a54e;
    border-radius: 90px;
    color: #01a54e;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .mocktest-type-row
    .content
    .not-answered {
    background: linear-gradient(180deg, #f8f8f6 48.3%, #d3d3d3 100%);
    color: #000;
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .mocktest-type-row
    .content
    .answered {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    color: #fff;
    font-weight: 900;
    font-size: 18px;
    text-align: "center";
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .mocktest-type-row
    .content
    .un-answered {
    background: linear-gradient(180deg, #ad2806 0%, #fc5a10 96.15%);
    border: 2px solid #cf6e52;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 900;
    font-size: 18px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .mocktest-type-row
    .content
    .mark-question {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 900;
    font-size: 18px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .not-answered {
    color: #000;
    font-size: 18px;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .wrong-answer {
    background: #fbd7e1;
    border: 1px solid #ee2d3b;
    border-radius: 90px;
    color: red;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-choices-value-not-choosen {
    display: flex;
    align-items: center;
    padding: 0px 10px;
  }

  .mocktest-question-choices-value-choosen p,
  .mocktest-reattempt-question-choices-value-choosen p,
  .mocktest-question-choices-value-not-choosen p,
  .mocktest-reattempt-question-choices-value-not-choosen p,
  .mocktest-question-choices-value p {
    margin: 0px;
    padding: 0px;
  }

  .un-mark-question-btn {
    float: left;
    color: #3c4852;
    font-size: 16px;
    font-weight: 600;
    background: #f0f1f4;
    border-radius: 2px;
    cursor: pointer;
    padding: 8px 18px;
    margin: 0px 20px 0px 1px;
  }

  .mocktest-quest-footer {
    position: fixed;
    width: 54%;
    bottom: 0px;
  }

  /* Antd tabs */
  .question-tab-column {
    padding: 8px 4px 8px 8px;
    background: #fff;
    border: 1px solid #d3d8dd;
  }

  .question-tab-column
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    background: #0b649d;
    color: #fff !important;
    font-size: 16px;
    font-weight: 600;
  }

  .question-tab-column .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    text-shadow: 0 0 0.25px currentColor;
    padding: 0px 5px 0px 19px;
    font-size: 18px;
  }

  .question-section-tabpane-name {
    margin: 0px 32px 0px 0px;
  }

  .question-section-tabpane {
    padding: 8px;
  }

  .question-bar {
    background: rgb(60, 72, 82);
    padding: 3px 5px;
    margin: 0px 3px 0px 0px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }

  .section-timer-flex {
    padding: 11px;
    font-size: 15px;
    flex-direction: column;
  }

  .section-timer {
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
    display: block;
    min-width: max-content;
  }

  .course-modules-mocktest-group-buttons {
    margin: 16px 20px 20px 20px;
  }

  /* scroll */
  #mock-fixed-content {
    height: calc(100vh - 580px);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-question-status-card {
    box-shadow: none;
  }

  #mock-attempted-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 26vh);
    overflow-x: hidden;
  }

  #mock-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 26vh);
    overflow-x: hidden;
  }

  #mock-startresume-fixed-content {
    height: calc(100vh - 29vh);
    overflow: hidden;
    overflow-y: auto;
  }

  #mock-notvisited-fixed-content {
    height: calc(100vh - 27vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-attempted-fixed-status {
    height: calc(100vh - 59vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  .mocktest-question-choices-value-choosen {
    padding: 0px 10px;
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .mock-question-container {
    width: 100%;
    padding: 0px;
    margin: 0px;
    background: #f2f2fa;
    overflow: hidden;
    height: 100vh;
  }
  .mocktest-footer-position {
    position: fixed;
    width: 85%;
    bottom: 0px;
    padding-right: 30px;
    z-index: 1;
    left: 3px;
}
  .mockaside-icon {
    padding: 16px 5px 16px 6px;
    background: #3c4852;
    position: absolute;
    left: -16px;
    border-radius: 7px 0px 0px 7px;
    top: 45%;
    cursor: pointer;
  }

  .side-BarText {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(50px, auto);
    justify-content: center;
    padding: 0px 30px;
  }

  .mock-content-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 13px;
    justify-content: center;
    padding: 0px;
  }

  .mocktest-question-choices-value-choosen {
    padding: 0px 10px;
    font-size: 15px;
  }

  .mock-content-background-grid {
    padding: 1px 5px;
  }
  .course-modules-mocktest-group-buttons
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 16px;
    font-weight: 600;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    padding: 0 8px;
    font-size: 16px;
    line-height: 25px;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    height: 40px;
  }
}

@media (min-width: 961px) {
  .mock-question-container {
    width: 100%;
    padding: 0px;
    margin: 0px;
    background: #f2f2fa;
    overflow: hidden;
    height: 100vh;
  }

  .mockaside-icon-left {
    padding: 16px 5px 17px 6px;
    background: #3c4852;
    position: absolute;
    right: -4px;
    border-radius: 11px 0px 0px 11px;
    top: 45%;
    cursor: pointer;
  }

  .mock-questions-count-column .card .mock-card-user-block .pause-icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  .side-BarText {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 47px;
    grid-auto-rows: minmax(50px, auto);
    justify-content: center;
    padding: 0px 30px;
  }

  .mock-content-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 38px;
    justify-content: center;
    padding: 0px;
  }

  .mock-content-background-grid {
    padding: 1px 5px;
  }

  .questionTab {
    padding-left: 40px;
  }

  .inner-Content {
    display: flex;
    align-items: center;
    min-width: 160px;
    text-align: center;
    padding: 1px;
    justify-content: space-between;
  }

  .inner-ContentEnd {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mocktest-question-container {
    background: transparent;
    margin: auto;
    min-height: 100vh;
    height: 100%;
    width: 80%;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-header
    .icon-column
    .back-icon {
    color: #fff;
    font-size: 18px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .header
    .title {
    font-size: 18px;
    background: rgb(11, 100, 157);
    padding: 16px 26px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-transform: capitalize;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout {
    margin: 15px 3px 6px 3px;
  }

  .mock-card-user-block {
    padding: 5% 4%;
  }

  .mocktest-question-choices-value-choosen {
    padding: 0px 20px;
    font-size: 15px;
  }

  .mocktest-footer-position {
    position: fixed;
    width: 100%;
    bottom: 0px;
    padding-right: 30px;
    z-index: 1;
  }

  .mocktest-footer-position-full {
    position: fixed;
    width: 100%;
    bottom: 0px;
    z-index: 1;
    left: 3px;
  }

  .mocktest-footer-container {
    padding: 20px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 8%);
    /* border-top: 1px solid lightgrey; */
  }

  .un-mark-question-btn {
    float: left;
    color: #3c4852;
    font-size: 16px;
    font-weight: 600;
    background: #f0f1f4;
    border-radius: 2px;
    cursor: pointer;
    padding: 10px 20px;
    margin: 0px 20px 0px 1px;
  }

  .mocktest-quest-footer {
    position: fixed;
    width: 35%;
    bottom: 0px;
  }

  /* Antd tabs */
  .question-tab-column {
    padding: 8px 4px 8px 8px;
    background: #fff;
    border: 1px solid #d3d8dd;
  }

  .question-tab-column
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    background: #0b649d;
    color: #fff !important;
    font-size: 17px;
    font-weight: 600;
  }

  .question-tab-column .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    text-shadow: 0 0 0.25px currentColor;
    padding: 0px 6px 0px 22px;
    font-size: 18px;
  }

  .question-section-tabpane-name {
    margin: 0px 35px 0px 0px;
  }

  .question-section-tabpane {
    padding: 8px;
  }

  .question-bar {
    background: rgb(60, 72, 82);
    padding: 7px 26px;
    margin: 0px 10px;
    border-radius: 3px;
  }

  .section-timer-flex {
    padding: 13px;
    font-size: 14px;
    flex-direction: row;
  }

  .section-timer {
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    display: block;
  }

  .course-modules-mocktest-group-buttons {
    margin: 16px 20px 20px 20px;
  }

  /* scroll */
  #mock-fixed-content {
    height: calc(100vh - 600px);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-question-status-card {
    box-shadow: none;
  }

  #mock-attempted-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 30vh);
    overflow-x: hidden;
  }

  #mock-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 26vh);
    overflow-x: hidden;
  }

  .section-timer #mock-startresume-fixed-content {
    height: calc(100vh - 46vh);
    overflow: hidden;
    overflow-y: auto;
  }

  #mock-notvisited-fixed-content {
    height: calc(100vh - 34vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-attempted-fixed-status {
    height: calc(100vh - 59vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  .mockaside-icon {
    padding: 16px 5px 16px 6px;
    background: #3c4852;
    position: absolute;
    left: -16px;
    border-radius: 7px 0px 0px 7px;
    top: 45%;
    cursor: pointer;
  }
  .course-modules-mocktest-group-buttons
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 16px;
    font-weight: 600;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    padding: 0 8px;
    font-size: 16px;
    line-height: 25px;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    height: 40px;
  }
}
@media (min-width: 996px) {
  #mock-startresume-fixed-content {
    height: calc(100vh - 22vh);
    overflow: hidden;
    overflow-y: auto;
  }
}

@media (min-width: 1024px) {
  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-submit {
    padding: 6.5px 85px !important;
    border-top: 1px solid rgba(90, 114, 200, 0.2);;
  }
  .mocktest-question-container
  .main-layout
  .row
  .mock-questions-count-column
  .card
  .question-submited {
  padding: 7px 85px !important;
  border-top: 2px solid rgba(90, 114, 200, 0.2);;
}
  .mocktest-question-container .main-layout .row .mock-question-column {
    height: calc(100vh - 17px) !important;
  }
  #mock-startresume-fixed-content {
    height: calc(100vh - 252px);
  }
  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .question {
    padding-bottom: 20px;
  }
  .mock-question-container {
    width: 100%;
    padding: 0px;
    margin: 0px;
    background: #f2f2fa;
    overflow: hidden;
    height: 100vh;
  }

  .mockaside-icon {
    padding: 16px 5px 16px 6px;
    background: #3c4852;
    position: absolute;
    left: -16px;
    border-radius: 7px 0px 0px 7px;
    top: 41%;
    cursor: pointer;
  }

  .mockaside-icon-left {
    padding: 16px 5px 17px 6px;
    background: #3c4852;
    position: absolute;
    right: -4px;
    border-radius: 11px 0px 0px 11px;
    top: 45%;
    cursor: pointer;
  }

  .colan {
    display: none;
    margin-left: 9px;
  }

  .side-BarText {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    grid-auto-rows: minmax(50px, auto);
    justify-content: center;
    padding: 0px 30px;
  }

  .mock-content-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 22px;
    justify-content: center;
    padding: 0px;
  }

  .mock-content-background-grid {
    padding: 1px 5px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row {
    padding: 10px 30px;
  }

  .questionTab {
    padding-left: 40px;
  }

  .inner-Content {
    display: flex;
    align-items: center;
    min-width: 160px;
    text-align: center;
    padding: 1px;
    justify-content: flex-start;
  }

  .inner-ContentEnd {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .mock-text {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }

  .section-timer-flex {
    padding: 10px;
    font-size: 15px;
    flex-direction: column;
  }

  .section-timer {
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
    display: block;
    min-width: max-content;
  }

  .mocktest-question-container {
    background: transparent;
    margin: auto;
    min-height: 100vh;
    height: 100%;
    width: 70%;
  }

  .mocktest-question-container .breadcrumb-container {
    margin: 0px;
  }

  .mocktest-question-container .breadcrumb-container .breadcrumb {
    margin: 0px;
    padding: 0px;
  }

  .mocktest-question-container .breadcrumb-container .breadcrumb .item {
    cursor: pointer;
  }

  .mocktest-question-container .breadcrumb-container .breadcrumb .item .link {
    color: #0b649d;
    font-size: 14px;
  }

  .mock-question-container {
    width: 100%;
    padding: 0px;
    margin: 0px;
    background: #f9f9fd;
    overflow: hidden;
    height: 100vh;
  }

  .mocktest-question-container .breadcrumb-container .breadcrumb .active-item {
    color: #0b649d;
    font-weight: 400;
    font-size: 14px;
  }

  .mocktest-question-container .main-layout {
    margin: 0px;
    margin-top: 0px !important;
    background: #f9f9fd;
  }

  .mocktest-question-container .main-layout .row {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card {
    padding: 0px 10px 0px 0px;
    border-top: 1px solid rgba(90, 114, 200, 0.2);
    border-radius: 3px;
    background: #ffffff;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-header {
    background: #0b649d;
    padding: 10px 20px;
    border-top: 1px solid rgba(90, 114, 200, 0.2);
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-header
    .title-column {
    color: #fff;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-header
    .title-column
    .title {
    font-size: 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-header
    .icon-column {
    text-align: end;
    padding: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-header
    .icon-column
    .back-icon {
    color: #fff;
    font-size: 22px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header {
    padding: 15px 40px;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .left-column {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .left-column
    .icon {
    cursor: pointer;
    width: 25px;
    height: 25px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .center-column {
    text-align: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .center-column
    .timer {
    background: #fbd7e1;
    border-radius: 4px;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .center-column
    .timer
    .text {
    padding: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .center-column
    .timer-spin {
    text-align: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .right-column {
    margin: 0px;
    text-align: end;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .inner-layout
    .card
    .row-sub-header
    .right-column
    .pause-icon {
    cursor: pointer;
    width: 55px;
    height: 55px;
  }

  .mock-questions-count-column .card .mock-card-user-block .pause-icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  .mocktest-question-container .main-layout .row .mock-questions-count-column {
    margin: 0px;
    position: absolute;
    width: 70%;
    /* top: 65px; */
    right: 0;
    z-index: 1;
    display: block;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .mock-question-column {
    padding: 0px 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .mock-question-column
    .text {
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat {
    text-align: end;
    text-align: left;
    padding-right: 1.5px !important;
    padding-left: 17.5px !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat
    .content {
    padding: 0px 20px;
    display: contents;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat
    .content
    .box-1 {
      background: #8ad6ad;
      border-radius: 4px;
      color: #000;
      font-size: 12px;
      padding: 6px 10px;
      font-weight: bold;
      margin: 5px;
      width: 42px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat
    .content
    .box-2 {
      background: #fbd7e1;
      border-radius: 4px;
      color: #000;
      font-size: 12px;
      padding: 6px 4px;
      font-weight: bold;
      margin: 5px;
      width: 42px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat
    .content
    .bookmark-span {
    cursor: pointer;
    margin: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat
    .content
    .bookmarked-span {
    cursor: pointer;
    margin: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat
    .content
    .share-span {
    cursor: pointer;
    margin: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat
    .content
    .share-span
    .share-icon {
    margin: 0px;
    width: 20px;
    height: 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .question {
    background: rgba(90, 114, 200, 0.04);
    padding: 20px 15px 10px 15px;
    margin: 10px 32px;
    font-size: 17px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .question
    .question-name
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question {
    padding: 10px 25px 20px;
    margin:20px 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question
    .question {
    margin: 0px;
    padding: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question
    .question
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question
    .show-content {
    text-align: center;
    padding: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question
    .show-content
    .show-more,
  .show-less {
    cursor: pointer;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question
    .show-content {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question
    .show-content
    .show-more {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .group-question
    .show-content
    .show-less {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .solution-choices {
    margin: 10px 20px 10px 32px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .solution-choices
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .solution-choices
    .right {
    padding: 10px 16px;
    margin: 20px 5px;
    border: 0px solid #01a54e;
    color: #01a54e;
    font-weight: 600;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .solution-choices
    .not-choosen {
    padding: 2px 16px;
    background: #fff;
    margin: 20px 5px;
    border: 0px solid #d3d8dd;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .solution-choices
    .wrong {
    background: #ffe9e9;
    padding: 10px 16px;
    margin: 20px 5px;
    border: 0px solid #f7685b;
    cursor: pointer;
    color: #f7685b;
    font-weight: 600;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .reattempt-choices
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .reattempt-choices
    .right {
    padding: 0px;
    background: #ccffe4;
    margin: 25px 5px;
    border: 0px solid #01a54e;
    cursor: pointer;
    color: #01a54e;
    font-weight: 600;
    padding: 10px 10px 10px 15px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .reattempt-choices
    .not-choosen {
    padding: 0px;
    background: #fff;
    margin: 25px 5px;
    border: 0px solid #d3d8dd;
    cursor: pointer;
    padding: 0px 16px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .reattempt-choices
    .wrong {
    padding: 0px;
    background: #ffe9e9;
    margin: 20px 5px;
    border: 0px solid #f7685b;
    cursor: pointer;
    color: #f7685b;
    font-weight: 600;
    padding: 10px 10px 10px 15px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .start-choices {
    margin: 10px 10px 10px 45px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .start-choices
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .start-choices
    .not-choosen {
    padding: 0px;
    background: #fff;
    margin: 20px 5px;
    border: 0px solid #d3d8dd;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .start-choices
    .choosen {
    padding: 0px;
    background: transparent;
    margin: 20px 5px;
    border: 0px solid #109cf1;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .resume-choices {
    margin: 10px 10px 10px 45px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .resume-choices
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .resume-choices
    .choosen {
    padding: 0px;
    background: transparent;
    margin: 20px 5px;
    border: 0px solid #0b649d;
    color: #0b649d;
    font-weight: 600;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .resume-choices
    .not-choosen {
    padding: 0px;
    margin: 20px 5px;
    border: 0px solid #d3d8dd;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout {
    margin-top: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background: #ffffff;
    padding: 16px 5px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .row {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .row
    .title-column {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .row
    .title-column
    .text {
    color: #151515;
    font-weight: 600;
    font-size: 18px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .row
    .report-column {
    margin: 0px;
    text-align: end;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .row
    .report-column
    .report {
    background: #e0f3ff;
    border-radius: 90px;
    padding: 5px 6px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .row
    .report-column
    .report
    .report-image {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .divider {
    margin: 15px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .row {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .row
    .label {
    color: #151515;
    font-size: 18px;
    font-weight: 600;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .row
    .label
    .option-value {
    color: #151515;
    font-size: 18px;
    font-weight: 400;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .content {
    padding-top: 15px;
    margin: 0px 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .content
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout {
    border: 1px solid #d1d1d1;
    box-sizing: border-box;
    margin-top: 40px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row {
    align-items: center;
    padding: 20px 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .difficult-column {
    text-align: start;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .difficult-column
    .image-span {
    padding: 0px 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .difficult-column
    .image-span
    .image {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .difficult-column
    .text-span {
    margin: 0px;
    color: #151515;
    font-size: 18px;
    font-weight: normal;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .difficult-column
    .text-span
    .easy-text {
    margin: 0px;
    color: #151515;
    font-size: 18px;
    font-weight: 400;
    color: #01a54e;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .difficult-column
    .text-span
    .hard-text {
    margin: 0px;
    color: #151515;
    font-size: 18px;
    font-weight: 400;
    color: #ee2d3b;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .difficult-column
    .text-span
    .medium-text {
    margin: 0px;
    color: #151515;
    font-size: 18px;
    font-weight: 400;
    color: #f9873c;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .attempt-column {
    text-align: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .attempt-column
    .image-span {
    padding: 0px 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .attempt-column
    .image-span
    .image {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .attempt-column
    .text-span {
    margin: 0px;
    color: #151515;
    font-size: 18px;
    font-weight: normal;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .inner-layout
    .row
    .attempt-column
    .text-span
    .text {
    margin: 0px;
    color: #151515;
    font-size: 18px;
    font-weight: 600;
    color: #01a54e;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .divider {
    margin: 0px;
    padding: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .topic {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .topic
    .content {
    text-align: center;
    padding: 20px 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .topic
    .content
    .image-span {
    margin: 0px;
    padding: 0px 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .topic
    .content
    .image-span
    .image {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .topic
    .content
    .text-span {
    color: #151515;
    font-size: 18px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .footer-layout
    .topic
    .content
    .text-span
    .text {
    font-weight: 600;
  }

  .mocktest-question-container .main-layout .row .mock-questions-count-column {
    margin: 0px;
    padding: 0px 0px 0px 0px !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card {
    background: #fff;
    padding: 0px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .header {
    border: 1px solid #d1d1d1;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .header
    .title {
    font-size: 18px;
    background: rgb(11, 100, 157);
    padding: 14px 26px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-transform: capitalize;
  }

  .mock-card-user-block {
    padding: 2.8% 5%;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .header
    .text {
    color: #151515;
    font-size: 18px;
    font-weight: 600;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content {
    padding: 15px 0px;
    margin: 0px 20px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .row {
    justify-content: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .row
    .column {
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .answered {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .un-answered {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .row
    .column
    .mark-question {
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: linear-gradient(180deg, #8e6cad 0%, #2c0a4d 160.31%);
    color: #fff;
    font-size: 18px;
    border-radius: 90px;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .row
    .column
    .correct {
    background: #ccffe4;
    border: 1px solid #01a54e;
    border-radius: 90px;
    color: #01a54e;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .mocktest-type-row
    .content
    .not-answered {
    background: linear-gradient(180deg, #f8f8f6 48.3%, #d3d3d3 100%);
    color: #000;
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .mocktest-type-row
    .content
    .answered {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    color: #fff;
    font-weight: 900;
    font-size: 18px;
    text-align: "center";
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .mocktest-type-row
    .content
    .un-answered {
    background: linear-gradient(180deg, #ad2806 0%, #fc5a10 96.15%);
    border: 2px solid #cf6e52;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 900;
    font-size: 18px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .mocktest-type-row
    .content
    .mark-question {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 900;
    font-size: 18px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .not-answered {
    color: #000;
    font-size: 18px;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .wrong-answer {
    background: #fbd7e1;
    border: 1px solid #ee2d3b;
    border-radius: 90px;
    color: red;
    font-weight: bold;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .not-answered
    .status-icon,
  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .answered
    .status-icon,
  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .un-answered
    .status-icon,
  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .mark-question
    .status-icon {
    font-size: 30px;
    position: absolute;
    bottom: 30px;
    color: #0b649d;
    left: 45px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .content
    .correct
    .status-icon {
    font-size: 30px;
    position: absolute;
    bottom: 25px;
    color: #01a54e;
    right: 5px;
  }

  .correct .reattempt-status-icon {
    font-size: 30px;
    position: absolute;
    bottom: 25px;
    color: #01a54e;
    right: 5px;
  }

  .wrong-answer .reattempt-status-icon {
    font-size: 30px;
    position: absolute;
    bottom: 25px;
    color: #ee2d3b;
    right: 5px;
  }

  .wrong-answer .status-icon {
    font-size: 30px;
    position: absolute;
    bottom: 25px;
    color: #ee2d3b;
    right: 5px;
  }

  .reattempt-status-icon {
    font-size: 30px;
    position: absolute;
    bottom: 25px;
    color: #0b649d;
    right: 5px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer {
    padding: 0px;
    margin: 0px 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type1-row {
    margin: 0px;
    justify-content: center;
    padding: 0px 20px;
    white-space: nowrap;
    flex-direction: column;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type1-row
    .column {
    margin: 0px;
    padding: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type1-row
    .column
    .content {
    padding: 0px;
    margin: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type1-row
    .column
    .content
    .image {
    width: 10px;
    height: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type1-row
    .column
    .content
    .text {
    font-size: 16px;
    padding: 0px 5px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type2-row {
    margin: 0px;
    justify-content: center;
    padding: 20px;
    white-space: nowrap;
    flex-direction: column;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type2-row
    .column {
    margin: 0px;
    padding: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type2-row
    .column
    .content {
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type2-row
    .column
    .content
    .image {
    width: 10px;
    height: 10px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .footer
    .type2-row
    .column
    .content
    .text {
    font-size: 14px;
    padding: 0px 5px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-submit {
    background: #fff;
    padding: 21px 85px;
  }
  .mocktest-question-container
  .main-layout
  .row
  .mock-questions-count-column
  .card
  .question-submited {
  background: #fff;
  padding: 21px 85px;
}

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .question-submit
    .button {
    height: 45px;
  }

  .mocktest-popup-modal-body-content-instruction {
    padding: 0px 0px;
    font-size: 18px;
  }

  .mocktest-popup-modal-body-content-instruction strong {
    display: flex;
    justify-content: center;
  }

  .mocktest-questions-main2-card2-choice-not-choosen {
    background: transparent;
    font-size: 16px;
    font-weight: 400;
  }

  .mocktest-questions-main2-card2-choice-choosen {
    background: #0b649d;

    padding: 14px 21px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
  }

  .mocktest-questions-main2-card2-choice-isright {
    background: #c9fce1;
    padding: 5px 0px;
    color: #01a54e;
    font-weight: bold;
    font-size: 16px;
  }

  .mocktest-questions-main2-card2-choice-iswrong {
    background: #ffe9e9;
    padding: 5px 0px;
    color: #f00;
    font-weight: bold;
    font-size: 16px;
  }

  .mocktest-question-choices-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mocktest-question-choices-value {
    padding: 0px 20px;
  }

  .mocktest-popup-modal-leave-footer-cancel {
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    background: rgb(240, 242, 245) !important;
    margin: 10px;
    border: 0px solid;
    color: #000 !important;
    height: 45px;
  }

  .mocktest-popup-modal-leave-footer-submit-icon {
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    background: #0b649d !important;
    margin: 0px;
    border: 0px solid;
    color: #fff !important;
    height: 45px;
  }

  .mocktest-popup-modal-leave-footer-submit-link {
    color: #fff !important;
    width: 100%;
    margin: 10px;
  }

  .mocktest-popup-modal-footer-cancel-button {
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    background: rgb(240, 242, 245) !important;
    margin: 10px;
    border: 0px solid;
    height: 45px;
    color: #000 !important;
  }

  .mocktest-popup-modal-footer-submit-button {
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    margin: 10px;
    background: #0b649d;
    color: #fff;
    height: 45px;
  }

  .mocktest-popup-modal-body-submit-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    align-items: center;
  }

  .mocktest-popup-modal-body-submit-content-image {
    padding: 0px 10px;
  }

  .mocktest-popup-modal-body-submit-content-image-text {
    padding: 5px;
    font-size: 14px;
  }

  .mocktest-question-choices-value-choosen {
    padding: 0px 20px;
    font-size: 15px;
  }

  .mocktest-reattempt-question-choices-value-choosen {
    padding: 0px 20px;
    font-weight: 900;
    display: flex;
    align-items: center;
    font-size: 15px;
  }

  .mocktest-question-choices-value-not-choosen {
    padding: 0px 20px;
    display: flex;
    align-items: center;
    font-size: 15px;
  }

  .mocktest-reattempt-question-choices-value-not-choosen {
    padding: 0px 20px;
  }

  .mocktest-question-choices-value-choosen p,
  .mocktest-reattempt-question-choices-value-choosen p,
  .mocktest-question-choices-value-not-choosen p,
  .mocktest-reattempt-question-choices-value-not-choosen p,
  .mocktest-question-choices-value p {
    margin: 0px;
    padding: 0px;
  }

  .mocktest-footer-position {
    position: fixed;
    width: 70%;
    bottom: 0px;
    padding-right: 30px;
    z-index: 1;
    left: 3px;
  }

  .mocktest-footer-container {
    padding: 6.5px 10px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 8%);
    /* border-top: 1px solid lightgrey; */
  }

  .un-mark-question-btn {
    float: left;
    color: #3c4852;
    font-size: 16px;
    font-weight: 600;
    background: #f0f1f4;
    border-radius: 2px;
    cursor: pointer;
    padding: 8px 18px;
    margin: 0px 20px 0px 1px;
  }

  /* Antd tabs */
  .question-tab-column {
    padding: 8px 4px 8px 8px;
    background: #fff;
    border: 1px solid #d3d8dd;
  }

  .question-tab-column
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    background: #0b649d;
    color: #fff !important;
    font-size: 18px;
    font-weight: 600;
  }

  .question-tab-column .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    text-shadow: 0 0 0.25px currentColor;
    padding: 0px 6px 0px 22px;
    font-size: 18px;
  }

  .question-section-tabpane-name {
    margin: 0px 35px 0px 0px;
    text-transform: capitalize;
  }

  .question-section-tabpane {
    padding: 5px;
  }

  .mocktest-quest-footer {
    position: fixed;
    width: 34%;
    bottom: 0px;
  }

  .question-bar {
    background: rgb(60, 72, 82);
    padding: 4px 8px;
    margin: 0px 2px;
    border-radius: 3px;
    min-width: fit-content;
  }

  .course-modules-mocktest-group-buttons {
    margin: 16px 20px 20px 20px;
  }

  /* scroll */
  #mock-fixed-content {
    height: calc(100vh - 62vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-question-status-card {
    box-shadow: none;
  }

  #mock-attempted-fixed-status {
    height: calc(100vh - 59vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-attempted-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 30vh);
    overflow-x: hidden;
  }

  #mock-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 26vh);
    overflow-x: hidden;
  }

  #mock-notvisited-fixed-content {
    height: calc(100vh - 36vh);
    overflow: hidden;
    overflow-y: scroll;
  }
  .course-modules-mocktest-group-buttons
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 17px;
    font-weight: 600;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    padding: 0 10px;
    font-size: 17px;
    line-height: 30px;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    height: 45px;
  }
}

@media (min-width: 1100px) {
  .mock-question-container {
    width: 100%;
    padding: 0px;
    margin: 0px;
    background: #f2f2fa;
    overflow: hidden;
    height: 100vh;
  }
  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout {
    margin: 15px 3px 15px 3px;
  }
  .mockaside-icon-left {
    padding: 16px 5px 17px 6px;
    background: #3c4852;
    position: absolute;
    right: -4px;
    border-radius: 11px 0px 0px 11px;
    top: 45%;
    cursor: pointer;
  }

  .mock-questions-count-column .card .mock-card-user-block .pause-icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout {
    margin: 15px 3px 6px 3px;
  }

  .colan {
    display: block;
  }

  .questionTab {
    padding-left: 40px;
  }

  .inner-Content {
    display: flex;
    align-items: center;
    min-width: 160px;
    text-align: center;
    padding: 1px;
    justify-content: space-between;
  }

  .inner-ContentEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .mocktest-question-container .main-layout .row .mock-questions-count-column {
    margin: 0px;
    top: 0;
    position: relative;
    right: 0;
    display: block;
  }

  .mocktest-question-choices-value-choosen {
    padding: 0px 20px;
    font-size: 15px;
  }
  .course-modules-mocktest-group-buttons
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 17px;
    font-weight: 600;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    padding: 0 10px;
    font-size: 17px;
    line-height: 30px;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    height: 48px;
  }
}

@media (min-width: 1200px) {
  .mock-questions-count-column .card .mock-card-user-block .pause-icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-bottom: 42px;
  }
  .center-text {
    text-align: center;
  }
  .right-text {
    text-align: right;
  }
  .mockaside-icon {
    padding: 16px 5px 17px 6px;
    background: #3c4852;
    position: absolute;
    left: -16px;
    border-radius: 7px 0px 0px 7px;
    top: 45%;
    cursor: pointer;
  }

  .mock-question-container {
    width: 100%;
    padding: 0px;
    margin: 0px;
    background: #f2f2fa;
    overflow: hidden;
    height: 100vh;
  }

  .mockaside-icon-left {
    padding: 16px 5px 17px 6px;
    background: #3c4852;
    position: absolute;
    right: -8px;
    border-radius: 11px 0px 0px 11px;
    top: 45%;
    cursor: pointer;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout {
    /* margin: 15px 3px -4px 3px; */
    margin: 4px 3px -4px 3px;
  }
  .mocktest-question-container
  .main-layout 
  .row
  .mock-questions-count-column 
  .card 
  .layout.drawer-topic-container{
    margin: -1px 3px -4px 3px;
  }
  .mock-questions-count-column .card .mock-card-user-block .pause-icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-bottom: auto;
  }

  .colan {
    display: block;
  }

  .side-BarText {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(50px, auto);
    justify-content: center;
    padding: 0px 30px;
  }

  .mock-content-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap:19px;
    justify-content: center;
    padding: 0px;
  }

  .mock-content-background-grid {
    padding: 1px 2px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row {
    padding: 10px 15px;
  }

  .questionTab {
    padding-left: 40px;
  }

  .inner-Content {
    display: flex;
    align-items: center;
    min-width: 160px;
    text-align: center;
    padding: 1px;
    justify-content: flex-start;
  }

  .inner-ContentEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .mocktest-question-container {
    background: transparent;
    margin: auto;
    min-height: 100vh;
    height: 100%;
    width: 70%;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .content
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container .main-layout .row .mock-questions-count-column {
    margin: 0px;
    position: relative;
    width: 70%;
    top: 0;
    right: 0;
    z-index: 1;
    display: block;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-next {
      background: #fff;
      padding: 7px 65px;
      /* border-top: 1px solid lightgrey; */
  }

  .mocktest-footer-position {
    position: fixed;
    width: 76%;
    bottom: 0px;
    padding-right: 15px;
    z-index: 1;
    left: 3px;
    border-top: 1px solid rgba(90, 114, 200, 0.2);;
  }

  .mocktest-footer-position-full {
    position: fixed;
    width: 100%;
    bottom: 0px;
    z-index: 1;
    left: 3px;
  }

  .mocktest-footer-container {
    padding: 6.5px 15px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 8%);
  }

  .un-mark-question-btn {
    float: left;
    color: #3c4852;
    font-size: 16px;
    font-weight: 600;
    background: #f0f1f4;
    border-radius: 2px;
    cursor: pointer;
    padding: 10px 20px;
    margin: 0px 20px 0px 1px;
  }

  .question-bar {
    background: rgb(60, 72, 82);
    padding: 3px 11px;
    margin: 0px 7px;
    border-radius: 3px;
    min-width: fit-content;
  }

  .section-timer-flex {
    padding: 10px;
    font-size: 13px;
    flex-direction: row;
    width: max-content;
  }

  .section-timer {
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
    display: block;
    min-width: fit-content;
  }

  .course-modules-mocktest-group-buttons {
    margin: 13px 20px;
  }

  /* Antd tabs */
  .question-tab-column {
    padding: 8px 4px 8px 8px;
    background: #fff;
    border: 1px solid #d3d8dd;
  }

  .question-tab-column
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    background: #0b649d;
    color: #fff !important;
    font-size: 18px;
    font-weight: 600;
  }

  .question-tab-column .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    text-shadow: 0 0 0.25px currentColor;
    padding: 0px 10px 0px 28px;
    font-size: 18px;
  }

  .question-section-tabpane-name {
    margin: 0px 30px 0px 0px;
  }

  .question-section-tabpane {
    padding: 12px 8px;
  }

  /* scroll */
  #mock-fixed-content {
    height: calc(100vh - 55vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-question-status-card {
    box-shadow: none;
  }

  #mock-attempted-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 30vh);
    overflow-x: hidden;
  }

  #mock-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 30vh);
    overflow-x: hidden;
  }

  #mock-notvisited-fixed-content {
    height: calc(100vh - 34vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-attempted-fixed-status {
    height: calc(100vh - 72vh);
    overflow: hidden;
    overflow-y: scroll;
  }
  .course-modules-mocktest-group-buttons
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 17px;
    font-weight: 600;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    padding: 0 10px;
    font-size: 17px;
    line-height: 30px;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    height: 48px;
  }
}

@media (min-width: 1330px) {
  .mocktest-quest-footer {
    position: fixed;
    width: 27%;
    bottom: 0px;
  }

  .mock-question-container {
    width: 100%;
    padding: 0px;
    margin: 0px;
    background: #f2f2fa;
    overflow: hidden;
    height: 100vh;
  }

  .mockaside-icon {
    padding: 16px 5px 17px 6px;
    background: #3c4852;
    position: absolute;
    left: -16px;
    border-radius: 7px 0px 0px 7px;
    top: 45%;
    cursor: pointer;
  }
  .course-modules-mocktest-group-buttons
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 17px;
    font-weight: 600;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    padding: 0 10px;
    font-size: 17px;
    line-height: 30px;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    height: 48px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1366px) {
  .mock-question-container {
    width: 100%;
    padding: 0px;
    margin: 0px;
    background: #f2f2fa;
    overflow: hidden;
    height: 100vh;
  }

  .mockaside-icon {
    padding: 16px 5px 16px 6px;
    background: #3c4852;
    position: absolute;
    left: -16px;
    border-radius: 7px 0px 0px 7px;
    top: 38%;
    cursor: pointer;
  }
}

@media (min-width: 1440px) {
  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row
    .question-stat {
    text-align: end;
    text-align: left;
    padding-right: 12.5px !important;
    padding-left: 26.5px !important;
  }

  .mockaside-icon {
    padding: 16px 5px 16px 6px;
    background: #3c4852;
    position: absolute;
    left: -16px;
    border-radius: 7px 0px 0px 7px;
    top: 40%;
    cursor: pointer;
  }

  .mockaside-icon-left {
    padding: 16px 5px 17px 6px;
    background: #3c4852;
    position: absolute;
    right: -9px;
    border-radius: 11px 0px 0px 11px;
    top: 45%;
    cursor: pointer;
  }

  .mock-questions-count-column .card .mock-card-user-block .pause-icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-bottom: auto;
  }

  .colan {
    display: block;
  }

  .mocktest-quest-footer {
    position: fixed;
    width: 25%;
    bottom: 0px;
  }

  .side-BarText {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 9px;
    grid-auto-rows: minmax(50px, auto);
    justify-content: center;
    padding: 0px 30px;
  }

  .mock-content-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 7px;
    justify-content: center;
    padding: 0px 20px;
  }

  .mock-content-background-grid {
    padding: 1px 5px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .body-layout
    .inner-layout
    .card
    .row {
    padding: 10px 22px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .header
    .title {
    font-size: 18px;
    background: rgb(11, 100, 157);
    padding: 16px 26px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-transform: capitalize;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout {
    margin: 15px 3px 6px 3px;
  }

  .mock-card-user-block {
    padding: 2.8% 6%;
  }

  .questionTab {
    padding-left: 20px;
  }

  .inner-Content {
    display: flex;
    align-items: center;
    min-width: 160px;
    text-align: center;
    padding: 1px;
    justify-content: flex-start;
  }

  .inner-ContentEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .content
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container {
    background: transparent;
    margin: auto;
    min-height: 100vh;
    height: 100%;
    width: 70%;
  }

  .mocktest-footer-position {
    position: fixed;
    width: 76%;
    bottom: 0px;
    padding-right: 15px;
    z-index: 1;
    left: 3px;
  }

  .mocktest-question-container .main-layout .row .mock-questions-count-column {
    margin: 0px;
    position: relative;
    width: 70%;
    top: 0;
    right: 0;
    z-index: 1;
    display: block;
  }

  .mocktest-footer-position-full {
    position: fixed;
    width: 100%;
    bottom: 0px;
    z-index: 1;
    left: 3px;
  }

  .mocktest-footer-container {
    box-shadow: 0px 2px 10px rgb(0 0 0 / 8%);
    /* border-top: 1px solid lightgrey; */
  }

  .un-mark-question-btn {
    float: left;
    color: #3c4852;
    font-size: 16px;
    font-weight: 600;
    background: #f0f1f4;
    border-radius: 2px;
    cursor: pointer;
    padding: 10px 20px;
    margin: 0px 20px 0px 1px;
  }

  .question-bar {
    background: rgb(60, 72, 82);
    padding: 5px 14px;
    margin: 0px 10px;
    border-radius: 3px;
  }

  .section-timer-flex {
    padding: 13px;
    font-size: 15px;
    flex-direction: row;
    float: right;
    width: max-content;
  }

  .section-timer {
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    display: block;
  }

  .course-modules-mocktest-group-buttons {
    margin: 20px 30px 16px 30px;
  }

  /* Antd tabs */
  .question-tab-column {
    padding: 8px 4px 8px 8px;
    background: #fff;
    border: 1px solid #d3d8dd;
  }

  .question-tab-column
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    background: #0b649d;
    color: #fff !important;
    font-size: 18px;
    font-weight: 600;
  }

  .question-tab-column .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    text-shadow: 0 0 0.25px currentColor;
    padding: 0px 10px 0px 28px;
    font-size: 18px;
  }

  .question-section-tabpane-name {
    margin: 0px 40px 0px 0px;
  }

  .question-section-tabpane {
    padding: 8px;
  }

  /* scroll */
  #mock-fixed-content {
    height: calc(100vh - 61vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-question-status-card {
    box-shadow: none;
  }

  #mock-attempted-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 30vh);
    overflow-x: hidden;
  }

  #mock-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 26vh);
    overflow-x: hidden;
  }

  #mock-notvisited-fixed-content {
    height: calc(100vh - 34vh);
    overflow: hidden;
    overflow-y: scroll;
  }
  .course-modules-mocktest-group-buttons
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 18px;
    font-weight: 600;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    padding: 0 10px;
    font-size: 18px;
    line-height: 30px;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    height: 45px;
  }
}

@media (min-width: 1500px) {
  .mockaside-icon-left {
    padding: 16px 5px 17px 6px;
    background: #3c4852;
    position: absolute;
    right: -9px;
    border-radius: 11px 0px 0px 11px;
    top: 45%;
    cursor: pointer;
  }

  .colan {
    display: block;
  }

  .side-BarText {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    grid-auto-rows: minmax(50px, auto);
    justify-content: center;
    padding: 0px 30px;
  }

  .mock-content-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 13px;
    justify-content: center;
    padding: 0px 20px;
  }

  .mock-content-background-grid {
    padding: 1px 5px;
  }

  .questionTab {
    padding-left: 20px;
  }

  .inner-Content {
    display: flex;
    align-items: center;
    min-width: 160px;
    text-align: center;
    padding: 1px;
    justify-content: flex-start;
  }

  .inner-ContentEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .content
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .header
    .title {
    font-size: 18px;
    background: rgb(11, 100, 157);
    padding: 16px 26px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-transform: capitalize;
  }

  .mock-card-user-block {
    padding: 2.5% 4%;
  }

  .mocktest-footer-position {
    position: fixed;
    width: 77%;
    bottom: 0px;
    padding-right: 34px;
    z-index: 1;
    left: 3px;
  }

  .mocktest-footer-position-full {
    position: fixed;
    width: 100%;
    bottom: 0px;
    z-index: 1;
    left: 3px;
  }

  .mocktest-footer-container {
    padding: 10px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 8%);
    /* border-top: 1px solid lightgrey; */
  }

  .un-mark-question-btn {
    float: left;
    color: #3c4852;
    font-size: 16px;
    font-weight: 600;
    background: #f0f1f4;
    border-radius: 2px;
    cursor: pointer;
    padding: 10px 20px;
    margin: 0px 20px 0px 1px;
  }

  .mocktest-quest-footer {
    position: fixed;
    width: 25%;
    bottom: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-next {
    background: #fff;
    padding: 10.5px 85px;
    border-top: 1px solid rgba(90, 114, 200, 0.2);;
  }

  .question-bar {
    background: rgb(60, 72, 82);
    padding: 7px 26px;
    margin: 0px 10px;
    border-radius: 3px;
  }

  .section-timer-flex {
    padding: 9px;
    font-size: 15px;
    flex-direction: row;
    align-items: center;
    max-width: max-content;
  }

  .section-timer {
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    display: block;
  }

  .course-modules-mocktest-group-buttons {
    margin: 18px 30px;
  }

  /* Antd tabs */
  .question-tab-column {
    padding: 8px 4px 8px 8px;
    background: #fff;
    border: 1px solid #d3d8dd;
  }

  .question-tab-column
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    background: #0b649d;
    color: #fff !important;
    font-size: 18px;
    font-weight: 600;
  }

  .question-tab-column .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    text-shadow: 0 0 0.25px currentColor;
    padding: 0px 6px 0px 22px;
    font-size: 18px;
  }

  .question-section-tabpane-name {
    margin: 0px 40px 0px 0px;
  }

  .question-section-tabpane {
    padding: 8px;
  }

  /* scroll */
  #mock-fixed-content {
    height: calc(100vh - 60vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-question-status-card {
    box-shadow: none;
  }

  #mock-attempted-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 30vh);
    overflow-x: hidden;
  }

  #mock-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 29vh);
    overflow-x: hidden;
  }

    /* #mock-notvisited-fixed-content {
      height: calc(100vh - 34vh);
      overflow: hidden;
      overflow-y: scroll;
    } */
  .course-modules-mocktest-group-buttons
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 18px;
    font-weight: 600;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    padding: 0 10px;
    font-size: 18px;
    line-height: 30px;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    height: 48px;
  }
}

@media (min-width: 1920px) {
  .mockaside-icon {
    padding: 16px 5px 16px 6px;
    background: #3c4852;
    position: absolute;
    left: -16px;
    border-radius: 7px 0px 0px 7px;
    top: 40%;
    cursor: pointer;
  }

  .mockaside-icon-left {
    padding: 16px 5px 17px 6px;
    background: #3c4852;
    position: absolute;
    right: -10px;
    border-radius: 11px 0px 0px 11px;
    top: 45%;
    cursor: pointer;
  }

  .colan {
    display: block;
  }

  .side-BarText {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 36px;
    justify-content: center;
    grid-auto-rows: minmax(50px, auto);
    padding: 0px 57px;
  }

  .mock-content-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 25px;
    justify-content: center;
    padding: 0px 30px;
  }

  .mock-content-background-grid {
    padding: 1px 3px;
  }

  .questionTab {
    padding-left: 40px;
  }

  .inner-Content {
    display: flex;
    align-items: center;
    min-width: 160px;
    text-align: center;
    padding: 1px;
    justify-content: flex-start;
  }

  .inner-ContentEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .content
    img {
    width: 100% !important;
    height: 100% !important;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout
    .header
    .title {
    font-size: 18px;
    background: rgb(11, 100, 157);
    padding: 16px 26px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-transform: capitalize;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout {
    margin: 0px 3px 15px 3px;
  }

  .mock-card-user-block {
    padding: 2% 3%;
  }

  .mocktest-footer-position {
    position: fixed;
    width: 76%;
    bottom: 0px;
    padding-right: 24px;
    z-index: 1;
    left: 3px;
  }

  .mocktest-footer-position-full {
    position: fixed;
    width: 100%;
    bottom: 0px;
    z-index: 1;
    left: 3px;
  }

  .mocktest-footer-container {
    padding: 10px 16px !important;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 8%);
    /* border-top: 1px solid lightgrey; */
  }

  .un-mark-question-btn {
    float: left;
    color: #3c4852;
    font-size: 16px;
    font-weight: 600;
    background: #f0f1f4;
    border-radius: 2px;
    cursor: pointer;
    padding: 10px 20px;
    margin: 0px 20px 0px 1px;
  }

  .mocktest-quest-footer {
    position: fixed;
    width: 26%;
    bottom: 0px;
    z-index: 1;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-next,
  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-submit,
    .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-submited {
    background: #fff;
    padding: 10.5px 85px;
  }

  .question-bar {
    background: rgb(60, 72, 82);
    padding: 7px 26px;
    margin: 0px 10px;
    border-radius: 3px;
  }

  .section-timer-flex {
    padding: 9px;
    font-size: 15px;
    flex-direction: row;
    align-items: center;
  }

  .section-timer {
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    display: block;
  }

  .course-modules-mocktest-group-buttons {
    margin: 20px 30px 25px 30px;
  }

  /* Antd tabs */
  .question-tab-column {
    padding: 8px 4px 8px 8px;
    background: #fff;
    border: 1px solid #d3d8dd;
  }

  .question-tab-column
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    background: #0b649d;
    color: #fff !important;
    font-size: 18px;
    font-weight: 600;
  }

  .question-tab-column .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    text-shadow: 0 0 0.25px currentColor;
    padding: 0px 6px 0px 22px;
    font-size: 18px;
  }

  .question-section-tabpane-name {
    margin: 0px 40px 0px 0px;
  }

  .question-section-tabpane {
    padding: 8px;
  }

  /* scroll */
  #mock-fixed-content {
    height: calc(100vh - 57vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-question-status-card {
    box-shadow: none;
  }

  #mock-attempted-fixed-status {
    height: calc(100vh - 54vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-attempted-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 25vh);
    overflow-x: hidden;
  }

  #mock-inner-layout {
    overflow-y: auto;
    height: calc(100vh - 25vh);
    overflow-x: hidden;
  }

  #mock-notvisited-fixed-content {
    height: calc(100vh - 22vh);
    overflow: hidden;
    overflow-y: scroll;
  }
}

@media (min-width: 2560px) {
  .mockaside-icon {
    padding: 16px 5px 16px 6px;
    background: #3c4852;
    position: absolute;
    left: -16px;
    border-radius: 7px 0px 0px 7px;
    top: 45%;
    cursor: pointer;
  }

  .colan {
    display: block;
  }

  .answered-mocktest-sidebar {
    flex-basis: 10% !important;
  }

  .side-BarText {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 53px;
    justify-content: center;
    grid-auto-rows: minmax(50px, auto);
    padding: 0px 57px;
  }

  .mock-content-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 25px;
    justify-content: center;
    padding: 0px 30px;
  }

  .mock-content-background-grid {
    padding: 1px 10px;
  }

  .inner-Content {
    display: flex;
    align-items: center;
    min-width: 160px;
    text-align: center;
    padding: 1px;
    justify-content: flex-start;
  }

  .inner-ContentEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-question-column
    .solution-layout
    .card
    .solution-body
    .content
    img {
    width: 100%;
    height: 100%;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout {
    margin: 15px 3px 15px 3px;
  }

  .mocktest-footer-position {
    position: fixed;
    width: 76%;
    bottom: 0px;
    padding-right: 25px;
    z-index: 1;
    left: 3px;
  }

  .mocktest-footer-position-full {
    position: fixed;
    width: 100%;
    bottom: 0px;
    z-index: 1;
    left: 3px;
  }

  .mocktest-footer-container {
    padding: 14px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 8%);
    border-top: 1px solid rgba(90, 114, 200, 0.2);;
  }

  .un-mark-question-btn {
    float: left;
    color: #3c4852;
    font-size: 16px;
    font-weight: 600;
    background: #f0f1f4;
    border-radius: 2px;
    cursor: pointer;
    padding: 10px 20px;
    margin: 0px 20px 0px 1px;
  }

  .mocktest-quest-footer {
    position: fixed;
    width: 25%;
    bottom: 0px;
  }

  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-submit,
  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-next,
    .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-submited { 
    background: #fff;
    padding: 26px 85px;
  }

  .question-bar {
    background: rgb(60, 72, 82);
    padding: 7px 26px;
    margin: 0px 10px;
    border-radius: 3px;
  }

  .section-timer-flex {
    padding: 13px;
    font-size: 15px;
    flex-direction: row;
    align-items: center;
  }

  .section-timer {
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    display: block;
  }

  .course-modules-mocktest-group-buttons {
    margin: 20px 30px 25px 30px;
  }

  /* Antd tabs */
  .question-tab-column {
    padding: 8px 4px 8px 8px;
    background: #fff;
    border: 1px solid #d3d8dd;
  }

  .question-tab-column
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    background: #0b649d;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }

  .question-tab-column .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    text-shadow: 0 0 0.25px currentColor;
    padding: 0px 6px 0px 22px;
    font-size: 18px;
  }

  .question-section-tabpane-name {
    margin: 0px 40px 0px 0px;
  }

  .question-section-tabpane {
    padding: 8px;
  }

  /* scroll */
  #mock-fixed-content {
    height: calc(100vh - 60vh);
    overflow: hidden;
    overflow-y: scroll;
  }

  #mock-question-status-card {
    box-shadow: none;
  }

  .mocktest-question-container .main-layout .row .mock-question-column {
    margin: 0px;
    height: calc(100vh - 12vh);
  }

  #mock-notvisited-fixed-content {
    height: calc(100vh - 45vh);
    overflow: hidden;
    overflow-y: scroll;
  }
  .course-modules-mocktest-group-buttons
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 20px;
    font-weight: 600;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    padding: 0 10px;
    font-size: 20px;
    line-height: 35px;
  }
  .course-modules-mocktest-group-buttons .ant-radio-button-wrapper {
    height: 50px;
  }
}

.drop-outer {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.drop-outer .drop-inner {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.drop-outer .drop-inner .drop-img {
  height: auto !important;
  background-size: contain !important;
  margin: 0px !important;
  background-position: center top !important;
}

.drop-img.drop-circleimg.mark-question.Marked-review {
  background-size: cover !important;
}

.mocktest-question-choices-value-choosen {
  color: #0b649d !important;
  font-weight: 900;
  display: flex;
  align-items: center;
}

/* new style start */
/* .ant-row.ant-row-middle.view-row-header {
  justify-content: space-between;
} */
.drawer-topic {
  font-size: 18px;
  padding: 17.5px 26px;
  font-weight: 600;
  color: #0b649d;
}
@media (min-width: 768px) and (max-width: 1100px) {
  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .layout {
    margin: 0px 3px 15px 3px;
  }
  .mocktest-question-container .main-layout .row .mock-questions-count-column .card .layout.drawer-topic-container {
    margin: 0px 3px -4px 3px;
   }
  .drawer-topic {
    font-size: 18px;
    padding: 16.5px 26px;
    font-weight: 600;
    color: #0b649d;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .inner-ContentEnd {
    display: flex;
    justify-content: flex-start !important;
  }
}

.mocktest-question-container
  .main-layout
  .row
  .mock-question-column
  .body-layout
  .inner-layout
  .card
  .solution-body
  img {
  width: 100% !important;
  height: 100% !important;
}

@media (min-width: 1190px) and (max-width: 1400px) {
  .mocktest-quest-footer {
    width: 25% !important;
  }
}
@media (min-width: 1520px) {
  .mocktest-question-container
    .main-layout
    .row
    .mock-questions-count-column
    .card
    .question-submit{
      padding: 10px 85px !important;
      border-top: 1px solid rgba(90, 114, 200, 0.2);;
  }
  .mocktest-question-container
  .main-layout
  .row
  .mock-questions-count-column
  .card
  .question-submited {
    padding: 10.5px 85px !important;
  border-top: 1px solid rgba(90, 114, 200, 0.2);;
}
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .mock-content-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width:768px) and (max-width:1200px){
  .inner-Content {
display:flex;
justify-content: flex-start !important;
}
}
@media (min-width:1020px) and (max-width:1200px){
.mock-content-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
}
@media (min-width:1024px) and (max-width:1370px){
  #mock-fixed-content {
    height: calc(100vh - 441px) !important;
    padding: 0px !important;
}
}
.solution-body.question-full-image{
  padding:10px 20px 20px
}
@media (min-width:768px) and (max-width:1200px){
  .solution-body.question-full-image{
  padding:10px 20px 120px 20px
}
}
@media (min-width: 768px) and (max-width:1200px){
  .mocktest-question-container .main-layout .row .mock-questions-count-column .card .question-submit {
    padding: 10px 80px !important;
    border-top: 1px solid rgba(90, 114, 200, 0.2);;
  }
  .mocktest-question-container .main-layout .row .mock-questions-count-column .card .question-submited {
    padding: 17px 80px !important;
    background: #fff;
  }
}
@media (min-height: 700px) and (max-height:768px){
.mocktest-question-container .main-layout .row .mock-questions-count-column .card .question-next {
  background: #fff;
  padding: 7px 85px !important;
  border-top: 1px solid rgba(90, 114, 200, 0.2);
}
.mocktest-question-container .main-layout .row .mock-questions-count-column .card .question-submit {
  padding: 6.5px 80px !important;
}
.mocktest-question-container .main-layout .row .mock-questions-count-column .card .question-submited {
  padding: 7px 80px !important;
  background: #fff;;
  border-top: 1px solid rgba(90, 114, 200, 0.2);;
}
}
@media (min-height: 1020px) and (max-height:1030px){
.mocktest-question-container .main-layout .row .mock-questions-count-column .card .question-next,
.mocktest-question-container .main-layout .row .mock-questions-count-column .card .question-submited {
  background: #fff;
  padding: 10.5px 85px !important;
  border-top: 1px solid rgba(90, 114, 200, 0.2);;

}
}
@media (min-height:760px) and (max-height:780px){
  .mocktest-question-container .main-layout .row .mock-question-column .body-layout .inner-layout .card .group-question{
    margin: 0px 10px 80px;
  }
  .mocktest-question-container
  .main-layout
  .row
  .mock-question-column
  .body-layout
  .inner-layout
  .start-choices {
  margin: 10px 10px 90px 45px
}
.mocktest-question-container
.main-layout
.row
.mock-question-column
.body-layout
.inner-layout
.resume-choices {
margin: 10px 10px 80px 45px;
}
}
.ant-table-wrapper:where(.css-dev-only-do-not-override-1r287do).ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >thead>tr>th{
  border-inline-end: none;
    border: 1px solid #8c8b8b !important;
}
#mock-attempted-fixed-content, #mock-notvisited-fixed-content {
  max-width: 99%;
}
/* #mock-startresume-fixed-content{
  max-width: 49%;
} */
@media (min-width:767px) and (max-width:1025px){
.mocktest-question-container .main-layout .row .mock-question-column .body-layout .inner-layout .reattempt-choices {
  margin: 10px 20px 50px 25px !important;

}
}
@media (min-width: 320px) and (max-width:1200px) {
  .mocktest-footer-container {
      border-top: 1px solid rgba(90, 114, 200, 0.2);
  }
}
@media (min-width: 1920px) {
  #mock-startresume-fixed-content {
      height: calc(100vh - 251px);
  }
  .mocktest-footer-container {
    border-top: 1px solid rgba(90, 114, 200, 0.2);
}
}
@media (min-height: 700px) and (max-height:800px) {
  #mock-startresume-fixed-content {
      height: calc(100vh - 200px);
  }
  #mock-notvisited-fixed-content,#mock-attempted-fixed-content, #mock-attempted-fixed-content {
    height: calc(100vh - 30vh);
    overflow: hidden;
    overflow-y: scroll;
  }
  #mock-attempted-fixed-status{
    height: calc(100vh - 481px) !important;
  }
}
@media (min-height: 863px) and (max-height:1023px) {
  #mock-startresume-fixed-content {
      height: calc(100vh - 241px);
  }
  #mock-notvisited-fixed-content {
      height: calc(100vh - 27vh);
      overflow: hidden;
      overflow-y: scroll;
  }
}
@media (min-height: 1024px) and (max-height:1080px) { 
  #mock-attempted-fixed-status, #mock-fixed-content {
    height: calc(100vh - 500px) !important;
  }
}
@media (min-height: 1080px) {
  #mock-attempted-fixed-status, #mock-fixed-content {
      height: calc(100vh - 474px) !important;
  }
  #mock-attempted-fixed-content {
    height: calc(100vh - 23vh);
    overflow: hidden;
    overflow-y: scroll;
}
}
@media (min-width:1500px) and (max-width:1600px){
  #mock-notvisited-fixed-content {
      height: calc(100vh - 28vh);
      overflow: hidden;
      overflow-y: scroll;
  }
}
@media (min-width:1601px) and (max-width:1920px){
  .side-BarText {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 32px;
    grid-auto-rows: minmax(50px, auto);
    justify-content: center;
    padding: 0px 30px;
  }
}