@media (min-width: 375px) {
  /* .mocktest-analysis .ant-tabs-nav-list {
    margin: 0px 1px;
  } */
  .mocktest-analysis .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav {
    flex-direction: column-reverse;
  }
  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .mocktest-analysis .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: flex;
  }
  .Back-two {
    display: block;
    font-size: large;
    font-weight: 800;
    color: #0b649d;
  }

  .mocktest-analysis-solution-button.Back-one {
    display: none;
  }
  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    flex: 1 1;
  }
}

@media (min-width: 425px) {
  /* .mocktest-analysis .ant-tabs-nav-list {
    margin: 0px 1px;
  } */
  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .mocktest-analysis .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: flex;
  }
  .mocktest-analysis .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav {
    flex-direction: column-reverse;
  }
  .mocktest-analysis .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 14px 0px;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    margin: 0px 30px !important;
    cursor: pointer;
  }
  .mocktest-analysis
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: #0b649d !important;
    font-size: 16px;
    font-weight: 600;
    padding: 0px 2px;
  }
  .mocktest-analysis .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 16px;
  }
  .mocktest-tab-menu-body {
    padding: 0px 20px;
  }
  .mocktest-analysis-grid {
    display: grid;
    grid-template-columns: auto;
    gap: 30px;
    grid-auto-rows: minmax(100px, auto);
  }
  .question-section-tabpane {
    padding: 5px;
  }
  .Back-two {
    display: block;
  }
  .mocktest-analysis-solution-button.Back-one {
    display: none;
  }
  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    flex: 1 1;
  }
}
@media (min-width: 641px) {
  /* .mocktest-analysis .ant-tabs-nav-list {
    margin: 0px 60px;
  } */
  /* .mocktest-analysis .ant-tabs-extra-content {
    flex: 2 1;
    width: 90%;
  } */
 .mocktest-analysis .ant-tabs-extra-content{
    flex: none !important;
  }
  .mocktest-analysis .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav {
    flex-direction: column-reverse;
  }
  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .mocktest-analysis .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: flex;
  }
  .mocktest-analysis .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 14px 0px;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    margin: 0px 30px !important;
    cursor: pointer;
  }
  .mocktest-analysis
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: #0b649d !important;
    font-size: 16px;
    font-weight: 600;
    padding: 0px 2px;
  }
  .mocktest-analysis .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 16px;
  }
  .mocktest-analysis-grid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px;
    grid-auto-rows: minmax(100px, auto);
  }
  .mocktest-tab-menu-body {
    padding: 0px 20px;
  }
  .question-section-tabpane {
    padding: 5px;
  }
  .Back-two {
    display: block;
  }
  .mocktest-analysis-solution-button.Back-one {
    display: none;
  }
  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    flex: 2 1;
  }
}
@media (min-width: 768px) {
  /* .mocktest-analysis .ant-tabs-nav-list {
    margin: 0px 20px;
  } */
  /* .mocktest-analysis .ant-tabs-extra-content {
    flex: 2 1;
  } */
 .mocktest-analysis .ant-tabs-extra-content{
    flex: none !important;
  }
  .mocktest-analysis .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav {
    flex-direction: column-reverse;
  }
  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .mocktest-analysis .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
  .mocktest-analysis-solution-button {
    width: 130px !important;
    margin-bottom: 15px;
  }

  .Back-two {
    display: block;
  }
  .mocktest-analysis-solution-button.Back-one {
    display: none;
  }

  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    margin-left: 30px;
  }

  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    flex: 2 1;
  }

  .mocktest-analysis .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 14px 0px;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    margin: 0px 15px !important;
    cursor: pointer;
  }
  .mocktest-analysis-grid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px;
    grid-auto-rows: minmax(100px, auto);
  }
  .mocktest-analysis
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: #0b649d !important;
    font-size: 16px;
    font-weight: 600;
    padding: 0px 2px;
  }
  .mocktest-analysis .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 16px;
  }
  .mocktest-tab-menu-body {
    padding: 0px 20px;
  }
  .question-section-tabpane {
    padding: 5px;
  }
}

@media (min-width: 1024px) {
  .Back-two {
    display: none;
  }
  .mocktest-analysis-solution-button.Back-one {
    display: none;
  }
  /* .mocktest-analysis .ant-tabs-nav-list {
    margin: 0px -16px;
  } */
  /* .mocktest-analysis .ant-tabs-extra-content {
    flex: 2 1;

  } */
   .mocktest-analysis .ant-tabs-extra-content{
    flex: none !important;
  }
  .mocktest-analysis-solution-button {
    width: 150px !important;
    margin-bottom: 15px;
  }
  .mocktest-analysis .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav {
    flex-direction: row;
  }
  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .mocktest-analysis .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: flex;
  }
  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    flex: 3 1;
  }

  .mocktest-analysis .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 14px 0px;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    margin: 0px 20px !important;
    cursor: pointer;
  }
  .mocktest-analysis-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
  }
  .mocktest-analysis
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: #0b649d !important;
    font-size: 18px;
    font-weight: 600;
    padding: 0px 2px;
  }
  .mocktest-analysis .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 18px;
  }
  .mocktest-tab-menu-body {
    padding: 0px 20px;
  }
  .question-section-tabpane {
    padding: 5px;
  }
}

@media (min-width: 1281px) {
  .Back-two {
    display: none;
  }
  .mocktest-analysis-solution-button.Back-one {
    display: block;
  }
  /* .mocktest-analysis .ant-tabs-nav-list {
    margin: 0px 45px;
  } */
  /* .mocktest-analysis .ant-tabs-extra-content {
    flex: 2 1;
  } */
   .mocktest-analysis .ant-tabs-extra-content{
    flex: none !important;
  }
  .mocktest-analysis .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav {
    flex-direction: row;
  }
  .mocktest-analysis .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 14px 0px;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    margin: 0px 45px !important;
    cursor: pointer;
  }
  .mocktest-analysis-grid {
    display: grid;
    grid-template-columns: 1.2fr 1.5fr 1.8fr 1.2fr 1.7fr;
    gap: 2px;
    grid-auto-rows: minmax(100px, auto);
  }
  .mocktest-analysis
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: #0b649d !important;
    font-size: 18px;
    font-weight: 600;
    padding: 0px 2px;
  }
  .mocktest-analysis .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 18px;
  }
  .mocktest-tab-menu-body {
    padding: 0px 50px;
  }
  .question-section-tabpane {
    padding: 8px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1366px) {
  .Back-two {
    display: none;
  }
  .mocktest-analysis-solution-button.Back-one {
    display: block;
  }
  /* .mocktest-analysis .ant-tabs-extra-content {
    flex: 1 1;
    margin-left: -343px;
  } */
  .mocktest-analysis .ant-tabs-extra-content{
    flex: none !important;
  }

  .mocktest-analysis-grid {
    display: grid;
    grid-template-columns: 1.2fr 1.5fr 1.8fr 1.2fr 1.7fr;
    gap: 19px;
    grid-auto-rows: minmax(100px, auto);
  }
}
@media (min-width: 1440px) {
  /* .mocktest-analysis .ant-tabs-nav-list {
    margin: 0px 65px;
  } */
  /* .mocktest-analysis .ant-tabs-extra-content {
    flex: 2 1;
    width: 75%;
  } */
  .mocktest-analysis .ant-tabs-extra-content{
    flex: none !important;
  }
  .mocktest-analysis .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav {
    flex-direction: row;
  }
  .mocktest-analysis-solution-button {
    width: 215px !important;
    margin-bottom: 15px;
  }

  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    flex: 3 1;
  }

  .mocktest-analysis .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 14px 0px;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    margin: 0px 45px !important;
    cursor: pointer;
  }
  .mocktest-analysis-grid {
    display: grid;
    grid-template-columns: 1.2fr 1.5fr 1.8fr 1.2fr 1.7fr;
    gap: 30px;
    grid-auto-rows: minmax(100px, auto);
  }
  .mocktest-analysis
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: #0b649d !important;
    font-size: 18px;
    font-weight: 600;
    padding: 0px 2px;
  }
  .mocktest-analysis .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 18px;
  }
  .mocktest-tab-menu-body {
    padding: 0px 50px;
  }
  .question-section-tabpane {
    padding: 6px;
  }
}

@media (min-width: 1900px) {
  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    flex: 4 1 2%;
  }
  .mocktest-analysis .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav {
    flex-direction: row;
  }
  /* .mocktest-analysis .ant-tabs-extra-content {
    flex: 2 1;
  } */
  .mocktest-analysis .ant-tabs-extra-content{
    flex: none !important;
  }
}

@media (min-width: 2560px) {
  /* .mocktest-analysis .ant-tabs-nav-list {
    margin: 0px 90px;
  } */
  .mocktest-analysis .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav {
    flex-direction: row;
  }
  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    flex: 5 1 6%;
  }
  /* .mocktest-analysis .ant-tabs-extra-content {
    flex: 2 1;
  } */
 .mocktest-analysis .ant-tabs-extra-content{
    flex: none !important;
  }
  .mocktest-analysis .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 14px 0px;
    font-size: 16px;
    background: transparent;
    border: 0;
    outline: none;
    margin: 0px 50px !important;
    cursor: pointer;
  }
  .mocktest-analysis-grid {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
    gap: 30px;
    grid-auto-rows: minmax(100px, auto);
  }
  .mocktest-analysis
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: #0b649d !important;
    font-size: 20px;
    font-weight: 600;
    padding: 0px 3px;
  }
  .mocktest-analysis .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 20px;
  }
  .mocktest-tab-menu-body {
    padding: 0px 50px;
  }
  .question-section-tabpane {
    padding: 8px;
  }
}
.mocktest-tab-menu-title::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0;
  margin-left: 100%;
  width: 0;
  height: 4%;
  border-bottom: 45px solid transparent;
  border-top: 0 solid transparent;
  border-left: 17px solid black;
  border-left-color: #2990cc;
  font-size: 0;
  line-height: 0;
}

.mocktest-analysis-grid-item-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.mocktest-analysis-grid-item-flex-count {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 12px;
}

/* Antd tabs */
.mocktest-analysis {
  padding: 1px 0px;
  /* background: #fff; */
  /* height: calc(90vh) !important; */
}

.mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  margin-left: 12px;
}

/* .mocktest-analysis .ant-tabs-extra-content {
  flex: 2 1;
} */
.mocktest-analysis .ant-tabs-nav-wrap {
  flex: 1;
}
.mocktest-analysis .ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  padding: 13px 0px 1px;
  background: #fff;
}
.mocktest-analysis .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  /* text-shadow: 0 0 0.25px currentColor; */
  padding: 0px 2px;
  font-weight: 600;
  color: #3c4852;
  /* section tab font color */
}

.mocktest-analysis-solution-button {
  font-weight: bold;
  border-radius: 3px !important;
  height: 50px !important;
  font-size: 14px;
  background: #0b649d;
  border: 0px solid;
  margin-bottom: 15px;
}

.mocktest-tab-menu-title {
  position: relative;
  background: linear-gradient(270deg, #2990cc 19.04%, #0b649d 96.14%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  bottom: 20px;
  right: 20px;
  padding: 10px 31px;
  font-size: 16px;
  font-weight: 900;
  color: #fff;
  width: calc(100vh - 70vh);
}

.question-section-tabpane {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mocktest-analysis .ant-table-thead > tr > th {
  position: relative;
  color: #687179;
  font-weight: 600;
  text-align: center;
  background: #eff1fa;
  border-bottom: 0px solid #f0f0f0;
  transition: background 0.3s ease;
}
.mocktest-analysis-TabPane-boby .ant-tabs-nav-wrap .ant-tabs-tab:first-child {
  margin: 0px 45px 0px 0px !important;
}
.ant-table-wrapper .ant-table-thead > tr > td {
  background: #eff1fa;
  /* colmn header missed color */
}

.mocktest-analysis .ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
}

.mocktest-analysis .ant-table table {
  width: 100%;
  text-align: center;
  border-radius: 2px 2px;
  border-collapse: separate;
  border-spacing: 0;
}
.mocktest-analysis-TabPane-boby {
  width: calc(100% - 15%);
  margin: 20px auto;
}

/* Tab menu */
.mocktest-section-analysis-tab .ant-tabs-nav-list {
  margin: 0px 0px;
}

.mocktest-section-analysis-tab .ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  padding: 0px 0 !important;
  background: #fff !important;
}

.mocktest-section-analysis-tab .ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 0px 0 !important;
}

.mocktest-section-analysis-tab .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: 4 1;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
  z-index: 0;
  margin-bottom: 8px !important;
}

.mocktest-section-analysis-tab
  .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #ffffff !important;
  font-size: 18px;
  font-weight: 600;
  padding: 0px 2px;
}

.mocktest-section-analysis-tab
  .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active {
  margin: 0px 0px;
  background: linear-gradient(270deg, #2990cc 19.04%, #0b649d 96.14%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  bottom: 0px;
  padding: 8px 50px;
  font-size: 16px;
  font-weight: 900;
  color: #fff !important;
}

.mocktest-section-analysis-tab
  .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0;
  margin-left: 100%;
  width: 0;
  height: 4%;
  border-bottom: 56px solid transparent;
  border-top: 0 solid transparent;
  border-left: 16px solid black;
  border-left-color: #2990cc;
  font-size: 2px;
  line-height: 0;
  z-index: 1;
}
.mocktest-section-analysis-tab .ant-tabs-tab-btn::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0;
  margin-left: 100%;
  width: 0;
  height: 4%;
  border-bottom: 56px solid transparent;
  border-top: 0 solid transparent;
  border-left: 16px solid black;
  border-left-color: #eef6ff;
  font-size: 2px;
  line-height: 0;
  z-index: 1;
}
.mocktest-section-analysis-tab .ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 10px 50px;
  font-size: 14px;
  color: #8c949a;
  background: #eef6ff;
  border: 0;
  outline: none;
  margin: 0px 0px !important;
  cursor: pointer;
}
.mocktest-section-analysis-tab .ant-tabs .ant-tabs-ink-bar {
  background: none;
}
/* radio tab menu */
.mocktest-section-analysis-tab .ant-radio-button-wrapper:first-child {
  border-inline-start: none;
  border-radius: 0px;
}

/* radio tab menu */
.mocktest-section-analysis-tab .ant-radio-button-wrapper:first-child {
  border-inline-start: none;
  border-radius: 0px;
}

.mocktest-section-analysis-tab .ant-radio-button.ant-radio-button-checked {
  background: linear-gradient(270deg, #2990cc 19.04%, #0b649d 96.14%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  color: #fff;
}

.mocktest-section-analysis-tab .ant-radio-button-wrapper:last-child {
  border-radius: 0px;
}

.mocktest-section-analysis-tab
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
}
.mocktest-section-analysis-tab .ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 45px;
  margin: 0;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  border-block-start-width: 1.02px;
  border-inline-end-width: 1px;
  cursor: pointer;
  transition: none;
  align-items: center;
  background: #eef6ff;
  border: 0;
  color: #8c949a;
  outline: none;
  padding: 10px 50px;
}

.mocktest-section-analysis-tab
  .ant-radio-group.ant-radio-group-outline
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0;
  margin-left: 100%;
  width: 0;
  border-bottom: 45px solid transparent;
  border-top: 0 solid transparent;
  border-left: 16px solid black;
  border-left-color: #2990cc;
  font-size: 2px;
  line-height: 0;
  z-index: 1;
}

.mocktest-section-analysis-tab
  .ant-radio-group.ant-radio-group-outline
  .ant-radio-button-wrapper::after {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  margin-left: 100%;
  width: 0;
  border-bottom: 45px solid transparent;
  border-top: 0 solid transparent;
  border-left: 16px solid black;
  border-left-color: #eef6ff;
  font-size: 2px;
  line-height: 0;
  z-index: 2;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #fff;
  content: "";
}

.mocktest-section-analysis-table {
  padding: 20px 0px;
  margin: auto;
  width: 100%;
  overflow-x: scroll;
}
.mocktest-analysis .ant-table-thead>tr>th{
  background-color:#eff1fa 
}
.mocktest-analysis .ant-table-wrapper:where(.css-dev-only-do-not-override-1r287do).ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >thead>tr>th{
  border: 1px solid #f0f0f0 !important;
  }
.mocktest-section-analysis-table .ant-table-wrapper .ant-table-cell,
.mocktest-section-analysis-table .ant-table-wrapper .ant-table-thead > tr > th,
.mocktest-section-analysis-table .ant-table-wrapper .ant-table-tbody > tr > th,
.mocktest-section-analysis-table .ant-table-wrapper .ant-table-tbody > tr > td,
.mocktest-section-analysis-table .ant-table-wrapper tfoot > tr > th,
.mocktest-section-analysis-table .ant-table-wrapper tfoot > tr > td {
  position: relative;
  padding: 10px 16px;
  overflow-wrap: break-word;
}

.mocktest-section-analysis-table
  .ant-table-wrapper
  .ant-table-tbody
  .ant-table-row
  > .ant-table-cell-row-hover {
  background-color: #dfe1e3 !important;
}

.mocktest-section-analysis-table::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.mocktest-section-analysis-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
.mocktest-section-analysis-table::-webkit-scrollbar-thumb {
  background: #e4e5e7;
  border-radius: 10px;
}

/* Handle on hover */
.mocktest-section-analysis-table::-webkit-scrollbar-thumb:hover {
  background: rgba(90, 114, 200, 0.04);
}

.mocktest-analysis .ant-tabs-extra-content .ant-flex {
  flex-direction: row-reverse;
}
.rate-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 13px;
  background-color: #f5f6fa;
  border-radius: 3px;
  margin-right: 3.5rem;
}
@media (max-width: 900px) {
  .rate-text {
    display: flex !important;
    flex-direction: column !important;
    padding: 0px 20px 0px 13px !important;
  }
}
@media (min-width: 1520px) and (max-width: 1920px) {
  .rate-text {
    margin-right: 50px;
  }
}
@media (min-width: 1366px) and (max-width: 1520px) {
  .rate-text {
    margin-right: 26px;
  }
}
@media (min-width: 1140px) and (max-width: 1366px) {
  .rate-text {
    margin-right: 20px;
  }
}
/* .mocktest-title{
  white-space: nowrap;

}
@media (max-width:1200px){

.mocktest-title-container{
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
 }
@media(min-width:991px) and (max-width:1024px){

.mocktest-title-container{
    width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}
}
@media(min-width:800px) and (max-width:991px){

.mocktest-title-container{
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

}
}
@media(min-width:768px) and (max-width:800px){

.mocktest-title-container{
    width: 40%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
} */
@media (min-width: 768px) and (max-width: 1200px) {
  .Iicon {
    padding-right: 0.5em;
  }
  .mocktest-analysis-TabPane-boby .ant-tabs-nav-wrap .ant-tabs-tab:first-child {
    margin: 0px 10px !important;
  }
  .mocktest-analysis .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  margin-left: 3px;
}
}
@media (min-width: 1020px) and (max-width: 1200px) {
  .mocktest-analysis-TabPane-boby .ant-tabs-extra-content {
    width: 40% !important;
  }
  .mocktest-analysis .ant-tabs-extra-content {
    flex: none !important;
  }
  .mocktest-analysis .ant-tabs-tab {
    margin: 0 10px !important;
  }
  .mocktest-analysis-TabPane-boby .ant-tabs-nav-wrap .ant-tabs-tab:first-child {
    margin: 0px 10px 0px 0px !important;
  }
}
@media (min-width: 1023px) and (max-width:1090px){
.mocktest-analysis .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav {
    flex-direction: column-reverse !important;
}
.Back-two {
  display: block;
}
.mocktest-analysis-TabPane-boby .ant-tabs-extra-content {
  width: 97% !important;
}
}
@media (min-width:1025px) and (max-width:1200px){
  .Back-one {
    display: block !important;
  }
  .Back-two {
    display: none;
  }
}
@media (min-width:768px) and (max-width:1024px){
  .mocktest-analysis .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap:before, .mocktest-analysis .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap:after {
    box-shadow: none !important;
}
}
@media (max-width:1024px){
  .mocktest-analysis .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations{
    display:none
  }
}