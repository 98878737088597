@media (min-width: 320px) {
  .ebook-detail-container {
    margin: auto;
    width: 100%;
    padding: 10px;
  }
  .ebook-detail-container .breadcrumb-container {
    margin: 0px;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb {
    margin: 0px;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb .item {
    cursor: pointer;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb .item .link {
    color: #0b649d;
    font-size: 12px;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb .active-item {
    color: #0b649d;
    font-weight: 400;
    font-size: 12px;
  }
  .ebook-detail-container .content {
    background: transparent;
    margin-top: 10px;
  }

  .ebook-detail-container .content .inner-content {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .header-card {
    margin: 0px;
    border: 0px solid;
    border-radius: 4px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    padding: 10px;
  }
  .ebook-detail-container .content .inner-content .header-card .content {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .back {
    margin: 0px;
    color: #0b649d;
    cursor: pointer;
  }
  .ebook-detail-container .content .inner-content .header-card .content .back .icon {
    margin: 0px;
    font-size: 12px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .back .text {
    margin-left: 10px;
    font-size: 12px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .main {
    margin: 0px;
    margin-top: 10px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .main .row {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .banner-column {
    margin: 0px;
    text-align: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .banner-column
    .image {
    margin: 0px;
    width: 200px;
    border: 1px solid #fff;
    padding: 5px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));
    border-radius: 7px;
    height: 280px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content {
    margin: 0px;
    height: 310px;
    max-height: 310px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .row {
    margin: 0px;
    padding: 5px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .row
    .text {
    margin: 0px;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    text-align: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content {
    margin: 0px;
    display: flex;
    align-items: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .image-container {
    margin: 0px;
    padding: 0px 10px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .image-container
    .image {
    margin: 0px;
    width: 20px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container
    .language {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container
    .language
    .language-text {
    margin: 0px;
    font-size: 14px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container
    .language
    .language-label {
    margin: 0px;
    color: #0b649d;
    font-size: 12px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content {
    margin: 0px;
    display: flex;
    align-items: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .image-container {
    margin: 0px;
    padding: 0px 10px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .image-container
    .image {
    margin: 0px;
    width: 20px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .date-container {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .date-container
    .date-text {
    margin: 0px;
    font-size: 14px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .date-container
    .date-label {
    margin: 0px;
    color: #0b649d;
    font-size: 12px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions {
    margin-top: 10px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row {
    margin: 0px;
    justify-content: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row
    .content {
    margin: 0px;
    background: #fffbe6;
    padding: 15px;
    border-radius: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row
    .content
    .action-span {
    margin: 0px;
    padding: 2px 10px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row
    .content
    .action-span
    .action-text {
    margin: 0px;
    font-weight: normal;
    padding: 0px 5px;
  }
  .ebook-detail-container .content .inner-content .get-card {
    margin-top: 20px;
    border: 0px solid;
    border-radius: 4px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }
  .ebook-detail-container .content .inner-content .get-card .row {
    margin: 0px;
    padding: 10px;
  }
  .ebook-detail-container .content .inner-content .get-card .row .image-column {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .get-card .row .image-column .image {
    margin: 0px;
    padding: 0px 10px;
    width: 40px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .get-card
    .row
    .image-column
    .title-span {
    margin: 0px;
    font-size: 12px;
    font-weight: bold;
  }
  .ebook-detail-container .content .inner-content .get-card .row .button-column {
    margin: 0px;
    text-align: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .get-card
    .row
    .button-column
    .button {
    margin: 0px;
    box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
    border-radius: 4px;
    height: 35px;
    width: auto;
  }
  .ebook-detail-container .content .inner-content .description-card {
    margin-top: 20px;
    border: 0px solid;
    border-radius: 4px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }
  .ebook-detail-container .content .inner-content .description-card .row {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .description-card .row .column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .description-card
    .row
    .column
    .content {
    margin: 0px;
    padding: 10px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .description-card
    .row
    .column
    .content
    .text {
    margin: 0px;
    white-space: pre-wrap;
  }
  
  .ebook-detail-container .main-layout {
    background: #fff;
    padding: 10px;
    margin-top: 10px;
  }
  .ebook-detail-container .main-layout .inner-layout {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span {
    margin: 0px;
    cursor: pointer;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span .icon {
    color: #0b649d;
    font-size: 12px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span .text {
    color: #0b649d;
    font-size: 14px;
    padding: 0px 10px;
  }
  .ebook-detail-container .main-layout .inner-layout .header {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title {
    margin: 0px;
    padding: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .text {
    color: #0B649D;
    font-size: 18px;
    font-weight: 600;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .text .author {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .author .text {
    color: #000;
    font-size: 12px;
    font-weight: normal;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .title
    .author
    .image-span {
    margin: 0px 10px;
    padding: 0px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .title
    .author
    .image-span
    .image {
    width: 15px;
    height: 15px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .content {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .content .row {
    margin: 0px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .unlike {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .like {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column
    .chat {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column
    .eye {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .share-column {
    margin: 0px;
    text-align: center;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .share-column
    .share {
    margin: 0px;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .date-column {
    margin-top: 10px;
    text-align: center;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .date-column
    .text {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .ebook-detail-container .main-layout .inner-layout .header .banner {
    margin-top: 15px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .banner .image {
    width: 100%;
    height: 100%;
  }
  .ebook-detail-container .main-layout .inner-layout .header .iframe-content {
    margin-top: 10px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .iframe-content
    #iframe {
    margin: 0px;
    border: 0px solid;
    width: 100%;
    height: 1000vh;
  }
}

@media (min-width: 481px) {
}

@media (min-width: 641px) {
  .ebook-detail-container {
    margin: auto;
    width: 100%;
    padding: 10px;
  }
  .ebook-detail-container .breadcrumb-container {
    margin: 0px;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb {
    margin: 0px;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb .item {
    cursor: pointer;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb .item .link {
    color: #0b649d;
    font-size: 12px;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb .active-item {
    color: #0b649d;
    font-weight: 400;
    font-size: 12px;
  }
  .ebook-detail-container .content {
    background: transparent;
    margin-top: 10px;
  }
  .ebook-detail-container .content .inner-content {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .header-card {
    margin: 0px;
    border: 0px solid;
    border-radius: 4px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    padding: 10px;
  }
  .ebook-detail-container .content .inner-content .header-card .content {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .back {
    margin: 0px;
    color: #0b649d;
    cursor: pointer;
  }
  .ebook-detail-container .content .inner-content .header-card .content .back .icon {
    margin: 0px;
    font-size: 12px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .back .text {
    margin-left: 10px;
    font-size: 12px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .main {
    margin: 0px;
    margin-top: 10px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .main .row {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .banner-column {
    margin: 0px;
    text-align: end;
    padding: 0px 20px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .banner-column
    .image {
    margin: 0px;
    width: 200px;
    border: 1px solid #fff;
    padding: 5px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));
    border-radius: 7px;
    height: 280px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content {
    margin: 0px;
    height: 230px;
    max-height: 230px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .row {
    margin: 0px;
    padding: 5px;
    justify-content: flex-start;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .row
    .text {
    margin: 0px;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    text-align: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row {
    margin: 0px 0px 0px 30px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content {
    margin: 0px;
    display: flex;
    align-items: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .image-container {
    margin: 0px;
    padding: 0px 10px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .image-container
    .image {
    margin: 0px;
    width: 20px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container
    .language {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container
    .language
    .language-text {
    margin: 0px;
    font-size: 14px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container
    .language
    .language-label {
    margin: 0px;
    color: #0b649d;
    font-size: 12px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content {
    margin: 0px;
    display: flex;
    align-items: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .image-container {
    margin: 0px;
    padding: 0px 10px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .image-container
    .image {
    margin: 0px;
    width: 20px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .date-container {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .date-container
    .date-text {
    margin: 0px;
    font-size: 14px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .date-container
    .date-label {
    margin: 0px;
    color: #0b649d;
    font-size: 12px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions {
    margin-top: 10px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row {
    margin: 0px;
    justify-content: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row
    .content {
    margin: 0px;
    background: #fffbe6;
    padding: 15px;
    border-radius: 90px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row
    .content
    .action-span {
    margin: 0px;
    padding: 2px 10px;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row
    .content
    .action-span
    .action-text {
    margin: 0px;
    font-weight: normal;
    padding: 0px 5px;
  }
  .ebook-detail-container .content .inner-content .get-card {
    margin-top: 20px;
    border: 0px solid;
    border-radius: 4px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }
  .ebook-detail-container .content .inner-content .get-card .row {
    margin: 0px;
    padding: 10px;
  }
  .ebook-detail-container .content .inner-content .get-card .row .image-column {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .get-card .row .image-column .image {
    margin: 0px;
    padding: 0px 10px;
    width: 100px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .get-card
    .row
    .image-column
    .title-span {
    margin: 0px;
    font-size: 12px;
    font-weight: bold;
  }
  .ebook-detail-container .content .inner-content .get-card .row .button-column {
    margin: 0px;
    text-align: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .get-card
    .row
    .button-column
    .button {
    margin: 0px;
    box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
    border-radius: 4px;
    height: 35px;
    width: auto;
  }
  .ebook-detail-container .content .inner-content .description-card {
    margin-top: 20px;
    border: 0px solid;
    border-radius: 4px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }
  .ebook-detail-container .content .inner-content .description-card .row {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .description-card .row .column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .description-card
    .row
    .column
    .content {
    margin: 0px;
    padding: 10px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .description-card
    .row
    .column
    .content
    .text {
    margin: 0px;
    white-space: pre-wrap;
  }
  
  .ebook-detail-container .main-layout {
    background: #fff;
    padding: 10px;
    margin-top: 10px;
  }
  .ebook-detail-container .main-layout .inner-layout {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span {
    margin: 0px;
    cursor: pointer;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span .icon {
    color: #0b649d;
    font-size: 12px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span .text {
    color: #0b649d;
    font-size: 14px;
    padding: 0px 10px;
  }
  .ebook-detail-container .main-layout .inner-layout .header {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title {
    margin: 0px;
    padding: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .text {
    color: #0B649D;
    font-size: 18px;
    font-weight: 600;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .text .author {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .author .text {
    color: #000;
    font-size: 12px;
    font-weight: normal;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .title
    .author
    .image-span {
    margin: 0px 10px;
    padding: 0px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .title
    .author
    .image-span
    .image {
    width: 15px;
    height: 15px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .content {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .content .row {
    margin: 0px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .unlike {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .like {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column
    .chat {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column
    .eye {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .share-column {
    margin: 0px;
    text-align: center;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .share-column
    .share {
    margin: 0px;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .date-column {
    margin-top: 10px;
    text-align: center;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .date-column
    .text {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .ebook-detail-container .main-layout .inner-layout .header .banner {
    margin-top: 15px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .banner .image {
    width: 100%;
    height: 100%;
  }
  .ebook-detail-container .main-layout .inner-layout .header .iframe-content {
    margin-top: 10px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .iframe-content
    #iframe {
    margin: 0px;
    border: 0px solid;
    width: 100%;
    height: 1000vh;
  }

  .ebook-detail-container .main-layout {
    background: #fff;
    padding: 10px;
    margin-top: 10px;
  }
  .ebook-detail-container .main-layout .inner-layout {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span {
    margin: 0px;
    cursor: pointer;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span .icon {
    color: #0b649d;
    font-size: 12px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span .text {
    color: #0b649d;
    font-size: 14px;
    padding: 0px 10px;
  }
  .ebook-detail-container .main-layout .inner-layout .header {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title {
    margin: 0px;
    padding: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .text {
    color: #0B649D;
    font-size: 18px;
    font-weight: 600;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .text .author {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .author .text {
    color: #000;
    font-size: 12px;
    font-weight: normal;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .title
    .author
    .image-span {
    margin: 0px 10px;
    padding: 0px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .title
    .author
    .image-span
    .image {
    width: 15px;
    height: 15px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .content {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .content .row {
    margin: 0px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .unlike {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .like {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column
    .chat {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column
    .eye {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .share-column {
    margin: 0px;
    text-align: center;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .share-column
    .share {
    margin: 0px;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .date-column {
    margin-top: 10px;
    text-align: center;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .date-column
    .text {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .ebook-detail-container .main-layout .inner-layout .header .banner {
    margin-top: 15px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .banner .image {
    width: 100%;
    height: 100%;
  }
  .ebook-detail-container .main-layout .inner-layout .header .iframe-content {
    margin-top: 10px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .iframe-content
    #iframe {
    margin: 0px;
    border: 0px solid;
    width: 100%;
    height: 1000vh;
  }

  .ebook-detail-container .main-layout {
    background: #fff;
    padding: 15px 60px;
    margin-top: 20px;
  }
  .ebook-detail-container .main-layout .inner-layout {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span {
    margin: 0px;
    cursor: pointer;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span .icon {
    color: #0b649d;
    font-size: 16px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span .text {
    color: #0b649d;
    font-size: 16px;
    padding: 0px 20px;
  }
  .ebook-detail-container .main-layout .inner-layout .header {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title {
    margin: 0px;
    padding: 20px 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .text {
    color: #0B649D;
    font-size: 28px;
    font-weight: 600;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .text .author {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .author .text {
    color: #000;
    font-size: 18px;
    font-weight: normal;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .title
    .author
    .image-span {
    margin: 0px 10px;
    padding: 0px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .title
    .author
    .image-span
    .image {
    width: 20px;
    height: 20px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .content {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .content .row {
    margin: 0px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .unlike {
    margin: 0px;
    width: 27px;
    height: 27px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .like {
    margin: 0px;
    width: 27px;
    height: 27px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column
    .chat {
    margin: 0px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column
    .eye {
    margin: 0px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .share-column {
    margin: 0px;
    text-align: center;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .share-column
    .share {
    margin: 0px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .date-column {
    margin: 0px;
    text-align: end;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .date-column
    .text {
    margin: 0px;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #000;
  }
  .ebook-detail-container .main-layout .inner-layout .header .banner {
    margin-top: 25px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .banner .image {
    width: 100%;
    height: 100%;
  }
  .ebook-detail-container .main-layout .inner-layout .header .iframe-content {
    margin-top: 10px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .iframe-content
    #iframe {
    margin: 0px;
    border: 0px solid;
    width: 100%;
    height: 1000vh;
  }

}

@media (min-width: 961px) {
  .ebook-detail-container {
    margin: auto;
    width: 80%;
    padding: 10px;
  }
  .ebook-detail-container .breadcrumb-container {
    margin: 0px;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb {
    margin: 0px;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb .item {
    cursor: pointer;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb .item .link {
    color: #0b649d;
    font-size: 12px;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb .active-item {
    color: #0b649d;
    font-weight: 400;
    font-size: 12px;
  }
  .ebook-detail-container .content {
    background: transparent;
    margin-top: 10px;
  }
  .ebook-detail-container .content .inner-content {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .header-card {
    margin: 0px;
    border: 0px solid;
    border-radius: 4px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    padding: 10px;
  }
  .ebook-detail-container .content .inner-content .header-card .content {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .back {
    margin: 0px;
    color: #0b649d;
    cursor: pointer;
  }
  .ebook-detail-container .content .inner-content .header-card .content .back .icon {
    margin: 0px;
    font-size: 12px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .back .text {
    margin-left: 10px;
    font-size: 12px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .main {
    margin: 0px;
    margin-top: 10px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .main .row {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .banner-column {
    margin: 0px;
    text-align: end;
    padding: 0px 20px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .banner-column
    .image {
    margin: 0px;
    width: 200px;
    border: 1px solid #fff;
    padding: 6px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));
    border-radius: 7px;
    height: 280px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content {
    margin: 0px;
    height: 230px;
    max-height: 230px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .row {
    margin: 0px;
    padding: 5px;
    justify-content: flex-start;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .row
    .text {
    margin: 0px;
    font-size: 19px;
    font-weight: bold;
    color: #000;
    text-align: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row {
    margin: 0px 0px 0px 14px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content {
    margin: 0px;
    display: flex;
    align-items: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .image-container {
    margin: 0px;
    padding: 0px 10px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .image-container
    .image {
    margin: 0px;
    width: 20px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container
    .language {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container
    .language
    .language-text {
    margin: 0px;
    font-size: 14px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container
    .language
    .language-label {
    margin: 0px;
    color: #0b649d;
    font-size: 12px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content {
    margin: 0px;
    display: flex;
    align-items: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .image-container {
    margin: 0px;
    padding: 0px 10px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .image-container
    .image {
    margin: 0px;
    width: 20px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .date-container {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .date-container
    .date-text {
    margin: 0px;
    font-size: 14px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .date-container
    .date-label {
    margin: 0px;
    color: #0b649d;
    font-size: 12px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions {
    margin-top: 10px;
    position: absolute;
    bottom: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row {
    margin: 0px;
    justify-content: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row
    .content {
    margin: 0px;
    background: #fffbe6;
    padding: 10px;
    border-radius: 90px;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row
    .content
    .action-span {
    margin: 0px;
    padding: 2px 10px;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row
    .content
    .action-span
    .action-text {
    margin: 0px;
    font-weight: normal;
    padding: 0px 5px;
  }
  .ebook-detail-container .content .inner-content .get-card {
    margin-top: 20px;
    border: 0px solid;
    border-radius: 4px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }
  .ebook-detail-container .content .inner-content .get-card .row {
    margin: 0px;
    padding: 10px;
  }
  .ebook-detail-container .content .inner-content .get-card .row .image-column {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .get-card .row .image-column .image {
    margin: 0px;
    padding: 0px 10px;
    width: 100px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .get-card
    .row
    .image-column
    .title-span {
    margin: 0px;
    font-size: 14px;
    font-weight: bold;
  }
  .ebook-detail-container .content .inner-content .get-card .row .button-column {
    margin: 0px;
    text-align: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .get-card
    .row
    .button-column
    .button {
    margin: 0px;
    box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
    border-radius: 4px;
    height: 35px;
    width: auto;
  }
  .ebook-detail-container .content .inner-content .description-card {
    margin-top: 20px;
    border: 0px solid;
    border-radius: 4px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }
  .ebook-detail-container .content .inner-content .description-card .row {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .description-card .row .column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .description-card
    .row
    .column
    .content {
    margin: 0px;
    padding: 10px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .description-card
    .row
    .column
    .content
    .text {
    margin: 0px;
    white-space: pre-wrap;
  }
  
    
  .ebook-detail-container .main-layout {
    background: #fff;
    padding: 10px;
    margin-top: 10px;
  }
  .ebook-detail-container .main-layout .inner-layout {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span {
    margin: 0px;
    cursor: pointer;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span .icon {
    color: #0b649d;
    font-size: 12px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span .text {
    color: #0b649d;
    font-size: 14px;
    padding: 0px 10px;
  }
  .ebook-detail-container .main-layout .inner-layout .header {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title {
    margin: 0px;
    padding: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .text {
    color: #0B649D;
    font-size: 18px;
    font-weight: 600;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .text .author {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .author .text {
    color: #000;
    font-size: 12px;
    font-weight: normal;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .title
    .author
    .image-span {
    margin: 0px 10px;
    padding: 0px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .title
    .author
    .image-span
    .image {
    width: 15px;
    height: 15px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .content {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .content .row {
    margin: 0px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .unlike {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .like {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column
    .chat {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column
    .eye {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .share-column {
    margin: 0px;
    text-align: center;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .share-column
    .share {
    margin: 0px;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .date-column {
    margin-top: 10px;
    text-align: center;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .date-column
    .text {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .ebook-detail-container .main-layout .inner-layout .header .banner {
    margin-top: 15px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .banner .image {
    width: 100%;
    height: 100%;
  }
  .ebook-detail-container .main-layout .inner-layout .header .iframe-content {
    margin-top: 10px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .iframe-content
    #iframe {
    margin: 0px;
    border: 0px solid;
    width: 100%;
    height: 1000vh;
  }

  .ebook-detail-container .main-layout {
    background: #fff;
    padding: 10px;
    margin-top: 10px;
  }
  .ebook-detail-container .main-layout .inner-layout {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span {
    margin: 0px;
    cursor: pointer;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span .icon {
    color: #0b649d;
    font-size: 12px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span .text {
    color: #0b649d;
    font-size: 14px;
    padding: 0px 10px;
  }
  .ebook-detail-container .main-layout .inner-layout .header {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title {
    margin: 0px;
    padding: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .text {
    color: #0B649D;
    font-size: 18px;
    font-weight: 600;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .text .author {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .author .text {
    color: #000;
    font-size: 12px;
    font-weight: normal;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .title
    .author
    .image-span {
    margin: 0px 10px;
    padding: 0px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .title
    .author
    .image-span
    .image {
    width: 15px;
    height: 15px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .content {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .content .row {
    margin: 0px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .unlike {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .like {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column
    .chat {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column
    .eye {
    margin: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .share-column {
    margin: 0px;
    text-align: center;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .share-column
    .share {
    margin: 0px;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .date-column {
    margin-top: 10px;
    text-align: center;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .date-column
    .text {
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .ebook-detail-container .main-layout .inner-layout .header .banner {
    margin-top: 15px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .banner .image {
    width: 100%;
    height: 100%;
  }
  .ebook-detail-container .main-layout .inner-layout .header .iframe-content {
    margin-top: 10px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .iframe-content
    #iframe {
    margin: 0px;
    border: 0px solid;
    width: 100%;
    height: 1000vh;
  }

  .ebook-detail-container .main-layout {
    background: #fff;
    padding: 15px 60px;
    margin-top: 20px;
  }
  .ebook-detail-container .main-layout .inner-layout {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span {
    margin: 0px;
    cursor: pointer;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span .icon {
    color: #0b649d;
    font-size: 16px;
  }
  .ebook-detail-container .main-layout .inner-layout .row .column .icon-span .text {
    color: #0b649d;
    font-size: 16px;
    padding: 0px 20px;
  }
  .ebook-detail-container .main-layout .inner-layout .header {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title {
    margin: 0px;
    padding: 20px 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .text {
    color: #0B649D;
    font-size: 28px;
    font-weight: 600;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .text .author {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .title .author .text {
    color: #000;
    font-size: 18px;
    font-weight: normal;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .title
    .author
    .image-span {
    margin: 0px 10px;
    padding: 0px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .title
    .author
    .image-span
    .image {
    width: 20px;
    height: 20px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .content {
    margin: 0px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .content .row {
    margin: 0px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .unlike {
    margin: 0px;
    width: 27px;
    height: 27px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .like {
    margin: 0px;
    width: 27px;
    height: 27px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .like-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column
    .chat {
    margin: 0px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .chat-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column {
    margin: 0px;
    text-align: start;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column
    .eye {
    margin: 0px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .view-column
    .value {
    margin: 0px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #0b649d;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .share-column {
    margin: 0px;
    text-align: center;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .share-column
    .share {
    margin: 0px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .date-column {
    margin: 0px;
    text-align: end;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .content
    .row
    .date-column
    .text {
    margin: 0px;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #000;
  }
  .ebook-detail-container .main-layout .inner-layout .header .banner {
    margin-top: 25px;
  }
  .ebook-detail-container .main-layout .inner-layout .header .banner .image {
    width: 100%;
    height: 100%;
  }
  .ebook-detail-container .main-layout .inner-layout .header .iframe-content {
    margin-top: 10px;
  }
  .ebook-detail-container
    .main-layout
    .inner-layout
    .header
    .iframe-content
    #iframe {
    margin: 0px;
    border: 0px solid;
    width: 100%;
    height: 1000vh;
  }

}

@media (min-width: 1025px) {
  .ebook-detail-container {
    margin: auto;
    min-height: 100vh;
    height: 100%;
    width: 90%;

  }
  .ebook-detail-container .breadcrumb-container {
    margin: 0px;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb {
    margin: 0px;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb .item {
    cursor: pointer;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb .item .link {
    color: #0b649d; font-size: 14px;
  }
  .ebook-detail-container .breadcrumb-container .breadcrumb .active-item {
    color: #0b649d;
    font-weight: 400;
    font-size: 14px;
  }
  .ebook-detail-container .content {
    background: transparent;
    margin-top: 20px;
  }
  .ebook-detail-container .content .inner-content {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .header-card {
    margin: 0px;
    border: 0px solid;
    border-radius: 4px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    padding: 15px 20px;
  }
  .ebook-detail-container .content .inner-content .header-card .content {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .back {
    margin: 0px;
    color: #0b649d;
    cursor: pointer;
  }
  .ebook-detail-container .content .inner-content .header-card .content .back .icon {
    margin: 0px;
    font-size: 16px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .back .text {
    margin-left: 10px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .main {
    margin: 0px;
    margin-top: 20px;
  }
  .ebook-detail-container .content .inner-content .header-card .content .main .row {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .banner-column {
    margin: 0px;
    text-align: end;
    padding: 0px 20px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .banner-column
    .image {
    margin: 0px;
    width: 200px;
    border: 1px solid #fff;
    padding: 5px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));
    border-radius: 7px;
    height: 280px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content {
    margin: 0px;
    height: 260px;
    max-height: 260px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .row {
    margin: 0px;
    padding: 5px;
    justify-content: flex-start;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .row
    .text {
    margin: 0px;
    font-size: 28px;
    font-weight: bold;
    color: #000;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content {
    margin: 0px;
    display: flex;
    align-items: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .image-container {
    margin: 0px;
    padding: 0px 10px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .image-container
    .image {
    margin: 0px;
    width: 30px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container
    .language {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container
    .language
    .language-text {
    margin: 0px;
    font-size: 18px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .language-column
    .content
    .text-container
    .language
    .language-label {
    margin: 0px;
    color: #0b649d;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content {
    margin: 0px;
    display: flex;
    align-items: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .image-container {
    margin: 0px;
    padding: 0px 10px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .image-container
    .image {
    margin: 0px;
    width: 30px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .date-container {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .date-container
    .date-text {
    margin: 0px;
    font-size: 18px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .info
    .row
    .date-column
    .content
    .date-container
    .date-label {
    margin: 0px;
    color: #0b649d;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions {
    margin: 0px;
    position: absolute;
    bottom: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row {
    margin: 0px;
    justify-content: flex-start;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row
    .content {
    margin: 0px;
    background: #fffbe6;
    padding: 15px;
    border-radius: 90px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row
    .content
    .action-span {
    margin: 0px;
    padding: 0px 30px;
    font-size: 14px;
    font-weight: bold;
    color: #0b649d;
  }
  .ebook-detail-container
    .content
    .inner-content
    .header-card
    .content
    .main
    .row
    .content-column
    .title-content
    .actions
    .row
    .content
    .action-span
    .action-text {
    margin: 0px;
    font-weight: normal;
    padding: 0px 5px;
  }
  .ebook-detail-container .content .inner-content .get-card {
    margin-top: 20px;
    border: 0px solid;
    border-radius: 4px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }
  .ebook-detail-container .content .inner-content .get-card .row {
    margin: 0px;
    padding: 10px;
  }
  .ebook-detail-container .content .inner-content .get-card .row .image-column {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .get-card .row .image-column .image {
    margin: 0px;
    padding: 0px 20px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .get-card
    .row
    .image-column
    .title-span {
    margin: 0px;
    font-size: 16px;
    font-weight: bold;
  }
  .ebook-detail-container .content .inner-content .get-card .row .button-column {
    margin: 0px;
    text-align: center;
  }
  .ebook-detail-container
    .content
    .inner-content
    .get-card
    .row
    .button-column
    .button {
    margin: 0px;
    box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
    border-radius: 4px;
    height: 40px;
    width: 150px;
  }
  .ebook-detail-container .content .inner-content .description-card {
    margin-top: 20px;
    border: 0px solid;
    border-radius: 4px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }
  .ebook-detail-container .content .inner-content .description-card .row {
    margin: 0px;
  }
  .ebook-detail-container .content .inner-content .description-card .row .column {
    margin: 0px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .description-card
    .row
    .column
    .content {
    margin: 0px;
    padding: 25px;
  }
  .ebook-detail-container
    .content
    .inner-content
    .description-card
    .row
    .column
    .content
    .text {
    margin: 0px;
    white-space: pre-wrap;
  }

  
.ebook-detail-container .main-layout {
  background: #fff;
  padding: 10px;
  margin-top: 10px;
}
.ebook-detail-container .main-layout .inner-layout {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .row {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .row .column {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .row .column {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .row .column .icon-span {
  margin: 0px;
  cursor: pointer;
}
.ebook-detail-container .main-layout .inner-layout .row .column .icon-span .icon {
  color: #0b649d;
  font-size: 12px;
}
.ebook-detail-container .main-layout .inner-layout .row .column .icon-span .text {
  color: #0b649d;
  font-size: 14px;
  padding: 0px 10px;
}
.ebook-detail-container .main-layout .inner-layout .header {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .header .title {
  margin: 0px;
  padding: 0px;
}
.ebook-detail-container .main-layout .inner-layout .header .title .text {
  color: #0B649D;
  font-size: 18px;
  font-weight: 600;
}
.ebook-detail-container .main-layout .inner-layout .header .title .text .author {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .header .title .author .text {
  color: #000;
  font-size: 12px;
  font-weight: normal;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .title
  .author
  .image-span {
  margin: 0px 10px;
  padding: 0px;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .title
  .author
  .image-span
  .image {
  width: 15px;
  height: 15px;
}
.ebook-detail-container .main-layout .inner-layout .header .content {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .header .content .row {
  margin: 0px;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .like-column {
  margin: 0px;
  text-align: start;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .like-column
  .unlike {
  margin: 0px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .like-column
  .like {
  margin: 0px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .like-column
  .value {
  margin: 0px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #0b649d;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .chat-column {
  margin: 0px;
  text-align: start;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .chat-column
  .chat {
  margin: 0px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .chat-column
  .value {
  margin: 0px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #0b649d;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .view-column {
  margin: 0px;
  text-align: start;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .view-column
  .eye {
  margin: 0px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .view-column
  .value {
  margin: 0px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #0b649d;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .share-column {
  margin: 0px;
  text-align: center;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .share-column
  .share {
  margin: 0px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .date-column {
  margin-top: 10px;
  text-align: center;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .date-column
  .text {
  margin: 0px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.ebook-detail-container .main-layout .inner-layout .header .banner {
  margin-top: 15px;
}
.ebook-detail-container .main-layout .inner-layout .header .banner .image {
  width: 100%;
  height: 100%;
}
.ebook-detail-container .main-layout .inner-layout .header .iframe-content {
  margin-top: 10px;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .iframe-content
  #iframe {
  margin: 0px;
  border: 0px solid;
  width: 100%;
  height: 1000vh;
}

.ebook-detail-container .main-layout {
  background: #fff;
  padding: 10px;
  margin-top: 10px;
}
.ebook-detail-container .main-layout .inner-layout {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .row {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .row .column {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .row .column {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .row .column .icon-span {
  margin: 0px;
  cursor: pointer;
}
.ebook-detail-container .main-layout .inner-layout .row .column .icon-span .icon {
  color: #0b649d;
  font-size: 12px;
}
.ebook-detail-container .main-layout .inner-layout .row .column .icon-span .text {
  color: #0b649d;
  font-size: 14px;
  padding: 0px 10px;
}
.ebook-detail-container .main-layout .inner-layout .header {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .header .title {
  margin: 0px;
  padding: 0px;
}
.ebook-detail-container .main-layout .inner-layout .header .title .text {
  color: #0B649D;
  font-size: 18px;
  font-weight: 600;
}
.ebook-detail-container .main-layout .inner-layout .header .title .text .author {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .header .title .author .text {
  color: #000;
  font-size: 12px;
  font-weight: normal;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .title
  .author
  .image-span {
  margin: 0px 10px;
  padding: 0px;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .title
  .author
  .image-span
  .image {
  width: 15px;
  height: 15px;
}
.ebook-detail-container .main-layout .inner-layout .header .content {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .header .content .row {
  margin: 0px;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .like-column {
  margin: 0px;
  text-align: start;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .like-column
  .unlike {
  margin: 0px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .like-column
  .like {
  margin: 0px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .like-column
  .value {
  margin: 0px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #0b649d;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .chat-column {
  margin: 0px;
  text-align: start;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .chat-column
  .chat {
  margin: 0px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .chat-column
  .value {
  margin: 0px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #0b649d;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .view-column {
  margin: 0px;
  text-align: start;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .view-column
  .eye {
  margin: 0px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .view-column
  .value {
  margin: 0px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #0b649d;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .share-column {
  margin: 0px;
  text-align: center;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .share-column
  .share {
  margin: 0px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .date-column {
  margin-top: 10px;
  text-align: center;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .date-column
  .text {
  margin: 0px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.ebook-detail-container .main-layout .inner-layout .header .banner {
  margin-top: 15px;
}
.ebook-detail-container .main-layout .inner-layout .header .banner .image {
  width: 100%;
  height: 100%;
}
.ebook-detail-container .main-layout .inner-layout .header .iframe-content {
  margin-top: 10px;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .iframe-content
  #iframe {
  margin: 0px;
  border: 0px solid;
  width: 100%;
  height: 1000vh;
}

.ebook-detail-container .main-layout {
  background: #fff;
  padding: 15px 60px;
  margin-top: 20px;
}
.ebook-detail-container .main-layout .inner-layout {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .row {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .row .column {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .row .column {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .row .column .icon-span {
  margin: 0px;
  cursor: pointer;
}
.ebook-detail-container .main-layout .inner-layout .row .column .icon-span .icon {
  color: #0b649d;
  font-size: 16px;
}
.ebook-detail-container .main-layout .inner-layout .row .column .icon-span .text {
  color: #0b649d;
  font-size: 16px;
  padding: 0px 20px;
}
.ebook-detail-container .main-layout .inner-layout .header {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .header .title {
  margin: 0px;
  padding: 20px 0px;
}
.ebook-detail-container .main-layout .inner-layout .header .title .text {
  color: #0B649D;
  font-size: 28px;
  font-weight: 600;
}
.ebook-detail-container .main-layout .inner-layout .header .title .text .author {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .header .title .author .text {
  color: #000;
  font-size: 18px;
  font-weight: normal;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .title
  .author
  .image-span {
  margin: 0px 10px;
  padding: 0px;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .title
  .author
  .image-span
  .image {
  width: 20px;
  height: 20px;
}
.ebook-detail-container .main-layout .inner-layout .header .content {
  margin: 0px;
}
.ebook-detail-container .main-layout .inner-layout .header .content .row {
  margin: 0px;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .like-column {
  margin: 0px;
  text-align: start;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .like-column
  .unlike {
  margin: 0px;
  width: 27px;
  height: 27px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .like-column
  .like {
  margin: 0px;
  width: 27px;
  height: 27px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .like-column
  .value {
  margin: 0px;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #0b649d;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .chat-column {
  margin: 0px;
  text-align: start;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .chat-column
  .chat {
  margin: 0px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .chat-column
  .value {
  margin: 0px;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #0b649d;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .view-column {
  margin: 0px;
  text-align: start;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .view-column
  .eye {
  margin: 0px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .view-column
  .value {
  margin: 0px;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #0b649d;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .share-column {
  margin: 0px;
  text-align: center;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .share-column
  .share {
  margin: 0px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .date-column {
  margin: 0px;
  text-align: end;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .content
  .row
  .date-column
  .text {
  margin: 0px;
  padding: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.ebook-detail-container .main-layout .inner-layout .header .banner {
  margin-top: 25px;
}
.ebook-detail-container .main-layout .inner-layout .header .banner .image {
  width: 100%;
  height: 100%;
}
.ebook-detail-container .main-layout .inner-layout .header .iframe-content {
  margin-top: 10px;
}
.ebook-detail-container
  .main-layout
  .inner-layout
  .header
  .iframe-content
  #iframe {
  margin: 0px;
  border: 0px solid;
  width: 100%;
  height: 1000vh;
}

}

@media (min-width: 1281px) {
}
