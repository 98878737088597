@media (min-width: 425px) {
  .coins-container {
    margin: 10px;
  }
  .coins-ads-container {
    background: linear-gradient(180deg, #e0f3ff 0%, #e0f3ff 100%);
    padding: 30px 10px;
    margin-top: 10px;
  }
  .coins-ads-inner-content {
    text-align: center;
    padding: 10px 0px;
  }
  .coins-ads-google-pay-button {
    color: #0b649d;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #0b649d;
    width: 160px;
    height: 36px;
  }
  .coin-ads-text-content {
    text-align: center;
    padding: 10px 0px;
  }
  .coin-ads-link {
    color: #0b649d;
    font-size: 12px;
  }
  .coins-ads-box {
    margin-top: 25px;
  }
  .coins-ads-image {
    width: 100%;
  }
  .coins-ads-list-content {
    margin-top: 25px;
    border-top: 5px solid #0b649d;
  }
  .coins-ads-block {
    display: none;
  }
  .coins-ads-playstore-icon-1 {
    width: 30px;
  }
  .coins-ads-list {
    background: #fff;
  }
  .coins-ads-list-icon {
    color: #0b649d;
  }
  .coins-ads-list-title {
    margin-left: 20px;
    color: #0b649d;
  }
  .coin-inner-container {
    background: linear-gradient(180deg, #ffffff 37.81%, #e0f3ff 100%);
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    margin: auto;
    width: 300px;
    padding: 40px 0px 10px 0px;
  }
  .my-coins {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
    top: -60px;
    border-radius: 90px;
    padding: 10px;
    z-index: 1;
    text-align: center;
    margin-bottom: 20px;
  }
  .coine-header {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 45px;
    color: #0b649d;
    padding: 10px;
    text-align: center;
  }
  .coins-row {
    margin: 0px;
  }
  .coin-balance-container {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    padding: 9px 20px;
  }
  .coin-balance-inner {
    padding: 12px 34px;
    background: #ffffff;
    border: 1px solid rgba(90, 114, 200, 0.1);
    box-shadow: 0px 4px 4px rgba(90, 114, 200, 0.25);
    border-radius: 12px;
    white-space: nowrap;
  }
  .inner-balance {
    font-size: 14px;
    font-weight: 400;
  }
  .coine-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 45px;
    color: #0b649d;
    padding: 16px 0;
  }
  .coins-conatainer {
    height: 100px;
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
  }
  .coin-container {
    width: 300px;
    margin: auto;
  }
  .coin-container-flex {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 20px 30px;
    align-items: center;
  }
  .coin-card {
    height: 129.85px;
    left: 391px;
    right: 516px;
    top: 677.87px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    margin: 12px 0;
  }
  .cards-heading {
    font-family: "InterNormal";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 44px;
  }
  .cards-link {
    font-family: "InterNormal";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 35px;
    color: #f9873c;
  }
  .earn-coins {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 15px;
    align-items: center;
    height: 110px;
  }
  .card-text {
    font-family: "InterNormal";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    width: 100%;
    color: #000000;
  }
  .use-coins {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 20px 10px;
    align-items: center;
  }
  .coin-img {
    height: 100px;
  }
  .coin-img-column {
    padding: 5px;
  }
  .use-coin-img {
    padding: 15px 30px;
  }
}

@media (min-width: 641px) {
  .coins-container {
    margin: 40px 50px;
  }
  .coins-ads-container {
    background: linear-gradient(180deg, #e0f3ff 0%, #e0f3ff 100%);
    padding: 30px 10px;
    margin-top: 20px;
  }
  .coins-ads-inner-content {
    text-align: center;
    padding: 10px 0px;
  }
  .coins-ads-google-pay-button {
    color: #0b649d;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #0b649d;
    width: 160px;
    height: 36px;
  }
  .coin-ads-text-content {
    text-align: center;
    padding: 10px 0px;
  }
  .coin-ads-link {
    color: #0b649d;
    font-size: 12px;
  }
  .coins-ads-box {
    margin-top: 25px;
  }
  .coins-ads-image {
    width: 100%;
  }
  .coins-ads-list-content {
    margin-top: 25px;
    border-top: 5px solid #0b649d;
  }
  .coins-ads-playstore-icon-1 {
    width: 30px;
  }
  .coins-ads-list {
    background: #fff;
  }
  .coins-ads-list-icon {
    color: #0b649d;
  }
  .coins-ads-list-title {
    margin-left: 20px;
    color: #0b649d;
  }
  .coin-inner-container {
    background: linear-gradient(180deg, #ffffff 37.81%, #e0f3ff 100%);
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    margin: auto;
    width: 400px;
    padding: 60px 0px 10px 0px;
  }
  .my-coins {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
    top: -60px;
    border-radius: 90px;
    padding: 8px;
    z-index: 1;
    text-align: center;
    margin-bottom: 20px;
  }
  .coine-header {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 50px;
    color: #0b649d;
    padding: 15px;
    text-align: center;
  }
  .coin-balance-container {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    padding: 9px 20px;
  }
  .coins-row {
    margin: 10px 0px;
  }
  .coin-balance-inner {
    padding: 10px;
    background: #ffffff;
    border: 1px solid rgba(90, 114, 200, 0.1);
    box-shadow: 0px 4px 4px rgba(90, 114, 200, 0.25);
    border-radius: 12px;
    white-space: nowrap;
  }
  .inner-balance {
    font-size: 16px;
    font-weight: 400;
  }
  .coine-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 45px;
    color: #0b649d;
    padding: 14px 0;
  }
  .coins-conatainer {
    height: 100px;
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
  }
  .coin-container {
    width: 400px;
    margin: auto;
  }
  .coin-container-flex {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 20px 30px;
    align-items: center;
  }
  .coin-card {
    height: 129.85px;
    left: 391px;
    right: 516px;
    top: 677.87px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    margin: 12px 0;
  }
  .cards-heading {
    font-size: 16px;
    line-height: 25px;
  }
  .cards-link {
    font-size: 16px;
    line-height: 40px;
    color: #f9873c;
  }
  .earn-coins {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 20px;
    align-items: center;
    height: 140px;
  }

  .card-text {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    width: 100%;
    color: #000000;
  }

  .use-coins {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 20px 25px;
    align-items: center;
  }
  .coin-img {
    height: 100px;
  }
  .coin-img-column {
    padding: 15px 25px;
  }
  .use-coin-img {
    padding: 15px 30px;
  }
}

@media (min-width: 768px) {
  .coins-container {
    margin: 40px 50px;
  }
  .coins-ads-block {
    display: none;
  }
  .coins-ads-container {
    background: linear-gradient(180deg, #e0f3ff 0%, #e0f3ff 100%);
    padding: 30px 10px;
    margin-top: 15px;
  }
  .coins-ads-inner-content {
    text-align: center;
    padding: 10px 0px;
  }
  .coins-ads-google-pay-button {
    color: #0b649d;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #0b649d;
    width: 163px;
    height: 36px;
  }
  .coin-ads-text-content {
    text-align: center;
    padding: 10px 0px;
  }
  .coin-ads-link {
    color: #0b649d;
    font-size: 12px;
  }
  .coins-ads-box {
    margin-top: 25px;
  }
  .coins-ads-image {
    width: 100%;
  }
  .coins-ads-list-content {
    margin-top: 25px;
    border-top: 5px solid #0b649d;
  }
  .coins-ads-playstore-icon-1 {
    width: 30px;
  }
  .coins-ads-list {
    background: #fff;
  }
  .coins-ads-list-icon {
    color: #0b649d;
  }
  .coins-ads-list-title {
    margin-left: 20px;
    color: #0b649d;
  }
  .coin-inner-container {
    background: linear-gradient(180deg, #ffffff 37.81%, #e0f3ff 100%);
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    margin: auto;
    width: 400px;
    padding: 50px 0px 10px 0px;
  }
  .my-coins {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
    top: -60px;
    border-radius: 90px;
    padding: 10px;
    z-index: 1;
    text-align: center;
    margin-bottom: 20px;
  }
  .coine-header {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 50px;
    color: #0b649d;
    padding: 15px;
    text-align: center;
  }
  .coin-balance-container {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    padding: 9px 20px;
  }
  .coin-balance-inner {
    padding: 12px 20px;
    background: #ffffff;
    border: 1px solid rgba(90, 114, 200, 0.1);
    box-shadow: 0px 4px 4px rgba(90, 114, 200, 0.25);
    border-radius: 12px;
    white-space: nowrap;
  }
  .inner-balance {
    font-size: 14px;
    font-weight: 400;
  }
  .coine-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 45px;
    color: #0b649d;
    padding: 16px 0;
  }
  .coins-conatainer {
    height: 100px;
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
  }
  .coin-container {
    width: 400px;
    margin: auto;
  }
  .coin-container-flex {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 20px 30px;
    align-items: center;
  }
  .coin-card {
    height: 129.85px;
    left: 391px;
    right: 516px;
    top: 677.87px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    margin: 12px 0;
  }
  .cards-heading {
    font-size: 18px;
    line-height: 44px;
  }
  .cards-link {
    font-size: 14px;
    line-height: 35px;
    color: #f9873c;
  }
  .earn-coins {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 25px;
    align-items: center;
    height: 110px;
  }
  .card-text {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    width: 320px;
    color: #000000;
  }
  .use-coins {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 20px 10px;
    align-items: center;
  }
  .coin-img {
    height: 100px;
  }
  .coins-row {
    margin: 10px 8px;
  }
  .coin-img-column {
    padding: 15px 25px;
  }
  .coins-below-container {
    margin: auto;
  }
}

@media (min-width: 1024px) {
  .coins-container {
    margin: 40px 50px;
  }
  .coins-ads-container {
    background: linear-gradient(180deg, #e0f3ff 0%, #e0f3ff 100%);
    padding: 30px 10px;
    margin-top: 0px;
  }
  .coins-ads-inner-content {
    text-align: center;
    padding: 10px 0px;
  }
  .coins-ads-google-pay-button {
    color: #0b649d;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #0b649d;
    width: 163px;
    height: 40px;
  }
  .coin-ads-text-content {
    text-align: center;
    padding: 10px 0px;
  }
  .coin-ads-link {
    color: #0b649d;
    font-size: 12px;
  }
  .coins-ads-box {
    margin-top: 25px;
  }
  .coins-ads-image {
    width: 100%;
  }
  .coins-ads-list-content {
    margin-top: 25px;
    border-top: 5px solid #0b649d;
  }
  .coins-ads-playstore-icon-1 {
    width: 30px;
  }

  .coins-ads-list {
    background: #fff;
  }
  .coins-ads-list-icon {
    color: #0b649d;
  }
  .coins-ads-list-title {
    margin-left: 20px;
    color: #0b649d;
  }

  /* Card */

  .coin-inner-container {
    background: linear-gradient(180deg, #ffffff 37.81%, #e0f3ff 100%);
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    margin: auto;
    width: 450px;
    padding: 60px 0px 15px 0px;
  }

  .my-coins {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
    top: -60px;
    border-radius: 90px;
    padding: 10px;
    z-index: 1;
    text-align: center;
    margin-bottom: 20px;
  }

  .coine-header {
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 55px;
    color: #0b649d;
    padding: 15px;
    text-align: center;
  }

  .coin-balance-container {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    padding: 9px 20px;
  }

  .coin-balance-inner {
    padding: 12px 34px;
    background: #ffffff;
    border: 1px solid rgba(90, 114, 200, 0.1);
    box-shadow: 0px 4px 4px rgba(90, 114, 200, 0.25);
    border-radius: 12px;
    white-space: nowrap;
  }

  .inner-balance {
    font-size: 16px;
    font-weight: 400;
  }

  .coine-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 45px;
    color: #0b649d;
    padding: 16px 0;
  }

  .coins-conatainer {
    height: 100px;
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
  }

  .coin-container {
    width: 450px;
    margin: auto;
  }
  .coin-container-flex {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 20px 30px;
    align-items: center;
  }
  .coin-card {
    height: 129.85px;
    left: 391px;
    right: 516px;
    top: 677.87px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    margin: 12px 0;
  }

  .cards-heading {
    font-size: 18px;
    line-height: 44px;
  }

  .cards-link {
    font-size: 16px;
    line-height: 40px;
    color: #f9873c;
  }

  .earn-coins {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 25px 30px;
    align-items: center;
    height: 140px;
  }

  .card-text {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    width: 350px;
    color: #000000;
  }

  .use-coins {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 25px 30px;
    align-items: center;
  }
  .coin-img {
    height: 100px;
  }
  .coins-row {
    margin: 10px 22px;
  }
  .coin-img-column {
    padding: 15px 30px;
  }
  .coins-below-container {
    margin: 0;
  }
}

@media (min-width: 1281px) {
  .coins-container {
    margin: 40px 50px;
  }
  .coins-ads-container {
    background: linear-gradient(180deg, #e0f3ff 0%, #e0f3ff 100%);
    padding: 30px 10px;
  }
  .coins-ads-inner-content {
    text-align: center;
    padding: 10px 0px;
  }
  .coins-ads-google-pay-button {
    color: #0b649d;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #0b649d;
    width: 163px;
    height: 36px;
  }
  .coin-ads-text-content {
    text-align: center;
    padding: 10px 0px;
  }
  .coin-ads-link {
    color: #0b649d;
    font-size: 12px;
  }
  .coins-ads-box {
    margin-top: 25px;
  }
  .coins-ads-image {
    width: 100%;
  }
  .coins-ads-list-content {
    margin-top: 25px;
    border-top: 5px solid #0b649d;
  }
  .coins-ads-block {
    padding: 0px !important;
  }
  .coins-ads-playstore-icon-1 {
    width: 30px;
  }

  .coins-ads-list {
    background: #fff;
  }
  .coins-ads-list-icon {
    color: #0b649d;
  }
  .coins-ads-list-title {
    margin-left: 20px;
    color: #0b649d;
  }

  /* Card */

  .coin-inner-container {
    background: linear-gradient(180deg, #ffffff 37.81%, #e0f3ff 100%);
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    margin: auto;
    width: 640px;
    padding: 60px 0px 15px 0px;
  }
  .my-coins {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
    top: -60px;
    border-radius: 90px;
    padding: 10px;
    z-index: 1;
    text-align: center;
    margin-bottom: 20px;
  }
  .coine-header {
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 55px;
    color: #0b649d;
    padding: 15px;
    text-align: center;
  }
  .coin-balance-container {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    padding: 9px 20px;
  }
  .coin-balance-inner {
    padding: 12px 34px;
    background: #ffffff;
    border: 1px solid rgba(90, 114, 200, 0.1);
    box-shadow: 0px 4px 4px rgba(90, 114, 200, 0.25);
    border-radius: 12px;
    white-space: nowrap;
  }
  .inner-balance {
    font-size: 16px;
    font-weight: 400;
  }
  .coine-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 45px;
    color: #0b649d;
    padding: 16px 0;
  }
  .coins-conatainer {
    height: 100px;
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
  }
  .coin-container {
    width: 640px;
    margin: auto;
  }
  .coin-container-flex {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 20px 30px;
    align-items: center;
  }
  .coin-card {
    height: 129.85px;
    left: 391px;
    right: 516px;
    top: 677.87px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    margin: 12px 0;
  }

  .cards-heading {
    font-size: 18px;
    line-height: 44px;
  }

  .cards-link {
    font-size: 16px;
    line-height: 40px;
    color: #f9873c;
  }

  .earn-coins {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 25px 30px;
    align-items: center;
    height: 140px;
  }

  .card-text {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    width: 350px;
    color: #000000;
  }

  .use-coins {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 25px 30px;
    align-items: center;
  }

  .coins-row {
    margin: 10px 90px;
  }

  .coin-img-column {
    padding: 15px 30px;
  }
  .use-coin-img {
    padding: 15px 30px;
  }
  .coins-below-container {
    margin: 0;
  }
}

@media (min-width: 1440px) {
  .coins-container {
    margin: 40px 50px;
  }
  .coins-ads-container {
    background: linear-gradient(180deg, #e0f3ff 0%, #e0f3ff 100%);
    padding: 30px 10px;
  }
  .coins-ads-inner-content {
    text-align: center;
    padding: 10px 0px;
  }
  .coins-ads-google-pay-button {
    color: #0b649d;
    font-style: normal;
    color: #0b649d;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #0b649d;
    width: 163px;
    height: 40px;
    padding: 8px 15px;
  }
  .coin-ads-text-content {
    text-align: center;
    padding: 10px 0px;
  }
  .coin-ads-link {
    color: #0b649d;
    font-size: 12px;
  }
  .coins-ads-box {
    margin-top: 25px;
  }
  .coins-ads-image {
    width: 100%;
  }
  .coins-ads-list-content {
    margin-top: 25px;
    border-top: 5px solid #0b649d;
  }
  .coins-ads-playstore-icon-1 {
    width: 30px;
  }
  .coins-ads-list {
    background: #fff;
  }
  .coins-ads-list-icon {
    color: #0b649d;
  }
  .coins-ads-list-title {
    margin-left: 20px;
    color: #0b649d;
  }

  /* Card */

  .coin-inner-container {
    background: linear-gradient(180deg, #ffffff 37.81%, #e0f3ff 100%);
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    margin: auto;
    width: 640px;
    padding: 60px 0px 15px 0px;
  }

  .my-coins {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
    top: -60px;
    border-radius: 90px;
    padding: 10px;
    z-index: 1;
    text-align: center;
    margin-bottom: 20px;
  }

  .coine-header {
    font-size: 35px;
    line-height: 55px;
    color: #0b649d;
    padding: 15px;
    text-align: center;
  }

  .coin-balance-container {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    padding: 9px 20px;
    text-align: center;
  }

  .coin-balance-inner {
    padding: 12px 34px;
    background: #ffffff;
    border: 1px solid rgba(90, 114, 200, 0.1);
    box-shadow: 0px 4px 4px rgba(90, 114, 200, 0.25);
    border-radius: 12px;
    white-space: nowrap;
  }

  .inner-balance {
    font-size: 16px;
    font-weight: 400;
  }

  .coine-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 45px;
    color: #0b649d;
    padding: 16px 0;
  }

  .coins-conatainer {
    height: 100px;
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
  }

  .coin-container {
    width: 640px;
    margin: auto;
  }
  .coin-container-flex {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 20px 30px;
    align-items: center;
  }
  .coin-card {
    height: 129.85px;
    left: 391px;
    right: 516px;
    top: 677.87px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    margin: 12px 0;
  }

  .cards-heading {
    font-size: 18px;
    line-height: 44px;
  }

  .cards-link {
    font-size: 16px;
    line-height: 40px;
    color: #f9873c;
  }

  .earn-coins {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 25px 30px;
    align-items: center;
    height: 140px;
  }

  .card-text {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    width: 350px;
    color: #000000;
  }

  .use-coins {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(90, 114, 200, 0.1);
    border-radius: 4px;
    padding: 25px 30px;
    align-items: center;
  }
  .coins-row {
    margin: 10px 90px;
  }

  .coin-img-column {
    padding: 15px 30px;
  }
  .use-coin-img {
    padding: 15px 30px;
  }
  .coins-below-container {
    margin: 0;
  }
}
