.article-list-container {
    margin: 0px;
}
.article-list-container .row {
    margin: 0px;  padding: 0px;
}
.article-list-container .row .column {
    margin: 0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
.article-list-container .row .column .main-container {
    margin: 10px; padding: 15px;  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16); border-radius: 12px; background: #fff;
}
.article-list-container .row .column .main-container .card {
    margin: 0px; border: 0px solid;
}
.article-list-container .row .column .main-container .card .card-content {
    margin: 0px;
}
.article-list-container .row .column .main-container .card .card-content .image-container {
    margin: 0px;
}
.article-list-container .row .column .main-container .card .card-content .image-container .content {
    margin: 0px;
}
.article-list-container .row .column .main-container .card .card-content .image-container .content .image1 {
    width: 100%; border-radius: 8px; min-height: auto; height: auto; object-fit: cover;
}
.article-list-container .row .column .main-container .card .card-content .body-content {
    margin-top: 10px;
}
.article-list-container .row .column .main-container .card .card-content .body-content .inner-content {
    margin: 0px;
}
.article-list-container .row .column .main-container .card .card-content .body-content .inner-content .nowrap-content .column {
    margin: 0px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
}
.article-list-container .row .column .main-container .card .card-content .body-content .inner-content .nowrap-content .column .title {
    font-size: 16px; font-weight: 500; color: #151515 !important; text-transform: capitalize;
}
.article-list-container .row .column .main-container .card .card-content .body-content .body-footer {
    margin: 0px; padding-bottom: 10px;
}
.article-list-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .language-image {
    width: 30px; padding: 0px 5px;
}
.article-list-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .like-image {
    padding: 0px 5px; color: grey; width: 34px;
}
.article-list-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .view-image {
    padding: 0px 5px; color: grey; width: 28px;
}
.article-list-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .calendar-image {
    width: 32px; padding: 0px 5px; 
}
.article-list-container .row .column .main-container .card .card-content .body-content .body-footer .nowrap-content .column .image-label {
    color: #0B649D; font-weight: normal; font-size: 12px;
}