@media (min-width: 320px) {
  .course-filter {
    margin: 10px 20px;
  }
  .course-filter-main {
    display: flex;
    flex-direction: row;
    background: #fff;
    border-radius: 7px;
    padding: 10px;
    align-items: center;
    flex-wrap: wrap;
  }
  .tag-filter-main {
    display: flex;
    flex-direction: row;
    background: linear-gradient(180deg, #ffffff 0%, #e0f3ff 100%);
    border-radius: 0px;
    padding: 15px;
    align-items: center;
  }
  .filter-img {
    padding: 0px 20px;
  }
  .filter-dropdown {
    background: #fff;
    height: calc(100vh - 42vh);
    width: 500px;
    margin: 0px 0px;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(58, 58, 68, 0.24),
      0px 2px 4px rgba(90, 91, 106, 0.24);
  }
  .filter-dropdown-option {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
  .filter-dropdown-menu {
    flex: 2;
    background: rgba(224, 243, 255, 0.5);
    padding: 30px 0px;
    height: calc(100vh - 45vh);
  }
  .dropdown-menu {
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .radioselected {
    border-radius: 90px;
    color: #fff;
    background: #2ed47a;
    width: 8px;
    height: 8px;
    text-align: center;
    margin: 0px 10px;
    font-size: 12px;
  }
  .selected {
    border-radius: 90px;
    color: #fff;
    background: #2ed47a;
    width: 18px;
    height: 18px;
    text-align: center;
    margin: 0px 10px;
    font-size: 12px;
    float: right;
  }
  .sub-dropdown {
    flex: 3;
    padding: 30px 20px;
    height: calc(100vh - 45vh);
    overflow-y: scroll;
  }
  .sub-dropdown-menu {
    padding: 5px 0px;
  }
  .dropdown-button {
    border-radius: 7px;
    color: #0b649d;
    border: 1px solid #0b649d;
    font-weight: 700;
  }
  .CloseButton {
    font-size: 18px;
    cursor: pointer;
    color: #0b649d;
  }
  .top-menu {
    margin: 0px 10px;
    padding: 5px 0px;
  }
  .menu-box {
    border: 1px solid #e4e5e7;
    border-radius: 10px;
    padding: 5px 15px;
    cursor: pointer;
    white-space: nowrap;
  }
}

@media (min-width: 421px) {
  .course-filter {
    margin: 10px 20px;
  }
  .course-filter-main {
    display: flex;
    flex-direction: row;
    background: #fff;
    border-radius: 7px;
    padding: 10px;
    align-items: center;
    flex-wrap: wrap;
  }
  .tag-filter-main {
    display: flex;
    flex-direction: row;
    background: linear-gradient(180deg, #ffffff 0%, #e0f3ff 100%);
    border-radius: 0px;
    padding: 15px;
    align-items: center;
  }
  .filter-img {
    padding: 0px 20px;
  }
  .filter-dropdown {
    background: #fff;
    height: calc(100vh - 42vh);
    width: 500px;
    margin: 0px 0px;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(58, 58, 68, 0.24),
      0px 2px 4px rgba(90, 91, 106, 0.24);
  }
  .filter-dropdown-option {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
  .filter-dropdown-menu {
    flex: 2;
    background: rgba(224, 243, 255, 0.5);
    padding: 30px 0px;
    height: calc(100vh - 45vh);
  }
  .dropdown-menu {
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .selected {
    border-radius: 90px;
    color: #fff;
    background: #2ed47a;
    width: 18px;
    height: 18px;
    text-align: center;
    margin: 0px 10px;
    font-size: 12px;
  }
  .sub-dropdown {
    flex: 3;
    padding: 30px 20px;
    height: 530px;
    overflow-y: scroll;
  }
  .sub-dropdown-menu {
    padding: 5px 0px;
  }
  .dropdown-button {
    border-radius: 7px;
    color: #0b649d;
    border: 1px solid #0b649d;
    font-weight: 700;
  }
  .CloseButton {
    font-size: 18px;
    cursor: pointer;
    color: #0b649d;
  }
  .top-menu {
    margin: 0px 10px;
    padding: 5px 0px;
  }
  .menu-box {
    border: 1px solid #e4e5e7;
    border-radius: 10px;
    padding: 5px 15px;
    cursor: pointer;
    white-space: nowrap;
  }
}

@media (min-width: 641px) {
  .course-filter {
    margin: 10px 20px;
  }
  .course-filter-main {
    display: flex;
    flex-direction: row;
    background: #fff;
    border-radius: 7px;
    padding: 10px;
    align-items: center;
  }
  .tag-filter-main {
    display: flex;
    flex-direction: row;
    background: linear-gradient(180deg, #ffffff 0%, #e0f3ff 100%);
    border-radius: 0px;
    padding: 15px;
    align-items: center;
  }
  .filter-img {
    padding: 0px 20px;
  }
  .filter-dropdown {
    background: #fff;
    height: calc(100vh - 42vh);
    width: 500px;
    margin: 0px 0px;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(58, 58, 68, 0.24),
      0px 2px 4px rgba(90, 91, 106, 0.24);
  }
  .filter-dropdown-option {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
  .filter-dropdown-menu {
    flex: 2;
    background: rgba(224, 243, 255, 0.5);
    padding: 30px 0px;
    height: calc(100vh - 45vh);
  }
  .dropdown-menu {
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .selected {
    border-radius: 90px;
    color: #fff;
    background: #2ed47a;
    width: 18px;
    height: 18px;
    text-align: center;
    margin: 0px 10px;
    font-size: 12px;
  }
  .sub-dropdown {
    flex: 3;
    padding: 30px 20px;
    height: 530px;
    overflow-y: scroll;
  }
  .sub-dropdown-menu {
    padding: 5px 0px;
  }
  .dropdown-button {
    border-radius: 7px;
    color: #0b649d;
    border: 1px solid #0b649d;
    font-weight: 700;
  }
  .CloseButton {
    font-size: 18px;
    cursor: pointer;
    color: #0b649d;
  }
  .top-menu {
    margin: 0px 10px;
  }
  .menu-box {
    border: 1px solid #e4e5e7;
    border-radius: 10px;
    padding: 5px 15px;
    cursor: pointer;
    white-space: nowrap;
  }
}

@media (min-width: 961px) {
  .course-filter {
    margin: 10px 20px;
  }
  .course-filter-main {
    display: flex;
    flex-direction: row;
    background: #fff;
    border-radius: 7px;
    padding: 10px;
    align-items: center;
  }
  .tag-filter-main {
    display: flex;
    flex-direction: row;
    background: linear-gradient(180deg, #ffffff 0%, #e0f3ff 100%);
    border-radius: 0px;
    padding: 15px;
    align-items: center;
  }
  .filter-img {
    padding: 0px 20px;
  }
  .filter-dropdown {
    background: #fff;
    height: calc(100vh - 42vh);
    width: 500px;
    margin: 0px 0px;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(58, 58, 68, 0.24),
      0px 2px 4px rgba(90, 91, 106, 0.24);
  }
  .filter-dropdown-option {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
  .filter-dropdown-menu {
    flex: 2;
    background: rgba(224, 243, 255, 0.5);
    padding: 30px 0px;
    height: calc(100vh - 45vh);
  }
  .dropdown-menu {
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .selected {
    border-radius: 90px;
    color: #fff;
    background: #2ed47a;
    width: 18px;
    height: 18px;
    text-align: center;
    margin: 0px 10px;
    font-size: 12px;
  }
  .sub-dropdown {
    flex: 3;
    padding: 30px 20px;
    height: 530px;
    overflow-y: scroll;
  }
  .sub-dropdown-menu {
    padding: 5px 0px;
  }
  .dropdown-button {
    border-radius: 7px;
    color: #0b649d;
    border: 1px solid #0b649d;
    font-weight: 700;
  }
  .CloseButton {
    font-size: 18px;
    cursor: pointer;
    color: #0b649d;
  }
  .top-menu {
    margin: 0px 10px;
  }
  .menu-box {
    border: 1px solid #e4e5e7;
    border-radius: 10px;
    padding: 5px 15px;
    cursor: pointer;
    white-space: nowrap;
  }
}

@media (min-width: 1025px) {
  .course-filter {
    margin: 10px 20px;
  }
  .course-filter-main {
    display: flex;
    flex-direction: row;
    background: #fff;
    border-radius: 7px;
    padding: 10px;
    align-items: center;
  }
  .tag-filter-main {
    display: flex;
    flex-direction: row;
    background: linear-gradient(180deg, #ffffff 0%, #e0f3ff 100%);
    border-radius: 0px;
    padding: 15px;
    align-items: center;
  }
  .filter-img {
    padding: 0px 20px;
  }
  .filter-dropdown {
    background: #fff;
    height: calc(100vh - 42vh);
    width: 500px;
    margin: 0px 0px;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(58, 58, 68, 0.24),
      0px 2px 4px rgba(90, 91, 106, 0.24);
  }
  .filter-dropdown-option {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
  .filter-dropdown-menu {
    flex: 2;
    background: rgba(224, 243, 255, 0.5);
    padding: 30px 0px;
    height: calc(100vh - 45vh);
  }
  .dropdown-menu {
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .selected {
    border-radius: 90px;
    color: #fff;
    background: #2ed47a;
    width: 18px;
    height: 18px;
    text-align: center;
    margin: 0px 10px;
    font-size: 12px;
  }
  .sub-dropdown {
    flex: 3;
    padding: 30px 20px;
    height: calc(100vh - 45vh);
    overflow-y: scroll;
  }
  .sub-dropdown-menu {
    padding: 5px 0px;
  }
  .dropdown-button {
    border-radius: 7px;
    color: #0b649d;
    border: 1px solid #0b649d;
    font-weight: 700;
  }
  .CloseButton {
    font-size: 18px;
    cursor: pointer;
    color: #0b649d;
  }
  .top-menu {
    margin: 0px 10px;
  }
  .menu-box {
    border: 1px solid #e4e5e7;
    border-radius: 10px;
    padding: 5px 15px;
    cursor: pointer;
    white-space: nowrap;
  }
}

@media (min-width: 1440px) {
  .filter-dropdown {
    background: #fff;
    height: calc(100vh - 43vh);
    width: 600px;
    margin: 0px 0px;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(58, 58, 68, 0.24),
      0px 2px 4px rgba(90, 91, 106, 0.24);
  }
  .filter-dropdown-option {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
  .filter-dropdown-menu {
    flex: 2;
    background: rgba(224, 243, 255, 0.5);
    padding: 30px 0px;
    height: calc(100vh - 45vh);
  }
  .dropdown-menu {
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 2560px) {
  .course-filter {
    margin: 10px 20px;
  }
  .course-filter-main {
    display: flex;
    flex-direction: row;
    background: #fff;
    border-radius: 7px;
    padding: 10px;
    align-items: center;
  }
  .tag-filter-main {
    display: flex;
    flex-direction: row;
    background: linear-gradient(180deg, #ffffff 0%, #e0f3ff 100%);
    border-radius: 0px;
    padding: 15px;
    align-items: center;
  }
  .filter-img {
    padding: 0px 20px;
  }
  .filter-dropdown {
    background: #fff;
    height: calc(100vh - 43vh);
    width: 600px;
    margin: 0px 0px;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(58, 58, 68, 0.24),
      0px 2px 4px rgba(90, 91, 106, 0.24);
  }
  .filter-dropdown-option {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
  .filter-dropdown-menu {
    flex: 2;
    background: rgba(224, 243, 255, 0.5);
    padding: 30px 0px;
    height: calc(100vh - 45vh);
  }
  .dropdown-menu {
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .selected {
    border-radius: 90px;
    color: #fff;
    background: #2ed47a;
    width: 18px;
    height: 18px;
    text-align: center;
    margin: 0px 10px;
    font-size: 12px;
  }
  .sub-dropdown {
    flex: 3;
    padding: 30px 20px;
    height: calc(100vh - 45vh);
    overflow-y: scroll;
  }
  .sub-dropdown-menu {
    padding: 5px 0px;
  }
  .dropdown-button {
    border-radius: 7px;
    color: #0b649d;
    border: 1px solid #0b649d;
    font-weight: 700;
  }
  .CloseButton {
    font-size: 18px;
    cursor: pointer;
    color: #0b649d;
  }
  .top-menu {
    margin: 0px 10px;
  }
  .menu-box {
    border: 1px solid #e4e5e7;
    border-radius: 10px;
    padding: 5px 15px;
    cursor: pointer;
    white-space: nowrap;
    font-size: 18px;
  }
}
