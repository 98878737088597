li.pointslevellist-cont {
  display: flex;
  flex-direction: unset;
  align-items: baseline;
  margin-top: 10px;
}
.doubts-sec {
  margin: 15px;
}
/* default css not used 11/10/2022 */
.icon-vector {
  float: right;
  position: relative;
  right: 0;
  font-size: 15px;
  top: 2px;
}

.cole {
  color: #181818bf;
}
/* sides */

.ant-slider {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-feature-settings: "tnum";
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  cursor: pointer;
  touch-action: none;
}

.total_points .ant-slider:hover .ant-slider-track {
  background-color: #00d1a9;
}
.total_points .ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #00d1a9;
}
.total_points .ant-slider-track {
  position: absolute;
  height: 7px;
  background-color: #91d5ff;
  transition: background-color 0.3s;
  border-radius: 10px;
}
.total_points .ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  inset-block-start: 0px;
  background-color: #00d1a9 !important;
  border: solid 2px #91d5ff;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s,
    transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.total_points .ant-slider-track {
  position: absolute;
  height: 7px;
  background-color: #00d1a9;
  transition: background-color 0.3s;
  border-radius: 10px;
}
.total_points .ant-slider-handle.ant-tooltip-open {
  border-color: #00d1a9 !important;
  background: #00d1a9 !important;
}
.total_points .ant-slider-disabled .ant-slider-track {
  background-color: #00d1a9 !important;
}
.total_points .ant-slider-handle {
  border: none;
}
.total_points .ant-slider-track:hover {
  background: #00d1a9 !important;
}
.total_points .ant-slider-handle.ant-tooltip-open {
  box-shadow: none;
}
.total_points .ant-slider-handle .ant-tooltip-open {
  box-shadow: none;
  display: none;
}

.ant-slider.ant-slider-disabled .ant-slider-handle::after {
  display: none;
}

/* four card sect */
.point-card-icon {
  height: 64px;
  width: 64px;
  margin: 0 7px;
  border-radius: 45px;
  float: left;
  position: relative;
}

.points-card-txt {
  display: flex;
  flex-direction: column;
}
.count {
  font-weight: bold;
  font-size: 23px;
}
.title-txt {
  font-size: 18px;
  font-weight: bold;
}
.count-txt {
  font-size: 15px;
}
.mypoints-cards-sec.bg-white {
  height: 81px;
  margin-top: 14px;
  line-height: 29px;
  justify-content: space-between !important;
}

.icon1-clr {
  background-color: #fff5e3;
  padding: 15px;
}
.icon2-clr {
  background-color: #e0f9eb;
  padding: 15px;
}
.icon3-clr {
  background-color: #dbf9fc;
  padding: 15px;
}
.icon4-clr {
  background-color: #fcfbd6;
  padding: 0px;
}

.list-no {
  background: rgba(255, 185, 70, 0.15);
  text-align: center;
  border-radius: 144px;
  padding: 0px 9px;
  color: #f9873c;
  margin-right: 10px;
}

.list-text {
  line-height: 24px;
  font-size: 13px;
  /* padding-left: 17px; */
  color: black;
  margin: 0 0px;
}
.cleardoubts {
  text-align: center;
  line-height: 6px;
  margin-bottom: 23px;
  font-weight: 400;
  font-size: 13px;
  color: #f9873c;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: unset;
}

.points-border {
  border: 1px solid #ffb946;
  border-radius: 35px;
  margin: 35px 0;
}

.doubtspointstot {
  position: relative !important;
}

/* ----------- */
.abs-cen {
  position: absolute;
  margin: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.flex-row-cen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bg-white {
  background-color: white;
}

.txt-blue {
  color: #0b649d;
}
h3 {
  margin-bottom: 0px !important;
}
.txt-cen {
  text-align: center;
}
.txt-orange {
  color: #f9873c;
}
.txt-white {
  color: #fff;
  font-weight: bold;
}
.btn-blue {
  font-size: 15px;
  border-radius: 5px;
  color: #fff;
  background: #0b649d;
  width: fit-content;
  height: 47px;
  top: 135px;
}
.btn-blue:hover,
.btn-blue:focus {
  color: #fff !important;
  background: #0b649d !important;
  border-color: #0b649d;
  height: 47px;
  top: 135px;
}
.btn-orange {
  color: #f9873c;
  border-color: #f9873c;
  border-radius: 16px;
  right: 25px !important;
  top: 24px;
  bottom: unset !important;
  left: unset !important;
  font-size: 9px;
  height: 23px;
  width: fit-content;
}
.btn-orange:focus,
.btn-orange:hover {
  color: #f9873c !important;
  border-color: #f9873c !important;
  border-radius: 16px;
  right: 25px !important;
  top: 24px;
  bottom: unset !important;
  left: unset !important;
  font-size: 9px;
  height: 23px;
  width: fit-content;
}
/* -------------------------------------------- */

.doubts-main-content {
  margin: 50px 30px;
}
.mypoint-bx-shad {
  box-shadow: 0px 2px 10px rgb(90 114 200 / 11%);
}
/* circle bar sec  */
.circle-bar-sec {
  width: 100%;
  height: 275px;
  margin-top: 15px;
}
.circle {
  width: fit-content;
  height: fit-content;
  border-bottom-right-radius: 18rem;
  border-bottom-left-radius: 18rem;
  border-top-right-radius: 18rem;
  border-top-left-radius: 18rem;
  border: 15px solid #f5fafd;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.circle-postion {
  position: relative !important;
  top: 3px !important;
}
.circle2 {
  width: 250px;
  height: 250px;
  border-bottom-right-radius: 19rem;
  border-bottom-left-radius: 19rem;
  border-top-right-radius: 19rem;
  border-top-left-radius: 19rem;
  border-top: 15px solid #e0f3ff;
  border-right: 15px solid #e0f3ff;
  border-bottom: 15px solid #e0f3ff;
  border-left: 15px solid #0b649d;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.circle-postion2 {
  margin: -1px -1px;
}
.circle-bar-txt {
  padding-left: 39px;
  padding-top: 14px;
  line-height: 16pt;
}
.star-circle-sec {
  height: 166px;
  overflow: hidden;
}
.circle-txt {
  z-index: 1;
  flex-direction: column;
  top: 68px;
}
.starpoint-img {
  bottom: 80px;
}
.star-img {
  top: 26px;
}
.line-div {
  position: relative;
  border: 3px solid #0b649d;
  top: 143px;
  min-width: 17rem;
  max-width: 19rem;
  width: 21rem;
  left: 0px;
  margin: auto;
}
/* levele bar sec */
.level-bar {
  background: #e0f3ff 30%;
  border-radius: 10px;
  padding-top: -10px;
  position: relative;
  top: 40px;
  margin: 0 27px;
  padding: 11px;
}
.level-bar-btn {
  /* background: blue; */
  height: 47px;
  top: 135px;
}
/* cup image content starts */
.level-benefit-sec {
  padding-top: 35px;
  padding-left: 55px;
  padding-right: 55px;
  padding-bottom: 35px;
  margin-top: 17px;
}
.cup-img {
  bottom: unset !important;
  top: 32px;
  background: #fff;
}

.cup-img-content {
  margin-top: 26px;
}
.range-ul {
  line-height: 25pt;
  font-size: 16px;
  margin: 32px 0;
  list-style: none;
}
.level-col {
  background-color: #f3fdff;
}
ul.points_level ul.help_other {
  list-style: none;
}
.range-col {
  background-color: #fffbf8;
}
.range-sec {
  min-width: 37%;
  text-align: center;
  border-top: 5px solid #ffb946;
  border-bottom: 5px solid #ffb946;
}
.list-item-cont {
  display: flex;
}
.doubts-point-lev-sec {
  flex-direction: row;
  display: flex;
  margin-bottom: 21px;
}
.man-img {
  top: unset;
  bottom: 4px;
  width: 90px;
}

/* cup image content end */

.level-bar-btn .ant-btn-primary:focus,
.ant-btn-primary:hover {
  border-color: #0a5a8d !important;
  background: #0a5a8d !important;
}
.pointslevelhead .ant-collapse-header {
  font-weight: 600;
  padding: 15px 20px !important;
  font-size: 18px;
  text-align: center;
}
.points-border .ant-collapse-header {
  font-weight: 600;
  padding: 15px 20px !important;
  font-size: 18px;
  text-align: center;
}

@media (min-width: 1600px) {
  .mypoints-doubts-sec {
    margin: 100px 250px;
  }
}

@media (max-width: 1600px) {
  .man-img {
    width: 90px;
  }
  .mypoints-doubts-sec {
    margin: 100px 175px;
  }
}

@media (max-width: 1200px) {
  .mypoints-doubts-sec {
    margin: 100px 200px;
  }
}

@media (max-width: 1024px) {
  .mypoints-doubts-sec {
    margin: 90px 150px;
  }
}

@media (max-width: 768px) {
  .mypoints-doubts-sec {
    margin: 80px 75px;
  }
}
